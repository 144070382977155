import { compose, withProps, lifecycle } from 'recompose'
import _ from 'lodash'
import { connect } from 'react-redux'
import { clearReservable } from '../../actions/remote_api'

const withReservable = compose(
  connect(
    (state) => ({
      isReservableLoading: _.get(
        state,
        'fetch.reservable.isLoading.single',
        false,
      ),
      reservableData: _.get(state, 'fetch.reservable.single'),
    }),
    { clearReservable },
  ),
  lifecycle({
    componentDidMount() {
      this.props.clearReservable('single')
    },
  }),
  withProps((props) => ({
    reservableStatus: reservableStatus(
      props.isReservableLoading,
      props.reservableData,
    ),
  })),
)

const reservableStatus = (isLoading, reservableData) => {
  if (isLoading)
    return {
      name: 'loading',
    }

  if (!reservableData) return { name: undefined }

  return {
    ...reservableData,
    name: reservableData.isReservable ? 'valid' : 'invalid',
  }
}

export default withReservable
