import React from 'react'
import { Table } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import _ from 'lodash'
import { dateTimeFormat } from '../../table'

const AgentCashTxnTable = ({ agentCashTxns }) => (
  <Table>
    <thead>
      <tr>
        <th>{I18n.t('agentPage.agentCashTxnTable.createdDate')}</th>
        <th>{I18n.t('agentPage.agentCashTxnTable.type')}</th>
        <th>{I18n.t('agentPage.agentCashTxnTable.infoType')}</th>
        <th>{I18n.t('agentPage.agentCashTxnTable.beforeAmount')}</th>
        <th>{I18n.t('agentPage.agentCashTxnTable.amount')}</th>
        <th>{I18n.t('agentPage.agentCashTxnTable.afterAmount')}</th>
      </tr>
    </thead>
    <tbody>
      {_.map(agentCashTxns, (agentCashTxn) => (
        <tr key={agentCashTxn._id}>
          <td>{dateTimeFormat(agentCashTxn.createdDate)}</td>
          <td>{agentCashTxn.type}</td>
          <td>{agentCashTxn.infoType || '-'}</td>
          <td>{agentCashTxn.beforeAmount}</td>
          <td>{agentCashTxn.amount}</td>
          <td>{agentCashTxn.afterAmount}</td>
        </tr>
      ))}
      {_.isEmpty(agentCashTxns) && (
        <tr>
          <td colSpan={5} className="text-center">
            {I18n.t('agentPage.agentCashTxnTable.noData')}
          </td>
        </tr>
      )}
    </tbody>
  </Table>
)

export default AgentCashTxnTable
