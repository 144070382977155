import React from 'react'
import { Panel, Form, Row, Col, FormControl, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import Select from 'react-select'
import { agentTypeOptions } from './agentTypeOptions'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {
  const fieldsLabel = I18n.t('agentPage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Row>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.code || ''}
              onChange={(e) => onFilterChange('code', e.currentTarget.value)}
              placeholder={fieldsLabel.code}
            />
          </Col>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.name || ''}
              onChange={(e) => onFilterChange('name', e.currentTarget.value)}
              placeholder={fieldsLabel.name}
            />
          </Col>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.phone1 || ''}
              onChange={(e) => onFilterChange('phone1', e.currentTarget.value)}
              placeholder={fieldsLabel.phone1}
            />
          </Col>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.phone2 || ''}
              onChange={(e) => onFilterChange('phone2', e.currentTarget.value)}
              placeholder={fieldsLabel.phone2}
            />
          </Col>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.email || ''}
              onChange={(e) => onFilterChange('email', e.currentTarget.value)}
              placeholder={fieldsLabel.email}
            />
          </Col>
          <Col md={2}>
            <Button className="pull-right" onClick={onClearFilter}>
              <span className="glyphicon glyphicon-trash" /> Clear Filter
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Select
              value={filter.type}
              onChange={(value) => onFilterChange('type', value)}
              simpleValue
              options={agentTypeOptions}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  )
}
