import React from 'react'
import { connect } from 'react-redux'
import { submit, stopSubmit } from 'redux-form'
import { Redirect } from 'react-router-dom'

import InvoiceIntervalForm from './InvoiceIntervalForm'
import {
  fetchInvoiceInterval,
  createInvoiceInterval,
  updateInvoiceInterval,
  clearInvoiceInterval,
} from '../../actions/remote_api'
import { invoiceIntervalStatus } from '../../services/invoiceInterval'

class InvoiceIntervalFormPage extends React.Component {
  formId = 'invoiceInterval'
  state = { formData: {} }

  componentDidMount() {
    const { fetchInvoiceInterval, id, clearInvoiceInterval } = this.props

    clearInvoiceInterval()
    if (id !== undefined) {
      fetchInvoiceInterval(id)
    }
  }

  componentWillUnmount() {
    this.props.clearInvoiceInterval()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.invoiceInterval) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.invoiceInterval },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = (formData) => {
    const { createInvoiceInterval, updateInvoiceInterval, id } = this.props
    const invoiceInterval = formData
    if (id === undefined)
      createInvoiceInterval(invoiceInterval, {
        onResponse: this.handleResponse,
      })
    else
      updateInvoiceInterval(invoiceInterval, {
        onResponse: this.handleResponse,
      })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = (e) => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  isInRestrictionStatus = () =>
    this.state.formData &&
    this.state.formData.status &&
    this.state.formData.status !== invoiceIntervalStatus.draft

  render() {
    if (this.isInRestrictionStatus()) return <Redirect to="/invoiceInterval" />
    return (
      <form onSubmit={this.submit}>
        <InvoiceIntervalForm
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { invoiceInterval }, ...state }) => ({
    invoiceInterval: invoiceInterval ? invoiceInterval.single : {},
    errors: invoiceInterval ? invoiceInterval.singleErrors : null,
  }),
  {
    createInvoiceInterval,
    updateInvoiceInterval,
    fetchInvoiceInterval,
    clearInvoiceInterval,
    submit,
    stopSubmit,
  },
)(InvoiceIntervalFormPage)
