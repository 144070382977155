import React from 'react'
import styled from 'styled-components'
import { Translate } from 'react-redux-i18n'

export default ({ greeting }) => (
  <div className="text-center">
    <WelcomeImage src={require('../img/coral-logo.png')} alt="logo" />
    <h1>
      <Translate value="greeting" dangerousHTML />
    </h1>
  </div>
)

const WelcomeImage = styled.img`
  margin-top: 80px;
  margin-bottom: 80px;
`
