import React from 'react'
import ReservationForm from './ReservationForm'
import { connect } from 'react-redux'
import {
  fetchReservation,
  createReservation,
  updateReservation,
  clearReservation,
} from '../../actions/fetch_reservation'
import { submit, stopSubmit, getFormValues } from 'redux-form'
import { fetchProduct } from '../../actions/remote_api'
import { compose } from 'recompose'
import withReservable from './withReservable'
import { formMode } from '../form'

const formId = 'reservation'

class ReservationFormPage extends React.Component {
  state = {
    formData: {
      flightType: 'arrival',
      replyTo: 'booking@coralthailand.com',
      luggageAmount: 0,
      guestPaxAdult: 0,
      guestPaxChild: 0,
      guestPaxInfant: 0,
      payerType: 'agent',
      isCharged: true,
    },
  }

  componentDidMount() {
    const { fetchReservation, id, clearReservation } = this.props

    clearReservation()
    if (id !== undefined) {
      fetchReservation(id)
    }
  }

  componentWillUnmount() {
    this.props.clearReservation()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reservation) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.reservation },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(formId, nextProps.errors)
    }
  }

  handleSubmit = (formData) => {
    const { createReservation, updateReservation, id } = this.props
    const reservation = formData
    if (id === undefined)
      createReservation(reservation, { onResponse: this.handleResponse })
    else updateReservation(reservation, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = (e) => {
    this.props.submit(formId)
    e.preventDefault()
  }

  isLocked = () =>
    this.state.formData.status && this.state.formData.status !== 'tentative'

  isOverrideCNShowed = () => !this.state.formData.confirmationNumber

  servicesBySelectedProduct = (product) =>
    this.props.formValues && this.props.formValues.targetStationsByService
      ? this.props.formValues.targetStationsByService.map(
          (target) => target.service,
        )
      : undefined

  isSubmittable = () => {
    // return this.isLocked() || this.isValidReserve()
    return this.isValidReserve()
  }

  isValidReserve = () =>
    this.formMode() === 'new'
      ? this.props.reservableStatus.isReservable
      : this.props.reservableStatus.isReservable === undefined ||
        this.props.reservableStatus.isReservable

  formMode = () =>
    this.props.formValues ? formMode(this.props.formValues._id) : undefined

  render() {
    return (
      <form onSubmit={this.submit}>
        <ReservationForm
          form={formId}
          formValues={this.props.formValues}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          isLocked={this.isLocked()}
          isOverrideCNShowed={this.isOverrideCNShowed()}
          onNewStationService={this.handleNewStationService}
          selectedServices={this.servicesBySelectedProduct(this.props.product)}
          reservableStatus={this.props.reservableStatus}
          enableReinitialize
          isSubmittable={this.isSubmittable()}
        />
      </form>
    )
  }
}

export default compose(
  connect(
    ({ fetch: { reservation, product, agent }, ...state }) => ({
      reservation: reservation ? reservation.single : {},
      errors: reservation ? reservation.singleErrors : null,
      formValues: getFormValues(formId)(state),
    }),
    {
      createReservation,
      updateReservation,
      fetchReservation,
      clearReservation,
      fetchProduct,
      submit,
      stopSubmit,
    },
  ),
  withReservable,
)(ReservationFormPage)
