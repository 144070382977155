import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import _ from 'lodash'

import ReservationDetailRightBarButton from './ReservationDetailRightBarButton'
import {
  confirmReservation,
  revertConfirmReservation,
  completeReservation,
  noShowReservation,
  missedCaseReservation,
  cancelReservation,
  reconfirmReservation,
  refundReservation,
  deleteReservation,
  revertFinalStatusReservation,
} from '../../../actions/fetch_reservation'
import { withAlertModal } from '../../alertModal'
import { checkIfAnyGrantedByGivenAuth } from '../../../modules/authService'
import { createShowToast } from '../../detailPopup'

class ReservationDetailRightBarButtonContainer extends React.Component {
  handleReservationConfirm = _.debounce(() => {
    const { instance, confirmReservation } = this.props
    confirmReservation(instance._id, {
      fetchId: 'reservation',
      onResponse: createShowToast('CONFIRM RESERVATION SUCCESS'),
    })
  }, 500)

  handleReservationReconfirm = _.debounce(() => {
    const { instance, reconfirmReservation } = this.props
    reconfirmReservation(instance._id, {
      fetchId: 'reservation',
      onResponse: createShowToast('RECONFIRM RESERVATION SUCCESS'),
    })
  }, 500)

  handleReservationRevertConfirm = () => {
    this.props.showAlert(I18n.t('reservationPage.revertConfirmAlert'), {
      action: 'revertConfirm',
    })
  }

  handleReservationComplete = () => {
    this.props.showAlert(I18n.t('reservationPage.completeAlert'), {
      action: 'complete',
    })
  }

  handleReservationNoShow = () => {
    this.props.showAlert(I18n.t('reservationPage.noShowAlert'), {
      action: 'noShow',
    })
  }

  handleReservationMissedCase = () => {
    this.props.showAlert(I18n.t('reservationPage.missedCaseAlert'), {
      action: 'missedCase',
    })
  }

  handleReservationRevertFinalStatus = () => {
    this.props.showAlert(I18n.t('reservationPage.revertFinalStatusAlert'), {
      action: 'revertFinalStatus',
    })
  }

  handleReservationCancel = () => {
    this.props.showAlert(I18n.t('reservationPage.cancelAlert'), {
      action: 'cancel',
    })
  }

  handleReservationRefund = () => {
    this.props.showAlert(I18n.t('reservationPage.refundAlert'), {
      action: 'refund',
    })
  }

  handleReservationDelete = () => {
    this.props.showAlert(I18n.t('reservationPage.deleteAlert'), {
      action: 'delete',
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isPrimaryTriggered) {
      this.performAction()
    }
  }

  performAction = _.debounce(() => {
    const { instance, hideAlert, alertMeta } = this.props

    if (alertMeta.action === 'delete') {
      this.props.deleteReservation(instance._id, {
        fetchId: 'reservation',
        onResponse: (res) => {
          createShowToast(
            `${alertMeta.action.toUpperCase()} RESERVATION SUCCESS`,
          )(res)
          hideAlert()
          this.props.onHide()
        },
      })
      return
    }

    let action
    switch (alertMeta.action) {
      case 'cancel':
        action = this.props.cancelReservation
        break
      case 'complete':
        action = this.props.completeReservation
        break
      case 'noShow':
        action = this.props.noShowReservation
        break
      case 'missedCase':
        action = this.props.missedCaseReservation
        break
      case 'refund':
        action = this.props.refundReservation
        break
      case 'revertConfirm':
        action = this.props.revertConfirmReservation
        break
      case 'revertFinalStatus':
        action = this.props.revertFinalStatusReservation
        break
      default:
        return
    }

    action(instance._id, {
      fetchId: 'reservation',
      onResponse: (res) => {
        createShowToast(
          `${alertMeta.action.toUpperCase()} RESERVATION SUCCESS`,
        )(res)
        hideAlert()
        this.props.onRefetchStart()
      },
    })
  }, 500)

  isFinalStatus = (status) =>
    status === 'completed' || status === 'noShow' || status === 'missedCase'

  isShows() {
    const { instance, isSupervisor } = this.props
    if (!instance) return {}
    const isFinalStatus = this.isFinalStatus(instance.status)
    return {
      confirm: instance.status === 'tentative',
      delete:
        isSupervisor &&
        instance.status === 'tentative' &&
        !instance.confirmationNumber,
      revertConfirm: instance.status === 'confirmed' && isSupervisor,
      reconfirm: instance.status === 'confirmed',
      completed: instance.status === 'confirmed' && isSupervisor,
      noShow: instance.status === 'confirmed' && isSupervisor,
      missedCase: instance.status === 'confirmed' && isSupervisor,
      cancel:
        (instance.status === 'tentative' || instance.status === 'confirmed') &&
        isSupervisor,
      refund: isFinalStatus && isSupervisor && !instance.isRefunded,
      revertFinalStatus: isFinalStatus && isSupervisor,
    }
  }

  render() {
    return (
      <ReservationDetailRightBarButton
        {...this.props}
        onReservationConfirm={this.handleReservationConfirm}
        onReservationReconfirm={this.handleReservationReconfirm}
        onReservationRevertConfirm={this.handleReservationRevertConfirm}
        onReservationComplete={this.handleReservationComplete}
        onReservationNoShow={this.handleReservationNoShow}
        onReservationMissedCase={this.handleReservationMissedCase}
        onReservationRevertFinalStatus={this.handleReservationRevertFinalStatus}
        onReservationCancel={this.handleReservationCancel}
        onReservationRefund={this.handleReservationRefund}
        onReservationDelete={this.handleReservationDelete}
        isShows={this.isShows()}
      />
    )
  }
}

export default connect(
  ({ auth }) => ({
    isSupervisor: checkIfAnyGrantedByGivenAuth(auth, ['supervisor']),
  }),
  {
    confirmReservation,
    revertConfirmReservation,
    completeReservation,
    noShowReservation,
    missedCaseReservation,
    cancelReservation,
    reconfirmReservation,
    refundReservation,
    deleteReservation,
    revertFinalStatusReservation,
  },
)(withAlertModal(ReservationDetailRightBarButtonContainer))
