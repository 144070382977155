import _ from 'lodash'
import {
  clipboard,
  person,
  cube,
  iosMonitorOutline,
  personStalker,
  plane,
  card,
  iosMonitor,
  iosBook,
  documentText,
  alertCircled,
  compose,
  iosPeople,
  iosGear,
  iosPaper,
  /*documentIcon,*/
  folder,
  iosCopy,
  plusCircled,
} from 'react-icons-kit/ionicons'
import { book } from 'react-icons-kit/fa'
import { ic_person_outline } from 'react-icons-kit/md'

import StationPage from '../components/station/StationPage'
import CardPage from '../components/card/CardPage'
import AirlinePage from '../components/airline/AirlinePage'
import AgentPage from '../components/agent/AgentPage'
import ProductPage from '../components/product/ProductPage'
import ReservationPage from '../components/reservation/ReservationPage'
import KioskReservationPage from '../components/reservation/KioskReservationPage'
import WarningReservationPage from '../components/reservation/WarningReservationPage'
import ReportPage from '../components/report/ReportPage'
import ServiceTxnPage from '../components/serviceTxn/ServiceTxnPage'
import ServiceFormPage from '../components/serviceForm/ServiceFormPage'
import SettingsPage from '../components/settings/SettingsPage'
import UserPage from '../components/user/UserPage'
import PricingContractPage from '../components/pricingContract/PricingContractPage'
import VoucherBookPage from '../components/voucherBook/VoucherBookPage'
import InvoiceIntervalPage from '../components/invoiceInterval/InvoiceIntervalPage'
import DebtorPage from '../components/debtor/DebtorPage'
import { InvoicePage } from '../components/invoice/InvoicePage'
import { TopupPage } from '../components/topup/TopupPage'
import { StaffPage } from '../components/staff/StaffPage'
import {
  generalAccess,
  superAccess,
  financeAccess,
  adminFinanceAccess,
} from './accessList'
import { checkIfAnyGrantedByGivenAuth } from './authService'

export const menus = [
  {
    title: 'JOB',
    items: [
      { path: '/reservation', icon: iosBook, title: 'RESERVATION' },
      {
        path: '/reservation-kiosk',
        icon: iosMonitorOutline,
        title: "KIOSK'S RESERV",
      },
      {
        path: '/warning-reservation',
        icon: alertCircled,
        title: 'NOTICED RESERV',
      },
      { path: '/serviceTxn', icon: clipboard, title: 'SERVICE TXN.' },
      { path: '/service-form', icon: compose, title: 'ASSIGNMENT FORM' },
      { path: '/staff', icon: person, title: 'STAFF' },
    ],
  },
  {
    title: 'MANAGEMENT',
    items: [
      { path: '/product', icon: cube, title: 'PRODUCT' },
      { path: '/agent', icon: personStalker, title: 'AGENT' },
      { path: '/airline', icon: plane, title: 'AIRLINE' },
      { path: '/card', icon: card, title: 'CARD' },
      {
        path: '/debtor/agent',
        highlightPath: '/debtor',
        icon: ic_person_outline,
        title: 'DEBTOR',
      },
      {
        path: '/voucherBook',
        highlightPath: '/voucherBook',
        icon: book,
        title: 'VOUCHER BOOK',
      },
      {
        path: '/topup',
        highlightPath: '/topup',
        icon: plusCircled,
        title: 'TOPUP',
      },
      { path: '/station', icon: iosMonitor, title: 'STATION' },
      { path: '/settings', icon: iosGear, title: 'SETTINGS' },
      { path: '/user', icon: iosPeople, title: 'USER' },
    ],
  },
  {
    title: 'FINANCE',
    items: [
      {
        path: '/pricingContract/agent',
        highlightPath: '/pricingContract',
        icon: iosPaper,
        title: 'CONTRACT',
      },
      {
        path: '/invoiceInterval',
        highlightPath: '/invoiceInterval',
        icon: folder,
        title: 'INVOICE INTERVAL',
      },
      {
        path: '/invoice/draft',
        highlightPath: '/invoice/draft',
        icon: iosCopy,
        title: 'DRAFT INVOICE',
      },
      {
        path: '/invoice/pending',
        highlightPath: '/invoice/pending',
        icon: iosCopy,
        title: 'PENDING INVOICE',
      },
      {
        path: '/invoice/all',
        highlightPath: '/invoice/all',
        icon: iosCopy,
        title: 'INVOICE',
      },
    ],
  },
  {
    title: 'REPORT',
    items: [{ path: '/report', icon: documentText, title: 'REPORT' }],
  },
]

export const accessRoutes = [
  {
    path: '/staff',
    component: StaffPage,
    ifAnyGranted: generalAccess,
  },
  {
    path: '/serviceTxn',
    component: ServiceTxnPage,
    ifAnyGranted: generalAccess,
  },
  {
    path: '/station',
    component: StationPage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/user',
    component: UserPage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/card',
    component: CardPage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/airline',
    component: AirlinePage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/agent',
    component: AgentPage,
    ifAnyGranted: adminFinanceAccess,
  },
  {
    path: '/product',
    component: ProductPage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/reservation',
    component: ReservationPage,
    ifAnyGranted: superAccess,
  },
  {
    path: '/reservation-kiosk',
    component: KioskReservationPage,
    ifAnyGranted: superAccess,
  },
  {
    path: '/warning-reservation',
    component: WarningReservationPage,
    ifAnyGranted: superAccess,
  },
  {
    path: '/service-form',
    component: ServiceFormPage,
    ifAnyGranted: superAccess,
  },
  {
    path: '/debtor',
    component: DebtorPage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/voucherBook',
    component: VoucherBookPage,
    ifAnyGranted: adminFinanceAccess,
  },
  {
    path: '/topup',
    component: TopupPage,
    ifAnyGranted: adminFinanceAccess,
  },
  {
    path: '/pricingContract',
    component: PricingContractPage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/invoiceInterval',
    component: InvoiceIntervalPage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/invoice',
    component: InvoicePage,
    ifAnyGranted: financeAccess,
  },
  {
    path: '/settings',
    component: SettingsPage,
    ifAnyGranted: superAccess,
  },
  {
    path: '/report',
    component: ReportPage,
    ifAnyGranted: generalAccess,
  },
]

export const findAccessLevel = (path) =>
  _.get(
    _.find(accessRoutes, (ac) => _.startsWith(path, ac.path)),
    'ifAnyGranted',
    [],
  )

export const menusByAuth = (auth) =>
  menus
    .map((menu) => ({
      title: menu.title,
      menuItems: menu.items.filter((item) => {
        return checkIfAnyGrantedByGivenAuth(auth, findAccessLevel(item.path))
      }),
    }))
    .filter((menu) => menu.menuItems.length > 0)
