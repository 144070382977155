import React from 'react'
import { reduxForm } from 'redux-form'
import { Toolbar } from '../form'
import { required, validateWith } from '../form/validator'
import { Button } from 'react-bootstrap'

import { I18n } from 'react-redux-i18n'
import PricingContractFormLayout from './PricingContractFormLayout'
import PricingContractGeneralInfoForm from './PricingContractGeneralInfoForm'
import PriceProductCheckboxSelectTableContainer from './PriceProductCheckboxSelectTableContainer'
import StatusBadge from '../commons/StatusBadge'

const validate = (values) => {
  const errors = {}
  errors.name = validateWith(values.name, [required])
  if (!values.isTemplate) {
    switch (values.contractorType) {
      case 'agent':
        errors.agentId = validateWith(values.agentId, [required])
        break
      case 'card':
        errors.cardId = validateWith(values.cardId, [required])
        break
      case 'airline':
        errors.airlineId = validateWith(values.airlineId, [required])
        break
      default:
        break
    }
    errors.startDate = validateWith(values.startDate, [required])
    errors.endDate = validateWith(values.endDate, [required])
  }
  return errors
}

const PricingContractForm = ({ initialValues, formValues, ...rest }) => {
  if (!formValues || !formValues.contractorType) return null
  const { fields } = I18n.t('pricingContractPage')
  return (
    <div className="container">
      <Toolbar
        customTitle={<PricingContractFormTitle pricingContract={formValues} />}
      >
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>
      <PricingContractFormLayout
        renderLeftPanel={() => (
          <PricingContractGeneralInfoForm
            fields={fields}
            initialValues={initialValues}
            contractorType={formValues.contractorType}
          />
        )}
        renderRightPanel={() => (
          <PriceProductCheckboxSelectTableContainer
            formId={rest.form}
            fieldName="prices"
          />
        )}
      />
    </div>
  )
}

const PricingContractFormTitle = ({ pricingContract }) => {
  const { form, fields } = I18n.t('pricingContractPage')
  const titleTemplate = pricingContract._id ? form.update : form.new
  const contractorTitle = fields.contractorType[pricingContract.contractorType]
  const title = titleTemplate.replace('{0}', contractorTitle)
  return (
    <span>
      {title}{' '}
      {pricingContract.status && (
        <StatusBadge>{pricingContract.status}</StatusBadge>
      )}
    </span>
  )
}

export default reduxForm({
  validate,
})(PricingContractForm)
