import React from 'react'
import { debounce } from 'lodash'
import ServiceFormPagerView from './ServiceFormPager.view'
import { toastr } from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
import {
  downloadReportWithToast,
  downloadCSReportWithToast,
} from '../commons/DownloadWithToast'
import { showExtraChargeModalIfNeeded } from '../ExtraCharge/index'
import { PrintSelectionModalAction } from './withPrintSelectionModal'
import { requestReport } from '../../api'
import { handleReportRequest } from '../../modules/report'

const isNewReportSystemEnabled = true

export default class ServiceFormPagerContainer extends React.Component {
  state = {
    pageNumber: undefined,
  }

  constructor() {
    super()
    this.pageChange = debounce(this.pageChange, 500)
  }

  componentDidMount() {
    this.addKeyListeners()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPage !== this.state.pageNumber) {
      this.setState({
        pageNumber: nextProps.currentPage,
      })
    }
  }

  addKeyListeners() {
    document.addEventListener('keydown', this.keyboardEventListener)
  }

  removeKeyListeners() {
    document.removeEventListener('keydown', this.keyboardEventListener)
  }

  keyboardEventListener = (keyboardEvent) => {
    if (!keyboardEvent.altKey) {
      if (keyboardEvent.key === 'n') {
        this.goNextPage()
      } else if (keyboardEvent.key === 'b') {
        this.goPrevPage()
      }
    }
  }

  goPrevPage = () => {
    this.goToPage(this.state.pageNumber - 1)
  }

  goNextPage = () => {
    this.goToPage(this.state.pageNumber + 1)
  }

  hasPrevPage = () => this.props.currentPage > 1
  hasNextPage = () => this.props.currentPage < this.props.totalPage

  handlePageChange = (pageNumberText) => {
    const pageNumber = pageNumberText ? Number(pageNumberText) : undefined
    this.setState((state) => ({
      ...state,
      pageNumber,
    }))
    this.pageChange(pageNumber)
  }

  pageChange = (pageNumber) => {
    this.goToPage(pageNumber)
  }

  goToPage = (pageNumber) => {
    if (pageNumber && pageNumber > 0 && pageNumber <= this.props.totalPage) {
      this.props.fetchServiceTxnBySeq(pageNumber)
    } else {
      this.setState((state) => ({
        ...state,
        pageNumber: this.props.currentPage,
      }))
    }
  }

  submiting = false

  handleFormSubmit = (serviceTxn) => {
    if (this.submiting) return
    const submitAction = () => {
      this.submiting = true
      this.props.updateServiceTxnForm(serviceTxn, {
        onResponse: this.handleUpdateServiceTxnResponse,
      })
    }

    showExtraChargeModalIfNeeded(serviceTxn, submitAction)
  }

  handleUpdateServiceTxnResponse = (response) => {
    if (response.ok) {
      toastr.success('Save completed')
      if (this.hasNextPage()) this.goNextPage()
      else {
        const { dateValue } = this.props
        const dateText = dateValue.isSame(moment(), 'day')
          ? 'today'
          : dateValue.format(I18n.t('date.display'))
        toastr.info(
          `You've completely fill all of service transaction for ${dateText}`,
        )
      }
    } else {
      toastr.error('Error', 'something went wrong please try again')
    }
    this.submiting = false
  }

  handleDownloadAllServiceTxnInDate = ({ fromSeq, toSeq }) => {
    if (isNewReportSystemEnabled) {
      handleReportRequest(
        requestReport('/api/reports/queue/serviceTxnReport', {
          type: 'pdf',
          queryDate: moment(this.props.dateValue).toISOString(),
          fromSeq,
          toSeq,
        }),
      )
    } else {
      downloadReportWithToast('serviceTxnReport', {
        queryDate: moment(this.props.dateValue).toISOString(),
        fromSeq,
        toSeq,
      })
    }
  }

  handleDownloadAllServiceTxnCSVInDate = () => {
    if (isNewReportSystemEnabled) {
      handleReportRequest(
        requestReport('/api/reports/queue/serviceTxnReport', {
          queryDate: moment(this.props.dateValue).toISOString(),
          type: 'csv',
        }),
      )
    } else {
      downloadCSReportWithToast('serviceTxnReport', {
        queryDate: moment(this.props.dateValue).toISOString(),
        type: 'csv',
      })
    }
  }

  handleViewServiceTxnBySeq = () => {
    const url = new URL('/report/web', window.location.href)
    url.searchParams.set('name', 'serviceTxnReport')
    url.searchParams.set(
      'queryDate',
      moment(this.props.dateValue).toISOString(),
    )
    url.searchParams.set('seq', this.state.pageNumber)
    window.open(url.href, '_blank')
  }

  handleViewAllServiceTxn = ({ fromSeq, toSeq }) => {
    const url = new URL('/report/web', window.location.href)
    url.searchParams.set('name', 'serviceTxnReport')
    url.searchParams.set(
      'queryDate',
      moment(this.props.dateValue).toISOString(),
    )
    url.searchParams.set('fromSeq', fromSeq)
    url.searchParams.set('toSeq', toSeq)
    window.open(url.href, '_blank')
  }

  handleDownloadServiceTxnBySeq = () => {
    if (isNewReportSystemEnabled) {
      handleReportRequest(
        requestReport('/api/reports/queue/serviceTxnReport', {
          type: 'pdf',
          seq: this.state.pageNumber,
          queryDate: moment(this.props.dateValue).toISOString(),
        }),
      )
    } else {
      downloadReportWithToast('serviceTxnReport', {
        seq: this.state.pageNumber,
        queryDate: moment(this.props.dateValue).toISOString(),
      })
    }
  }

  isNeedWorkSheet = () => {
    return this.props.serviceTxn ? this.props.serviceTxn.needWorkSheet : false
  }

  render() {
    return (
      <PrintSelectionModalAction
        totalPage={this.props.totalPage}
        onPrint={this.handleViewAllServiceTxn}
        limitRange={250}
      >
        {(handleViewAllServiceTxnInDate) => (
          <PrintSelectionModalAction
            totalPage={this.props.totalPage}
            onPrint={this.handleDownloadAllServiceTxnInDate}
            limitRange={250}
          >
            {(handleDownloadAllServiceTxnInDate) => (
              <ServiceFormPagerView
                pageNumber={this.state.pageNumber}
                totalPage={this.props.totalPage}
                onPageChange={this.handlePageChange}
                onDownloadAllServiceTxn={handleDownloadAllServiceTxnInDate}
                onDownloadServiceTxnBySeq={this.handleDownloadServiceTxnBySeq}
                onDownloadServiceTxnInCSV={
                  this.handleDownloadAllServiceTxnCSVInDate
                }
                onViewAllServiceTxn={handleViewAllServiceTxnInDate}
                onViewServiceTxnBySeq={this.handleViewServiceTxnBySeq}
                isNeedWorkSheet={this.isNeedWorkSheet()}
                dateValue={this.props.dateValue}
                onDateChange={this.props.onDateChange}
                goNextPage={this.goNextPage}
                goPrevPage={this.goPrevPage}
                hasPrevPage={this.hasPrevPage()}
                hasNextPage={this.hasNextPage()}
                currentServiceTxn={this.props.serviceTxn}
                onFormSubmit={this.handleFormSubmit}
              />
            )}
          </PrintSelectionModalAction>
        )}
      </PrintSelectionModalAction>
    )
  }
}
