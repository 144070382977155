import React from 'react'
import { reduxForm } from 'redux-form'
import { Button, Row, Col } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { get, isEmpty } from 'lodash'

import { Toolbar } from '../form'
import { required, validateWith, isPositiveNumber } from '../form/validator'
import StatusBadge from '../commons/StatusBadge'
import { dateFormat } from '../table/index'
import UpdatePricingProductTableContainer from './UpdatePricingProductTableContainer'
import { pricingProductType } from '../../services/pricingProduct'

const validate = (values) => {
  const errors = {}
  errors.prices = (values.prices || []).map((price) => {
    switch (price.type) {
      case pricingProductType.fixed:
        return {
          fixedPriceInfo: {
            price: validateWith(get(price, 'fixedPriceInfo.price'), [required]),
            addendumPrice: validateWith(
              get(price, 'fixedPriceInfo.addendumPrice'),
              [required],
            ),
          },
        }
      case pricingProductType.stepped:
        return {
          steppedPriceInfo: {
            steps: isEmpty(price.steppedPriceInfo.steps)
              ? 'Required'
              : price.steppedPriceInfo.steps.map((step) => ({
                  limit: validateWith(step.limit, [isPositiveNumber]),
                  price: validateWith(step.price, [required]),
                  addendumPrice: validateWith(step.addendumPrice, [required]),
                })),
          },
        }
      default:
        break
    }
    return {}
  })

  return errors
}

const PricingContractProductPriceForm = ({
  initialValues,
  formValues,
  form,
  formSyncErrors,
  ...rest
}) => {
  if (!formValues || !formValues.contractorType || !formValues.id) return null
  const { fields } = I18n.t('pricingContractPage')
  return (
    <div className="container-fluid">
      <Toolbar
        customTitle={<PricingContractFormTitle pricingContract={formValues} />}
      >
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>

      <Row>
        <Col md={6}>
          <ContractorTitle fields={fields} pricingContract={formValues} />
        </Col>
        <Col md={3}>
          <dl>
            <dt>{fields.startDate}</dt>
            <dd>{dateFormat(formValues.startDate)}</dd>
          </dl>
        </Col>
        <Col md={3}>
          <dl>
            <dt>{fields.endDate}</dt>
            <dd>{dateFormat(formValues.endDate)}</dd>
          </dl>
        </Col>
      </Row>

      <Row>
        <UpdatePricingProductTableContainer
          form={form}
          fields={fields}
          pricingContract={formValues}
          formSyncErrors={formSyncErrors}
        />
      </Row>
    </div>
  )
}

const PricingContractFormTitle = ({ pricingContract }) => {
  const { productPricingForm, fields } = I18n.t('pricingContractPage')
  const titleTemplate = productPricingForm.title
  const contractorTitle = fields.contractorType[pricingContract.contractorType]
  const title = titleTemplate.replace('{0}', contractorTitle)
  return (
    <span>
      {title}{' '}
      {pricingContract.status && (
        <StatusBadge>{pricingContract.status}</StatusBadge>
      )}
    </span>
  )
}

const ContractorTitle = ({ pricingContract, fields }) => {
  return (
    <dl>
      <dt>{fields.contractorType[pricingContract.contractorType]}</dt>
      <dd>{getContractorTitle(pricingContract) || '-'}</dd>
    </dl>
  )
}

const getContractorTitle = (pricingContract) => {
  switch (pricingContract.contractorType) {
    case 'agent': {
      const { code, name } = get(pricingContract, 'agent')
      return `${name} (${code})`
    }
    case 'airline': {
      const { code, name } = get(pricingContract, 'airline')
      return `${name} (${code})`
    }
    case 'card': {
      const { code, name } = get(pricingContract, 'card')
      return `${name} (${code})`
    }
    default:
      throw Error('unhandled contractor type')
  }
}

export default reduxForm({
  validate,
})(PricingContractProductPriceForm)
