import React from 'react'
import AgentSearchSelectField from '../agent/AgentSearchSelectField'
import AirlineSearchSelectField from '../airline/AirlineSearchSelectField'
import CardSearchSelectField from '../card/CardSearchSelectField'

export default ({ contractorType, fields, initialValues }) => {
  switch (contractorType) {
    case 'agent':
      return (
        <AgentSearchSelectField
          name="agentId"
          selectValue={(agent) => agent._id}
          label={fields.contractorType.agent}
          initialValue={initialValues.agentId}
        />
      )
    case 'card':
      return (
        <CardSearchSelectField
          name="cardId"
          label={fields.contractorType.card}
          initialValue={initialValues.cardId}
          selectValue={(card) => card._id}
          preFetch
        />
      )
    case 'airline':
      return (
        <AirlineSearchSelectField
          name="airlineId"
          label={fields.contractorType.airline}
          initialValue={initialValues.airlineId}
          selectValue={(airline) => airline._id}
        />
      )
    default:
      return null
  }
}
