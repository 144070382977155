import React from 'react'
import { reduxForm } from 'redux-form'
import { required, validateWith } from '../form/validator'
import { Button, Col, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import _ from 'lodash'

import { TextField, Toolbar, MultiSelectField, ImageUploadField } from '../form'

const roleOptions = [
  { label: 'USER', value: 'user' },
  { label: 'SUPERVISOR', value: 'supervisor' },
  { label: 'SUPER ADMIN', value: 'superAdmin' },
  { label: 'FINANCE', value: 'finance' },
]

const validate = (values) => {
  const errors = {}
  errors.displayName = validateWith(values.displayName, [required])
  errors.username = validateWith(values.username, [required])

  if (!values._id) {
    errors.password = validateWith(values.password, [required])
  } else {
    const { password, confirmPassword } = values
    if (!_.isEmpty(password) && !_.isEmpty(confirmPassword)) {
      const trimedPassword = password.trim()
      const trimedConfirmPassword = confirmPassword.trim()
      if (trimedPassword !== trimedConfirmPassword) {
        errors.password = 'New Password and confirm password is not match.'
        errors.confirmPassword =
          'Please check your new password and confirm password again.'
      }
    }
    if (!_.isEmpty(values.email) && !/^(.+)@(.+)$/.test(values.email)) {
      errors.email = "Email format doesn't seem correct, please revise it."
    }
  }
  errors.roles = validateWith(values.roles, [required])

  return errors
}

const UserForm = ({ initialValues, signatureUploadURL }) => {
  const { form, fields } = I18n.t('userPage')
  return (
    <div className="container">
      <Toolbar title={initialValues._id ? form.update : form.new}>
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>
      <Row>
        <Col xs={6}>
          <Row>
            <Col sm={12}>
              <TextField
                name="displayName"
                label={fields.displayName}
                addStar={true}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <TextField name="username" label={fields.username} />
            </Col>
          </Row>
          {!initialValues._id && (
            <Row>
              <Col sm={12}>
                <TextField
                  type="text"
                  name="password"
                  label={fields.password}
                  addStar={true}
                />
              </Col>
            </Row>
          )}

          {initialValues._id && (
            <div>
              <Row>
                <Col sm={12}>
                  <TextField
                    type="password"
                    name="password"
                    label={fields.newPassword}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <TextField
                    type="password"
                    name="confirmPassword"
                    label={fields.confirmPassword}
                  />
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col sm={12}>
              <TextField name="email" label={fields.email} addStar={false} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <MultiSelectField
                name="roles"
                label={fields.roles}
                addStar={true}
                options={roleOptions}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={6}>
          <Col sm={12}>
            <TextField
              type="roleTitle"
              name="roleTitle"
              label={fields.roleTitle}
            />
          </Col>

          <Col sm={12}>
            {signatureUploadURL ? (
              <ImageUploadField
                name="signature"
                label={fields.signature}
                width={'50%'}
                uploadURL={signatureUploadURL}
                emptyLabel={fields.signatureUploadEmpty}
              />
            ) : (
              fields.signatureUploadInfo
            )}
          </Col>
        </Col>
      </Row>
    </div>
  )
}

export default reduxForm({
  validate,
})(UserForm)
