import React from 'react'
import { connect } from 'react-redux'
import { submit, stopSubmit } from 'redux-form'
import { shallowEqual } from 'recompose'
import _ from 'lodash'

import TopupForm from './TopupForm'
import {
  fetchTopup,
  createTopup,
  updateTopup,
  clearTopup,
} from '../../actions/remote_api'
import { formMode } from '../form/index'
import { topupStatus } from '../../services/topup'

class TopupFormPage extends React.Component {
  formId = 'topup'
  state = { formData: {} }

  componentDidMount() {
    const { fetchTopup, id, clearTopup } = this.props

    clearTopup()
    if (id !== undefined) {
      fetchTopup(id)
    }
  }

  componentWillUnmount() {
    this.props.clearTopup()
  }

  componentWillReceiveProps(nextProps) {
    if (!shallowEqual(nextProps.topup, this.props.topup)) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.topup },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = (formData) => {
    const { createTopup, updateTopup, id } = this.props
    const topup = formData
    if (id === undefined)
      createTopup(topup, { onResponse: this.handleResponse })
    else updateTopup(topup, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = (e) => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  isLocked = () => _.get(this.props, 'topup.status') === topupStatus.valid

  render() {
    return (
      <form onSubmit={this.submit}>
        <TopupForm
          formMode={formMode(this.props.id)}
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          enableReinitialize
          isLocked={this.isLocked()}
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { topup }, ...state }) => ({
    topup: topup ? topup.single : {},
    errors: topup ? topup.singleErrors : null,
  }),
  {
    createTopup,
    updateTopup,
    fetchTopup,
    clearTopup,
    submit,
    stopSubmit,
  },
)(TopupFormPage)
