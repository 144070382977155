import types from '../constants'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_ALERT:
      return {
        ...state,
        isShowAlert: true,
        info: action.info,
        meta: action.meta,
      }
    case types.HIDE_ALERT:
      return initialState
    case types.TRIGGER_ALERT_ACTION:
      if (state.isPrimaryTriggered || state.isSecondaryTriggered) return state
      return {
        ...state,
        isPrimaryTriggered: action.name === 'primary',
        isSecondaryTriggered: action.name === 'secondary',
      }
    case types.ALERT_ACTION_END:
      return {
        ...state,
        isPrimaryTriggered: false,
        isSecondaryTriggered: false,
      }
    default:
      return state
  }
}

const initialState = {
  isShowAlert: false,
  isPrimaryTriggered: false,
  isSecondaryTriggered: false,
  info: {},
  meta: {},
}
