import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Panel } from 'react-bootstrap'
import LoginFormContainer from './LoginFormContainer'
import styled from 'styled-components'
import { I18n } from 'react-redux-i18n'

const Login = ({
  onLogin,
  from,
  username,
  password,
  onChange,
  message,
  error,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    handleLogin()
  }
  const handleLogin = () => onLogin({ username, password })
  return (
    <div className="login-container">
      <div className="wrapper">
        <form className="form-signin" onSubmit={handleSubmit}>
          <Margin>
            <div
              className="form-signin-heading"
              style={{ textAlign: 'center' }}
            >
              <img
                className="img-nav"
                src={require('../../img/coral-logo.png')}
                alt="logo"
                width="150px"
              />
            </div>
          </Margin>
          <Margin>
            <Title>Sign in</Title>
            <Paragraph>to continue to Coral Backoffice</Paragraph>
          </Margin>
          {error && <Panel header={I18n.t('authError')} bsStyle="danger" />}
          {message && <Panel header={message} bsStyle="danger" />}
          <input
            type="text"
            className="form-control"
            name="username"
            placeholder="Username"
            value={username}
            onChange={(e) => onChange('username', e.currentTarget.value)}
          />
          <input
            type="password"
            className="form-control"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => onChange('password', e.currentTarget.value)}
          />
          <button className="btn btn-lg btn-primary btn-block">Login</button>
        </form>
      </div>
    </div>
  )
}

const LoginWithForm = (props) => (
  <LoginFormContainer>
    <Login {...props} />
  </LoginFormContainer>
)

const Margin = styled.div`
  margin: 16px;
`
const Title = styled.h3`
  text-align: center;
`

const Paragraph = styled.p`
  text-align: center;
`

export default connect((state) => ({ error: _.get(state, 'auth.error') }))(
  LoginWithForm,
)
