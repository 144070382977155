import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default ({
  isShowAlert,
  hideAlert,
  triggerAction,
  info: { primary, secondary, ...info },
}) => (
  <Modal show={isShowAlert} onHide={hideAlert} bsSize="sm">
    <Modal.Header closeButton>
      <Modal.Title>{info.header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{info.description}</Modal.Body>
    <Modal.Footer>
      {info.defaultCancelButton && (
        <Button bsStyle="default" onClick={hideAlert}>
          {info.defaultCancelButtonTitle || 'Cancel'}
        </Button>
      )}
      {secondary && (
        <Button
          bsStyle={secondary.bsStyle}
          onClick={() => triggerAction('secondary')}
        >
          {secondary.title}
        </Button>
      )}
      {primary && (
        <Button
          bsStyle={primary.bsStyle}
          onClick={() => triggerAction('primary')}
        >
          {primary.title}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
)
