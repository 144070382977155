import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import { fetchServiceMappings } from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'

class ServiceMappingSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId="serviceMappingOptions"
        preFetch
      />
    )
  }
}

const createQuery = (searchText) => ({
  _sort: 'name',
  _order: 'asc',
})

const defaultValueQuery = (initialId) => ({
  _id: initialId,
})

const createLabel = (serviceMapping) => serviceMapping.name

export default connect(null, {
  fetchOptions: fetchServiceMappings,
  clearOptions: undefined,
})(ServiceMappingSearchSelect)
