import React from 'react'
import styled from 'styled-components'

const Panel = styled.div`
  height: 500px !important;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TableLoading = () => (
  <Panel>
    <h1>Loading</h1>
  </Panel>
)

export default TableLoading
