import React from 'react'

export default function WebReservationReport({
  data: {
    options: { data, dataset },
  },
}) {
  const { IMAGE_URL, title } = data
  return (
    <div className="w-[1180px] mx-auto py-6 px-6 bg-white print:bg-transparent shadow print:shadow-none">
      <div className="flex flex-col space-y-4">
        <div className="relative w-full flex justify-center p-4">
          <span className="text-2xl font-semibold">{title}</span>
          <img
            className="absolute top-[0.5rem] right-0 object-contain h-8"
            src={`${IMAGE_URL}/coral-logo.png`}
            alt="coral"
          />
        </div>

        {dataset.reservations.length > 0 ? (
          <div className="space-y-10">
            {dataset.reservations.map((reservation, index) => {
              return (
                <div className="flex items-start w-full space-x-4 break-inside-avoid">
                  <div className="text-2xl">{index + 1}</div>
                  <div className="flex-1 flex flex-col space-y-4">
                    <div className="flex justify-between space-x-2">
                      <span className="flex flex-col flex-[2_2_0%]">
                        <span className="uppercase text-sm font-semibold">
                          Passenger Name
                        </span>
                        <span className="uppercase">
                          {reservation.mainPaxName}
                        </span>
                      </span>
                      <span className="flex flex-col flex-[2_2_0%]">
                        <span className="uppercase text-sm font-semibold">
                          Confirmation Number
                        </span>
                        <span className="uppercase">
                          {reservation.confirmationNumber}
                        </span>
                      </span>
                      <span className="flex flex-col flex-[5_5_0%]">
                        <span className="uppercase text-sm font-semibold">
                          Product Name
                        </span>
                        <span className="uppercase">
                          {reservation.productName}
                        </span>
                      </span>
                      <span className="flex flex-col flex-[2_2_0%]">
                        <span className="uppercase text-sm font-semibold">
                          Service Date/Time
                        </span>
                        <span className="uppercase">
                          {reservation.serviceDateTime}
                        </span>
                      </span>
                      <span className="flex flex-col flex-1">
                        <span className="uppercase text-sm font-semibold">
                          Flight Number
                        </span>
                        <span className="uppercase">
                          {reservation.flightNumber}
                        </span>
                      </span>
                      <span className="flex flex-col flex-1">
                        <span className="uppercase text-sm font-semibold">
                          Status
                        </span>
                        <span className="uppercase">{reservation.status}</span>
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <span className="flex flex-col flex-1">
                        <span className="uppercase text-sm font-semibold">
                          Agent Name
                        </span>
                        <span className="uppercase">
                          {reservation.agentName}
                        </span>
                      </span>
                      <span className="flex space-x-2 flex-1">
                        <span className="flex flex-col">
                          <span className="uppercase text-sm font-semibold text-center">
                            Adults
                          </span>
                          <span className="uppercase text-center">
                            {reservation.guestPaxAdult}
                          </span>
                        </span>
                        <span className="flex flex-col">
                          <span className="uppercase text-sm font-semibold text-center">
                            Children
                          </span>
                          <span className="uppercase text-center">
                            {reservation.guestPaxChild}
                          </span>
                        </span>
                        <span className="flex flex-col">
                          <span className="uppercase text-sm font-semibold text-center">
                            Infants
                          </span>
                          <span className="uppercase text-center">
                            {reservation.guestPaxInfant}
                          </span>
                        </span>
                      </span>
                      <span className="flex flex-col flex-1">
                        <span className="uppercase text-sm font-semibold">
                          Important Note
                        </span>
                        <span className="uppercase">
                          {reservation.importantNote || '-'}
                        </span>
                      </span>
                      <span className="flex flex-col flex-1">
                        <span className="uppercase text-sm font-semibold">
                          Private Note
                        </span>
                        <span className="uppercase">
                          {reservation.privateNote || '-'}
                        </span>
                      </span>
                    </div>

                    <div className="flex justify-between space-x-12 pl-8">
                      <span className="flex flex-col flex-1">
                        <div className="flex flex-col -space-y-1">
                          <span className="uppercase text-sm font-semibold">
                            Services
                          </span>
                          <div className="bg-gray-300 h-px w-full"></div>
                        </div>

                        <div>
                          {reservation.serviceTxns.length > 0 ? (
                            reservation.serviceTxns.map((serviceTxn) => {
                              return (
                                <div className="flex justify-between">
                                  <span>{serviceTxn.serviceTitle}</span>
                                  <span>{serviceTxn.inTime || '-'}</span>
                                  <span>{serviceTxn.outTime || '-'}</span>
                                </div>
                              )
                            })
                          ) : (
                            <span className="uppercase font-semibold">-</span>
                          )}
                        </div>
                      </span>

                      <span className="flex flex-col flex-1">
                        <div className="flex flex-col -space-y-1">
                          <span className="uppercase text-sm font-semibold">
                            Job Assignments
                          </span>
                          <div className="bg-gray-300 h-px w-full"></div>
                        </div>
                        <div>
                          {reservation.jobAssignments.length > 0 ? (
                            reservation.jobAssignments.map((jobAssignment) => {
                              return (
                                <div className="flex justify-between">
                                  <span>{jobAssignment.staffName}</span>
                                  <span>{jobAssignment.service}</span>
                                </div>
                              )
                            })
                          ) : (
                            <span className="uppercase font-semibold">-</span>
                          )}
                        </div>
                      </span>
                    </div>

                    <div className="pt-4">
                      <div className="bg-gray-300 h-px w-full"></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="p-10 w-full text-center text-2xl font-semibold">
            No Results
          </div>
        )}
      </div>
    </div>
  )
}
