import React from 'react'

import UpdatePricingProductTable from './UpdatePricingProductTable'
import { pricingProductType } from '../../services/pricingProduct'

class UpdatePricingProductTableContainer extends React.Component {
  infoField = (type) => {
    switch (type) {
      case pricingProductType.fixed:
        return 'fixedPriceInfo'
      case pricingProductType.stepped:
        return 'steppedPriceInfo'
      default:
        break
    }
  }

  render() {
    return (
      <UpdatePricingProductTable
        pricingContract={this.props.pricingContract}
        fields={this.props.fields}
        formSyncErrors={this.props.formSyncErrors}
      />
    )
  }
}

export default UpdatePricingProductTableContainer
