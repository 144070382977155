import React from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { DateTimeField, ListField, Toolbar } from '../form'
import { required, date, validateWith } from '../form/validator'
import { Row, Col, ButtonGroup, Button, Badge } from 'react-bootstrap'
import styled from 'styled-components'

import { I18n } from 'react-redux-i18n'
import { dateTimeFormat } from '../table'
import StepperForReservation from './StepperForReservation'
import {
  isServiceHasWarning,
  getAssignedAmount,
} from '../../services/jobAssignment'
import { makeServiceTitle } from '../../components'
import StaffSearchSelectField from '../staff/StaffSearchSelectField'
import ServiceSearchSelectField from '../service/ServiceSearchSelectField'

const validate = (values) => {
  const errors = {}
  errors.jobAssignments = (values.jobAssignments || []).map((j) => ({
    staffId: validateWith(j.staffId, [required]),
    service: validateWith(j.service, [required]),
    issuedServiceDateTime: validateWith(j.issuedServiceDateTime, [
      required,
      date,
    ]),
  }))
  return errors
}

let AssignJobForm = ({
  initialValues,
  jobAssignments,
  onNewJobAssignment,
  isCompleteState,
  onDraftClick,
  onAssignedClick,
  onFinishedAssign,
  ...props
}) => {
  const { form, fields } = I18n.t('reservationPage')
  const { display: dateDisplay } = I18n.t('date')
  const { display: timeDisplay } = I18n.t('time')
  var serviceOptions = []
  if (initialValues.product) {
    serviceOptions = initialValues.product.services.map((s) => ({
      label: makeServiceTitle(s),
      value: s,
    }))
  }

  return (
    <div className="container">
      <Toolbar title={form.assignJob} titleComponent={ReservationTitle}>
        <RightBarButtonHolder>
          <ToolbarButton className="btn-default" onClick={onDraftClick}>
            <span className="glyphicon glyphicon-edit" /> Save as Draft
          </ToolbarButton>
          <ToolbarButton className="btn-primary" onClick={onAssignedClick}>
            <span className="glyphicon glyphicon-floppy-saved" /> Save as
            Assigned
          </ToolbarButton>
          <ToolbarButton className="btn-primary" onClick={onFinishedAssign}>
            <span className="glyphicon glyphicon-chevron-left" /> Done
          </ToolbarButton>
        </RightBarButtonHolder>
      </Toolbar>
      <Row>
        <ReservationDetail
          jobAssignmentsFormValue={jobAssignments}
          reservationInitValue={initialValues}
        />
        <hr />
        <Col md={12}>
          <ListField
            name="jobAssignments"
            label={fields.jobAssignments}
            disabled={initialValues.jobAssignStatus === 'completed'}
            itemLabels={fields.jobAssignmentItems}
            inputFieldItem={JobAssignmentField}
            dateDisplay={dateDisplay}
            timeDisplay={timeDisplay}
            serviceOptions={serviceOptions}
            initialValues={initialValues.jobAssignments}
            defaultServiceDateTime={initialValues.serviceDateTime}
            makeNewItem={onNewJobAssignment}
          />
        </Col>
      </Row>
      <FooterSpace />
    </div>
  )
}

const ReservationTitle = ({ title }) => (
  <span>
    {title}{' '}
    <Field name="confirmationNumber" component={renderConfirmationNum} />{' '}
    <Field name="status" component={renderStatusBadge} />
  </span>
)
const renderConfirmationNum = ({ input: { value } }) =>
  value ? <b>#{value}</b> : null

const JobAssignmentField = ({
  path,
  itemLabels,
  onRemove,
  index,
  initialValues,
  serviceOptions,
  disabled,
  ...props
}) => {
  return (
    <Row>
      <Col md={4}>
        <StaffSearchSelectField
          disabled={disabled}
          name={`${path}.staffId`}
          label={itemLabels.staff}
          selectId={`staff-${index}`}
          initialValue={
            initialValues[index] ? initialValues[index].staffId : undefined
          }
          selectValue={(staff) => staff._id}
        />
      </Col>
      <Col md={4}>
        {/* <SelectField
          disabled={disabled}
          name={`${path}.service`}
          format={service =>
            service && Object.keys(service).length > 0
              ? { value: service, label: makeServiceTitle(service) }
              : null
          }
          label={itemLabels.service}
          simpleValue={false}
          options={serviceOptions}
        /> */}
        <ServiceSearchSelectField
          disabled={disabled}
          name={`${path}.service`}
          label={itemLabels.service}
          preFetch
        />
      </Col>
      <Col md={3}>
        <DateTimeField
          inputProps={{ disabled: disabled }}
          name={`${path}.issuedServiceDateTime`}
          label={itemLabels.issuedServiceDateTime}
          dateFormat={props.dateDisplay}
          timeFormat={props.timeDisplay}
        />
      </Col>
      {disabled === false && (
        <DelBtnContainer md={1}>
          <Button onClick={onRemove} bsStyle="danger" className="pull-right">
            <span className="glyphicon glyphicon-trash" />
          </Button>
        </DelBtnContainer>
      )}
    </Row>
  )
}

const renderStatusBadge = ({ input: { value } }) =>
  value ? <StatusBadge status={value} /> : null

const StatusBadge = ({ status }) => <Badge>{status.toUpperCase()}</Badge>

const optionalTextFormat = (text) => (text ? text : '-')

const ReservationDetail = ({
  reservationInitValue: reservation,
  jobAssignmentsFormValue,
}) => {
  const { fields } = I18n.t('reservationPage')
  const { fields: pfields } = I18n.t('productPage')

  return (
    <div className="form-horizontal">
      <StepperForReservation instance={reservation} />

      <div className="row">
        <div className="col-sm-7">
          <div className="form-group">
            <label className="col-sm-5 control-label">{fields.product}</label>
            <div className="col-sm-7">
              <p className="form-control-static">
                {reservation.product && reservation.product.name}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-5 control-label">
              {fields.serviceDateTime}
            </label>
            <div className="col-sm-7">
              <p className="form-control-static">
                {reservation.serviceDateTime
                  ? dateTimeFormat(reservation.serviceDateTime)
                  : '-'}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-5 control-label">
              {fields.boardingDateTime}
            </label>
            <div className="col-sm-7">
              <p className="form-control-static">
                {reservation.boardingDateTime
                  ? dateTimeFormat(reservation.boardingDateTime)
                  : '-'}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-5 control-label">
              {fields.importantNote}
            </label>
            <div className="col-sm-7">
              <p className="form-control-static">
                {reservation && optionalTextFormat(reservation.importantNote)}
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="form-group">
            <label className="col-sm-6 control-label">
              {fields.mainPax.name}
            </label>
            <div className="col-sm-6">
              <p className="form-control-static">
                {reservation &&
                  reservation.mainPax &&
                  reservation.mainPax.fullName}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-6 control-label">
              {fields.luggageAmount}
            </label>
            <div className="col-sm-6">
              <p className="form-control-static">
                {reservation && reservation.luggageAmount}
              </p>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group col-sm-4">
              <label className="col-sm-6 control-label">
                {fields.guestPaxAdult}
              </label>
              <div className="col-sm-6">
                <p className="form-control-static">
                  {reservation && reservation.guestPaxAdult}
                </p>
              </div>
            </div>
            <div className="form-group col-sm-4">
              <label className="col-sm-6 control-label">
                {fields.guestPaxChild}
              </label>
              <div className="col-sm-6">
                <p className="form-control-static">
                  {reservation && reservation.guestPaxChild}
                </p>
              </div>
            </div>
            <div className="form-group col-sm-4">
              <label className="col-sm-6 control-label">
                {fields.guestPaxInfant}
              </label>
              <div className="col-sm-6">
                <p className="form-control-static">
                  {reservation && reservation.guestPaxInfant}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">
              {fields.privateNote}
            </label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {reservation && optionalTextFormat(reservation.privateNote)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="pull-left" style={{ height: '33px' }}>
          <strong>{pfields.services}</strong>
        </div>
        <br />
        {reservation.product && (
          <ServicesDetail
            services={reservation.product.services}
            jobAssignments={jobAssignmentsFormValue}
          />
        )}
      </div>
    </div>
  )
}

const ServicesDetail = ({ services, jobAssignments }) => {
  return (
    <div>
      {services.map((s, key) => {
        return (
          <ButtonGroup key={s._id} style={{ marginRight: 15 }}>
            <Button>
              <b>{makeServiceTitle(s)}</b>
            </Button>
            <Button
              className={
                isServiceHasWarning(s, jobAssignments)
                  ? 'btn-warning'
                  : 'btn-success'
              }
            >
              {getAssignedAmount(s, jobAssignments)}
            </Button>
          </ButtonGroup>
        )
      })}
    </div>
  )
}

const ToolbarButton = styled(Button)`
  min-width: 150px;
`

const RightBarButtonHolder = styled.div`
  > * {
    margin: 0 4px;
  }
`

const DelBtnContainer = styled(Col)`
  margin-top: 27px;
`
const FooterSpace = styled.div`
  min-height: 300px;
`

AssignJobForm = reduxForm({
  validate,
})(AssignJobForm)

const selector = formValueSelector('jobAssignments')

AssignJobForm = connect((state) => {
  return { jobAssignments: selector(state, 'jobAssignments') }
})(AssignJobForm)

export default AssignJobForm
