import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import { fetchProducts, clearProduct } from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'
import { escape } from '../../utils/regex'

class ProductSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId="productOptions"
      />
    )
  }
}

const createQuery = (searchText) => ({
  name_like: searchText ? escape(searchText.toLowerCase()) : undefined,
  sort: 'name',
  order: 'asc',
  limit: 100,
})

const defaultValueQuery = (initialId) => ({
  _id: initialId,
})

const createLabel = (p) => p.name

export default connect(null, {
  fetchOptions: fetchProducts,
  clearOptions: clearProduct,
})(ProductSearchSelect)
