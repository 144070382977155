import React from 'react'
import { connect } from 'react-redux'
import { debounce, get } from 'lodash'

import {
  fetchReservations,
  deleteReservation,
  confirmReservation,
} from '../../actions/fetch_reservation'
import { KioskReservationTable } from './ReservationTable'
import { createQueryFilter } from './createQueryFilter'

class KioskReservationTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = (filter) => {
    this.props.fetchReservations(
      {
        page: filter.currentPage,
        limit: filter.sizePerPage,
        sort: filter.sort,
        order: filter.order,
        createByKiosk: true,
        ...createQueryFilter(filter.query),
      },
      { fetchId: 'kioskReservation' },
    )
  }

  customCreateButtonGroup = (props) => <div />

  render() {
    return (
      <KioskReservationTable
        {...this.props}
        onDataChange={this.handleDataChange}
        onConfirm={this.handleReservationConfirm}
        headerI18nKey="reservationKioskPage.table.header"
        isAgentColumnShow={false}
        i18nPageKey="reservationPage"
        defaultSortName="serviceDateTime"
        defaultSortOrder="desc"
        options={{ btnGroup: this.customCreateButtonGroup }}
        settings={{ insertRow: false, deleteRow: false, selectRow: undefined }}
      />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    isLoading: get(fetch, 'kioskReservation.isLoading.list', false),
    instances: get(fetch, 'kioskReservation.list', []),
    totalCount: get(fetch, 'kioskReservation.totalCount'),
  }),
  { fetchReservations, deleteInstance: deleteReservation, confirmReservation },
)(KioskReservationTableContainer)
