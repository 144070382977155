import { compose, withStateHandlers, withHandlers } from 'recompose'

export const withConfirmModalHandlers = compose(
  withStateHandlers(() => ({ isShow: false }), {
    onShow: () => () => ({
      isShow: true,
    }),
    onHide: () => () => ({
      isShow: false,
    }),
  }),
  withHandlers({
    onConfirm:
      ({ onConfirm, onHide }) =>
      () => {
        onConfirm(onHide)
      },
  }),
)
