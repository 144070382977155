import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { fetchProduct } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'

const ProductDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('productPage')
  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.name}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.name}</p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.services}</label>
        <div className="col-sm-8 form-control-static">
          <table className="table table-border">
            <thead>
              <tr>
                <th>Class</th>
                <th>Name</th>
              </tr>
            </thead>
            {instance.services &&
              instance.services.map((s, index) => (
                <tr key={index}>
                  <td>{s.displayName}</td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </form>
  )
}

let ProductDetailPopupContainer = withDetailPopup(ProductDetailPopup, () => ({
  header: I18n.t('productPage.detail.header'),
}))

export default connect(
  (state) => ({ instance: get(state, 'fetch.product.single') }),
  { fetchInstance: fetchProduct },
)(ProductDetailPopupContainer)
