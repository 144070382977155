import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import { fetchVoucherBooks, clearVoucherBook } from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'
import { escape } from '../../utils/regex'

export const mapVoucherBookToVoucherBookOption = (s) => s.name

class VoucherBookSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={mapVoucherBookToVoucherBookOption}
        defaultValueQuery={defaultValueQuery}
        selectId="voucherBookOptions"
      />
    )
  }
}

const createQuery = (searchText) => ({
  name_like: searchText ? escape(searchText.toLowerCase()) : undefined,
})

const defaultValueQuery = (initialId) => ({ _id: initialId })

export default connect(null, {
  fetchOptions: fetchVoucherBooks,
  clearOptions: clearVoucherBook,
})(VoucherBookSearchSelect)
