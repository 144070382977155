import React from 'react'
import { Row, Col } from 'react-bootstrap'
import StationSearchSelectField from '../station/StationSearchSelectField'

const StationServiceItemField = ({
  path,
  itemLabels,
  onRemove,
  index,
  initialValues,
  disabled,
  ...props
}) => (
  <Row>
    <Col md={9}>
      <StationSearchSelectField
        disabled={disabled}
        name={`${path}.station`}
        selectId={`station-${index}`}
        format={(station) =>
          station && Object.keys(station).length > 0
            ? { value: station, label: station.name }
            : null
        }
        label={itemLabels && itemLabels[index]}
        simpleValue={false}
      />
    </Col>
  </Row>
)

export default StationServiceItemField
