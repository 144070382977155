import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, memoize, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
import Icon from 'react-icons-kit'
import { cash } from 'react-icons-kit/ionicons/cash'
import { ButtonGroup } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { check, times } from 'react-icons-kit/fa'

import {
  fetchPricingContracts,
  deletePricingContract,
} from '../../actions/remote_api'
import { withDetailPopupProvider } from '../detailPopup'
import { withTableConfigs, withDeleteModal, dateFormat } from '../table'
import PricingContractDetailPopup from './PricingContractDetailPopup'
import PricingContractTableFilterPanel from './PricingContractTableFilterPanel'
import { InsertButton } from '../commons/InsertButton'
import PricingContractSideMenus from './PricingContractSideMenus'
import styledComponents from 'styled-components'
import { createFetchId, getFetchPricingContract } from './index'
import AddFromTemplateButton from './AddFromTemplateButton'
import { UpdatePricingContractStatus } from './UpdatePricingContractStatus'
import { escape } from '../../utils/regex'

class PricingContractTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = (filter) => {
    this.props.fetchPricingContracts(
      {
        page: filter.currentPage,
        limit: filter.sizePerPage,
        sort: filter.sort,
        order: filter.order,
        contractorType: this.props.contractorType,
        isTemplate: this.props.isTemplate,
        ...this.createQueryFilter(filter.query),
      },
      { fetchId: createFetchId(this.props.contractorType) },
    )
  }

  createQueryFilter = (filter) => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.name) queryfilter.name_like = escape(filter.name)
    if (filter.status) queryfilter.status = filter.status

    return queryfilter
  }

  render() {
    return (
      <PricingContractTable
        {...this.props}
        onDataChange={this.handleDataChange}
        customCreateButtonGroup={customCreateButtonGroup}
      />
    )
  }
}

export default connect(
  (state, props) => {
    const pricingContracts = getFetchPricingContract(
      state,
      props.contractorType,
    )
    return {
      instances: pricingContracts ? pricingContracts.list : [],
      totalCount: pricingContracts ? pricingContracts.totalCount : undefined,
    }
  },
  (dispatch, props) => ({
    fetchPricingContracts: (query, options) =>
      dispatch(fetchPricingContracts(query, options)),
    deleteInstance: (id) =>
      dispatch(
        deletePricingContract(id, {
          fetchId: createFetchId(props.contractorType),
        }),
      ),
  }),
)(PricingContractTableContainer)

let PricingContractTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
  contractorType,
}) => {
  const { fields, table } = I18n.t('pricingContractPage')
  return (
    <Layout>
      <PricingContractSideMenus />
      <Content>
        <h1 className="page-header">{table.header}</h1>
        {renderFilterPanel}
        {renderToolbar}
        <BootstrapTable {...defaultSettings} options={defaultOptions}>
          <TableHeaderColumn dataField="name" dataFormat={detailLink} dataSort>
            {fields.name}
          </TableHeaderColumn>
          {isEmpty(contractorType) && (
            <TableHeaderColumn
              dataField="contractorType"
              dataFormat={contractorTypeFormat}
              dataAlign="center"
            >
              {fields.contractorTypeLabel}
            </TableHeaderColumn>
          )}
          <TableHeaderColumn
            dataField="startDate"
            dataFormat={dateFormat}
            dataAlign="center"
          >
            {fields.startDate}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="endDate"
            dataFormat={dateFormat}
            dataAlign="center"
          >
            {fields.endDate}
          </TableHeaderColumn>
          {!isEmpty(contractorType) && (
            <TableHeaderColumn
              dataField="status"
              dataFormat={pricingContractStatusFormat}
              dataAlign="center"
            >
              {fields.status}
            </TableHeaderColumn>
          )}
          <TableHeaderColumn
            dataField="edit"
            editable={false}
            dataFormat={renderActions(editButton, contractorType)}
            width="200px"
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Content>
    </Layout>
  )
}

const customCreateButtonGroup = (ownProps) => (props) => (
  <ButtonGroup>
    {ownProps.contractorType && (
      <InsertButton to={`/pricingContract/${ownProps.contractorType}/new`}>
        <i className="glyphicon glyphicon-plus" />{' '}
        {I18n.t('action.add').toUpperCase()}
      </InsertButton>
    )}
    {ownProps.contractorType && (
      <AddFromTemplateButton contractorType={ownProps.contractorType}>
        <i className="glyphicon glyphicon-plus" />{' '}
        {I18n.t('action.addFromTemplate').toUpperCase()}
      </AddFromTemplateButton>
    )}
    {props.deleteBtn}
  </ButtonGroup>
)

const contractorTypeFormat = (contractorType) =>
  I18n.t(`pricingContractPage.fields.contractorType.${contractorType}`)

const pricingContractStatusFormat = (status) => status.toUpperCase()

const renderActions = memoize(
  (editButton, contractorType) => (_, pricingContract) => (
    <div className="btn-group-vertical">
      {editButton(_, pricingContract, {
        path: `/pricingContract/${pricingContract.contractorType}/edit/${
          pricingContract.id
        }`,
      })}
      <Link
        className="btn btn-default"
        to={`/pricingContract/${pricingContract.contractorType}/pricing/${
          pricingContract.id
        }`}
      >
        <Icon icon={cash} /> Price
      </Link>
      <UpdatePricingContractStatus
        status="valid"
        pricingContract={pricingContract}
        fetchId={createFetchId(contractorType)}
      >
        {(updateStatus) =>
          !isEmpty(contractorType) &&
          pricingContract.status === 'draft' && (
            <Button bsStyle="success" onClick={updateStatus}>
              <Icon icon={check} />{' '}
              {I18n.t('pricingContractPage.table.markValid')}
            </Button>
          )
        }
      </UpdatePricingContractStatus>
      <UpdatePricingContractStatus
        status="invalid"
        pricingContract={pricingContract}
        fetchId={createFetchId(contractorType)}
      >
        {(updateStatus) =>
          !isEmpty(contractorType) &&
          pricingContract.status === 'draft' && (
            <Button bsStyle="danger" onClick={updateStatus}>
              <Icon icon={times} />{' '}
              {I18n.t('pricingContractPage.table.markInvalid')}
            </Button>
          )
        }
      </UpdatePricingContractStatus>
    </div>
  ),
)

const Layout = styledComponents.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 505px;
`

const Content = styledComponents.div`
  flex: 5;
  min-width: 500px;
`

PricingContractTable = withTableConfigs(({ contractorType }) =>
  createFetchId(contractorType),
)(PricingContractTable, PricingContractTableFilterPanel)
PricingContractTable = withDetailPopupProvider(
  PricingContractTable,
  PricingContractDetailPopup,
)
PricingContractTable = withDeleteModal(PricingContractTable)
