import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import Icon from 'react-icons-kit'
import { DropdownButton, MenuItem } from 'react-bootstrap'

import { RightBarButtonHolder, createShowToast } from '../detailPopup'
import { recalculateVoucherBookRemainingUsage } from '../../actions/remote_api'
import { refresh } from 'react-icons-kit/fa'

class VoucherBookDetailRightBarButtons extends React.Component {
  handleRecalculateVoucherBookRemainingUsage = () => {
    const { instance, recalculateVoucherBookRemainingUsage } = this.props
    recalculateVoucherBookRemainingUsage(instance._id, {
      onResponse: createShowToast(
        I18n.t('voucherBookPage.detail.recalculateToastMessage.success'),
      ),
    })
  }

  render() {
    return (
      <RightBarButtonHolder>
        <DropdownButton
          bsStyle="default"
          noCaret
          id="dropdown-no-caret"
          title={<span className="glyphicon glyphicon-option-vertical" />}
        >
          <MenuItem onClick={this.handleRecalculateVoucherBookRemainingUsage}>
            <Icon icon={refresh} /> {I18n.t('action.recalculate')}{' '}
            {I18n.t('voucherBookPage.fields.remainingUsageAmount')}
          </MenuItem>
        </DropdownButton>
      </RightBarButtonHolder>
    )
  }
}

export default connect(null, { recalculateVoucherBookRemainingUsage })(
  VoucherBookDetailRightBarButtons,
)
