import { createCRUDActions, createURL } from './remote_api_utils'

const customerActions = createCRUDActions('customer', '/api/customers')

export const fetchCustomers = customerActions.list
export const fetchCustomer = customerActions.get
export const deleteCustomer = customerActions.delete
export const createCustomer = customerActions.create
export const updateCustomer = customerActions.update
export const clearCustomer = customerActions.clear

const staffActions = createCRUDActions('staff', '/api/staffs')

export const fetchStaffs = staffActions.list
export const fetchStaff = staffActions.get
export const deleteStaff = staffActions.delete
export const createStaff = staffActions.create
export const updateStaff = staffActions.update
export const clearStaff = staffActions.clear

const stationActions = createCRUDActions('station', '/api/stations')

export const fetchStations = stationActions.list
export const fetchStation = stationActions.get
export const deleteStation = stationActions.delete
export const createStation = stationActions.create
export const updateStation = stationActions.update
export const clearStation = stationActions.clear

const userActions = createCRUDActions('user', '/api/users')

export const fetchUsers = userActions.list
export const fetchUser = userActions.get
export const deleteUser = userActions.delete
export const createUser = userActions.create
export const updateUser = userActions.update
export const clearUser = userActions.clear

const cardActions = createCRUDActions('card', '/api/cards')

export const fetchCards = cardActions.list
export const fetchCard = cardActions.get
export const deleteCard = cardActions.delete
export const createCard = cardActions.create
export const updateCard = cardActions.update
export const clearCard = cardActions.clear

const airlineActions = createCRUDActions('airline', '/api/airlines')

export const fetchAirlines = airlineActions.list
export const fetchAirline = airlineActions.get
export const deleteAirline = airlineActions.delete
export const createAirline = airlineActions.create
export const updateAirline = airlineActions.update
export const clearAirline = airlineActions.clear

const agentActions = createCRUDActions('agent', '/api/agents')

export const fetchAgents = agentActions.list
export const fetchAgent = agentActions.get
export const deleteAgent = agentActions.delete
export const createAgent = agentActions.create
export const updateAgent = agentActions.update
export const clearAgent = agentActions.clear

const productActions = createCRUDActions('product', '/api/products')

export const fetchProducts = productActions.list
export const fetchProduct = productActions.get
export const deleteProduct = productActions.delete
export const createProduct = productActions.create
export const updateProduct = productActions.update
export const clearProduct = productActions.clear

const serviceTxnActions = createCRUDActions('serviceTxn', '/api/serviceTxns')

export const fetchServiceTxns = serviceTxnActions.list
export const fetchServiceTxn = serviceTxnActions.get
export const deleteServiceTxn = serviceTxnActions.delete
export const createServiceTxn = serviceTxnActions.create
export const updateServiceTxn = serviceTxnActions.update
export const clearServiceTxn = serviceTxnActions.clear

export const fetchServiceTxnsCount = (query) => ({
  type: `FETCH_SERVICE_TXNS_COUNT_SINGLE`,
  meta: { fetch_id: 'serviceTxnsCount', type: 'single' },
  $payload: {
    url: createURL('/api/serviceTxns/count', query),
  },
})

export const clearServiceTxnsCount = (type = 'single') => ({
  type: 'CLEAR_FETCH_DATA',
  meta: { fetch_id: 'serviceTxnsCount', type },
})

const serviceTxnFormActions = createCRUDActions(
  'serviceTxnForm',
  '/api/serviceTxns',
)

export const fetchServiceTxnForms = serviceTxnFormActions.list
export const fetchServiceTxnForm = serviceTxnFormActions.get
export const deleteServiceTxnForm = serviceTxnFormActions.delete
export const createServiceTxnForm = serviceTxnFormActions.create
export const updateServiceTxnForm = serviceTxnFormActions.update
export const clearServiceTxnForm = serviceTxnFormActions.clear

const serviceMappingActions = createCRUDActions(
  'serviceMapping',
  '/api/serviceMappings',
)

export const fetchServiceMappings = serviceMappingActions.list

const serviceActions = createCRUDActions('service', '/api/services')

export const fetchServices = serviceActions.list
export const clearService = serviceActions.clear

const privilegeClassActions = createCRUDActions(
  'privilegeClass',
  '/api/privilegeClasses',
)

export const fetchPrivilegeClasses = privilegeClassActions.list

const pricingContractActions = createCRUDActions(
  'pricingContract',
  '/api/pricingContracts',
)

export const fetchPricingContracts = pricingContractActions.list
export const fetchPricingContract = pricingContractActions.get
export const deletePricingContract = pricingContractActions.delete
export const createPricingContract = pricingContractActions.create
export const updatePricingContract = pricingContractActions.update
export const clearPricingContract = pricingContractActions.clear

const pricingContractTemplateActions = createCRUDActions(
  'pricingContractTemplate',
  '/api/pricingContracts',
)

export const fetchPricingContractTemplates = (query, options) =>
  pricingContractTemplateActions.list(
    {
      isTemplate: true,
      ...query,
    },
    options,
  )

const voucherBookActions = createCRUDActions('voucherBook', '/api/voucherBooks')

export const fetchVoucherBooks = voucherBookActions.list
export const fetchVoucherBook = voucherBookActions.get
export const deleteVoucherBook = voucherBookActions.delete
export const createVoucherBook = voucherBookActions.create
export const updateVoucherBook = voucherBookActions.update
export const clearVoucherBook = voucherBookActions.clear
export const recalculateVoucherBookRemainingUsage = (
  id,
  { fetchId, onResponse },
) => ({
  type: 'RECALCULATE_VOUCHERBOOK_REMAINING_USAGE',
  meta: {
    fetch_id: fetchId || 'voucherBook',
    type: 'update',
  },
  $payload: {
    url: `/api/voucherBooks/${id}/recalculateRemainingAmount`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    },
    onResponse,
  },
})

const debtorActions = createCRUDActions('debtor', '/api/debtors')

export const fetchDebtors = debtorActions.list
export const fetchDebtor = debtorActions.get
export const deleteDebtor = debtorActions.delete
export const createDebtor = debtorActions.create
export const updateDebtor = debtorActions.update
export const clearDebtor = debtorActions.clear

const quotationActions = createCRUDActions('quotation', '/api/quotations')

export const fetchQuotations = quotationActions.list
export const fetchQuotation = quotationActions.get
export const deleteQuotation = quotationActions.delete
export const createQuotation = quotationActions.create
export const updateQuotation = quotationActions.update
export const clearQuotation = quotationActions.clear

const invoiceIntervalActions = createCRUDActions(
  'invoiceInterval',
  '/api/invoiceIntervals',
)

export const fetchInvoiceIntervals = invoiceIntervalActions.list
export const fetchInvoiceInterval = invoiceIntervalActions.get
export const deleteInvoiceInterval = invoiceIntervalActions.delete
export const createInvoiceInterval = invoiceIntervalActions.create
export const updateInvoiceInterval = invoiceIntervalActions.update
export const clearInvoiceInterval = invoiceIntervalActions.clear
export const processInvoiceInterval = (id, { onResponse } = {}) => ({
  type: 'PROCESS_INVOICE_INTERVAL',
  meta: {
    fetch_id: 'invoiceInterval',
    type: 'update',
  },
  $payload: {
    url: createURL(`/api/invoiceIntervals/process/${id}`),
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    },
    onResponse,
  },
})
export const cancelInvoiceIntervalProcess = (id, { onResponse } = {}) => ({
  type: 'CANCEL_PROCESS_INVOICE_INTERVAL',
  meta: {
    fetch_id: 'invoiceInterval',
    type: 'update',
  },
  $payload: {
    url: createURL(`/api/invoiceIntervals/process/${id}/cancel`),
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    },
    onResponse,
  },
})
export const restartInvoiceIntervalProcess = (id, { onResponse } = {}) => ({
  type: 'RESTART_PROCESS_INVOICE_INTERVAL',
  meta: {
    fetch_id: 'invoiceInterval',
    type: 'update',
  },
  $payload: {
    url: createURL(`/api/invoiceIntervals/process/${id}/restart`),
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    },
    onResponse,
  },
})

export const fetchProcessingInvoiceInterval = () => ({
  type: 'FETCH_PROCESSING_INVOICE_INTERVAL',
  meta: {
    fetch_id: 'processingInvoiceInterval',
    type: 'single',
  },
  $payload: { url: createURL(`/api/invoiceIntervals/processing`) },
})

const invoiceActions = createCRUDActions('invoice', '/api/invoices')

export const fetchInvoices = invoiceActions.list
export const fetchInvoice = invoiceActions.get
export const deleteInvoice = invoiceActions.delete
export const createInvoice = invoiceActions.create
export const updateInvoice = invoiceActions.update
export const clearInvoice = invoiceActions.clear
export const cancelInvoice = (
  invoice,
  { onResponse, resultTransform, fetchId },
) => ({
  type: `CANCEL_INVOICE`,
  meta: {
    fetch_id: fetchId || 'invoice',
    type: 'update',
    _id: invoice._id,
    resultTransform,
  },
  $payload: {
    url: `/api/invoices/${invoice._id}/cancel`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
    },
    onResponse,
  },
})

export const fetchReservable = (params, { resultTransform, fetchId } = {}) => ({
  type: `FETCH_RESERABLE_SINGLE`,
  meta: {
    fetch_id: fetchId || 'reservable',
    type: 'single',
    resultTransform,
  },
  $payload: {
    url: createURL('/api/reservations/reservable', params),
  },
})

export const clearReservable = (type = 'single', { fetchId } = {}) => ({
  type: `CLEAR_FETCH_DATA`,
  meta: {
    fetch_id: fetchId || 'reservable',
    type,
  },
})

const topupActions = createCRUDActions('topup', '/api/topups')

export const fetchTopups = topupActions.list
export const fetchTopup = topupActions.get
export const deleteTopup = topupActions.delete
export const createTopup = topupActions.create
export const updateTopup = topupActions.update
export const clearTopup = topupActions.clear

const agentCashTxnActions = createCRUDActions(
  'agentCashTxn',
  '/api/agentCashTxns',
)

export const fetchAgentCashTxns = agentCashTxnActions.list
export const fetchAgentCashTxn = agentCashTxnActions.get
export const deleteAgentCashTxn = agentCashTxnActions.delete
export const createAgentCashTxn = agentCashTxnActions.create
export const updateAgentCashTxn = agentCashTxnActions.update
export const clearAgentCashTxn = agentCashTxnActions.clear
