import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter, Switch } from 'react-router-dom'
import styled from 'styled-components'
import SettingsSideMenu from './SettingsSideMenu'
import SettingsDropOffArea from './SettingsDropOffArea'
import SettingsContentEmptyState from './SettingsContentEmptyState'
import { Panel } from 'react-bootstrap'
import { findAccessLevel } from '../../modules/accessRoute'

class SettingsPage extends React.Component {
  render() {
    return (
      <div>
        <h1 className="page-header">Setting Options</h1>
        <hr />
        <Panel>
          <SettingsLayout>
            <SettingsSideMenu />

            <SettingsContent>
              <Panel>
                <Switch>
                  <PrivateRoute
                    path="/settings/dropOffArea"
                    component={SettingsDropOffArea}
                    ifAnyGranted={findAccessLevel('/settings')}
                  />
                  <PrivateRoute
                    path="/settings"
                    component={SettingsContentEmptyState}
                    ifAnyGranted={findAccessLevel('/settings')}
                  />
                </Switch>
              </Panel>
            </SettingsContent>
          </SettingsLayout>
        </Panel>
      </div>
    )
  }
}

export default withRouter(SettingsPage)

const SettingsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 505px;
`

const SettingsContent = styled.div`
  flex: 5;
  min-width: 500px;
`
