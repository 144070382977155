import React from 'react'
import { ControlLabel } from 'react-bootstrap'
import { payerType } from '../../services/payerType'
import { I18n } from 'react-redux-i18n'
import { agentTypes } from '../../services/agentType'

const ReservableInfo = ({ info, reservation }) => {
  if (!info) return null

  const { nextUsageAmount, remainingAmount, prevUsageAmount } = info
  const currentRemainingAmount = remainingAmount + prevUsageAmount

  switch (reservation.payerType) {
    case payerType.voucher:
      return (
        <div>
          <div>
            <ControlLabel>
              {I18n.t('reservationPage.voucherBookPayer.infoLabel')}
            </ControlLabel>
          </div>
          <span>
            {currentRemainingAmount}/
            {reservation.payerVoucherBook.totalUsageAmount}
          </span>
        </div>
      )
    case payerType.agent:
      if (reservation.agent.type === agentTypes.postpaid) return null
      return (
        <div>
          <div>
            <ControlLabel>
              {I18n.t('reservationPage.agentPayer.infoLabel')}
            </ControlLabel>
          </div>
          <span>
            {nextUsageAmount}/{currentRemainingAmount}
          </span>
        </div>
      )
    default:
      return null
  }
}

export default ReservableInfo
