import React from 'react'
import Help1 from '../../img/report/help-1.png'
import Help2 from '../../img/report/help-2.png'
import Help3 from '../../img/report/help-3.png'
import Help4 from '../../img/report/help-4.png'
import Help5 from '../../img/report/help-5.png'
import Help6 from '../../img/report/help-6.png'
import Help7 from '../../img/report/help-7.png'

export default class WebReportHelp extends React.Component {
  render() {
    return (
      <div className="font-sans mx-auto container flex justify-end px-6 py-2">
        <div>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Help
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              ขั้นตอนการ Save as PDF
            </p>
          </div>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  กด Save as PDF
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div>
                    <img src={Help1} className="h-96" alt="help 1" />
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  กดเลือกบันทึกเป็น PDF
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div>
                    <img src={Help2} className="h-96" alt="help 2" />
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  ให้มีเครื่องหมายเช็คถูกหน้า Save as PDF
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div>
                    <img src={Help3} className="h-96" alt="help 3" />
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  กดที่ ตั้งค่าอื่นๆ หรือ More settings
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div>
                    <img src={Help4} className="h-96" alt="help 4" />
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  กดหน้ากระดาษเป็น A4 กรอบมาตรฐาน และเอาหัวและท้ายกระดาษออก
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div>
                    <img src={Help5} className="h-96" alt="help 5" />
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  กดบันทึก หรือ Save
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div>
                    <img src={Help6} className="h-96" alt="help 6" />
                  </div>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  จะได้ไฟล์ PDF เซฟลงในเครื่อง
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div>
                    <img src={Help7} className="h-48" alt="help 7" />
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    )
  }
}
