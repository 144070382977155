import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { I18n } from 'react-redux-i18n'
import { Button } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { money, wrench, userCircle } from 'react-icons-kit/fa'
import { iosPrinter } from 'react-icons-kit/ionicons'
import _ from 'lodash'

import { withDetailPopupProvider } from '../detailPopup'
import { withTableConfigs, withDeleteModal } from '../table'
import InvoiceDetailPopup from './InvoiceDetailPopup'
import { createInvoiceTableFilterPanel } from './InvoiceTableFilterPanel'
import { priceFormat } from '../../services/currency'
import {
  isPayable,
  isPrintable,
  isManual,
  isPaidOverDue,
} from '../../services/invoice'
import { PrintInvoiceReportModal } from './PrintInvoiceReportModal'
import styled from 'styled-components'
import MarkManualInvoiceAction from './manualInvoice/MarkManualInvoiceAction'
import MarkManualInvoiceModal from './manualInvoice/MarkManualInvoiceModal'
import UnmarkManualInvoiceAction from './manualInvoice/UnmarkManualInvoiceAction'
import UnmarkManualInvoiceModal from './manualInvoice/UnmarkManualInvoiceModal'
import TableLoading from '../table/TableLoading'
import Loader from '../Loader'

const trClassNameCreator = (invoice) => {
  const { billingDate, dueDayCount } = invoice
  if (_.some([billingDate, dueDayCount], _.isEmpty)) {
    return undefined
  }
  return isPaidOverDue(billingDate, dueDayCount, invoice) ? 'danger' : undefined
}

let InvoiceTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
  createOnProcess,
  headerI18nKey,
  createHandlePay,
  isLoading,
}) => {
  const { fields, table } = I18n.t('invoicePage')
  const trClassName = trClassNameCreator

  const header = headerI18nKey ? I18n.t(headerI18nKey) : undefined

  return (
    <div>
      <h1 className="page-header">{header || table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <Loader loading={<TableLoading />} isLoading={isLoading}>
        <BootstrapTable
          {...defaultSettings}
          options={defaultOptions}
          trClassName={trClassName}
          insertRow={false}
          deleteRow={false}
          selectRow={undefined}
        >
          <TableHeaderColumn
            width="15%"
            dataField="code"
            dataSort
            dataFormat={createInvoiceCodeDetailLink(detailLink)}
            dataAlign="center"
          >
            {fields.code}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="15%"
            dataField="debtorName"
            dataSort
            dataFormat={debtorFormat}
          >
            {fields.debtorName}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="subTotal"
            dataFormat={priceFormat}
            dataSort
            dataAlign="right"
          >
            {fields.subTotal}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="tax"
            dataFormat={priceFormat}
            dataSort
            dataAlign="right"
          >
            {fields.tax}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="grandTotal"
            dataFormat={priceFormat}
            dataSort
            dataAlign="right"
          >
            {fields.grandTotal}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="100px"
            dataField="status"
            dataFormat={(status) => status.toUpperCase()}
            dataAlign="center"
          >
            {fields.status}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            editable={false}
            dataFormat={actionButtons(editButton, createHandlePay)}
            width="125px"
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Loader>
    </div>
  )
}

const debtorFormat = (_, invoice) => <div>{invoice.debtor.name}</div>

function createInvoiceCodeDetailLink(detailLink) {
  return (code, invoice) => (
    <Marker isMarked={!invoice.isCreatedBySystem}>
      {(marker) => {
        if (code)
          return detailLink(
            <span>
              {code} {marker}
            </span>,
            invoice,
          )
        return (
          <NoCodeLink>
            {detailLink(<span>NO CODE {marker}</span>, invoice)}
          </NoCodeLink>
        )
      }}
    </Marker>
  )
}

const Marker = ({ children, isMarked }) =>
  children(isMarked ? <Icon icon={userCircle} /> : null)

const NoCodeLink = styled.div`
  background-color: #8b5c2d;
  > a {
    color: #fff;
  }
`

const actionButtons = (editButton, createHandlePay) => (_, invoice) => (
  <div className="btn-group-vertical">
    {editButton(_, invoice)}
    {isPayable(invoice) && (
      <Button bsStyle="success" onClick={createHandlePay(invoice._id)}>
        <Icon icon={money} /> {I18n.t('action.pay')}
      </Button>
    )}
    {isPrintable(invoice) && (
      <PrintInvoiceReportModal invoiceId={invoice._id}>
        {({ showModal, modal }) => (
          <Button bsStyle="default" onClick={showModal}>
            <Icon icon={iosPrinter} /> {I18n.t('action.print')}
            {modal}
          </Button>
        )}
      </PrintInvoiceReportModal>
    )}
    {!isManual(invoice) && (
      <MarkManualInvoiceAction invoice={invoice}>
        {(markManual) => (
          <MarkManualInvoiceModal onConfirm={(onHide) => markManual(onHide)}>
            {({ onShow, modal }) => (
              <Button bsStyle="warning" onClick={onShow}>
                <Icon icon={wrench} />{' '}
                {I18n.t('invoicePage.manualInvoice.markManual')}
                {modal}
              </Button>
            )}
          </MarkManualInvoiceModal>
        )}
      </MarkManualInvoiceAction>
    )}
    {isManual(invoice) && (
      <UnmarkManualInvoiceAction invoice={invoice}>
        {(unmarkManual) => (
          <UnmarkManualInvoiceModal
            onConfirm={(onHide) => unmarkManual(onHide)}
          >
            {({ onShow, modal }) => (
              <Button bsStyle="warning" onClick={onShow}>
                <Icon icon={wrench} />{' '}
                {I18n.t('invoicePage.manualInvoice.unmarkManual')}
                {modal}
              </Button>
            )}
          </UnmarkManualInvoiceModal>
        )}
      </UnmarkManualInvoiceAction>
    )}
  </div>
)

const createInvoiceTable = (filterName, { hasFilterByStatus }) => {
  let Table = withTableConfigs(filterName)(
    InvoiceTable,
    createInvoiceTableFilterPanel({ hasFilterByStatus }),
  )
  Table = withDetailPopupProvider(Table, InvoiceDetailPopup)
  Table = withDeleteModal(Table)

  return Table
}

export const AllInvoiceTable = createInvoiceTable('invoice', {
  hasFilterByStatus: true,
})
export const DraftInvoiceTable = createInvoiceTable('draftInvoice', {
  hasFilterByStatus: false,
})
export const PendingInvoiceTable = createInvoiceTable('pendingInvoice', {
  hasFilterByStatus: false,
})
