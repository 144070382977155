import React from 'react'
import { FieldLabel } from '../form/index'
import AirlinePrivilegeClassSearchSelect from './AirlinePrivilegeClassSearchSelect'

const AirlinePrivilegeClassSearchSelectField = (props) => (
  <FieldLabel
    {...props}
    format={null}
    component={AirlinePrivilegeClassSearchSelect}
  />
)

export default AirlinePrivilegeClassSearchSelectField
