import React from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import SteppedPricingProductTableDetail from './SteppedPricingProductTableDetail'
import FixedPricingProductInput from './FixedPricingProductInput'
import { pricingProductType } from '../../../services/pricingProduct'
import {
  updatePricingProduct,
  removeTrailingStepPricingProduct,
  initialSteppedPricingProduct,
} from '../../../actions/pricingContractAction'

class PricingProductInput extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.pricingProduct &&
      nextProps.pricingProduct.type === pricingProductType.stepped &&
      isEmpty(nextProps.pricingProduct.steppedPriceInfo.steps)
    ) {
      this.props.initialSteppedPricingProduct(this.props.index)
      this.props.updatePricingProduct(this.props.index)
    }
  }

  handleSteppedPricingProductStepChange = (stepIndex) => (path, value) => {
    const { index } = this.props

    if (path === 'limit') {
      this.props.updatePricingProduct(index)
    }
  }

  handleSteppedPricingProductStepBlur = (stepIndex) => (path, value) => {
    this.props.removeTrailingStepPricingProduct(this.props.index)
  }

  render() {
    const { pricingProduct, index, formSyncErrors } = this.props
    switch (pricingProduct.type) {
      case pricingProductType.fixed:
        return (
          <FixedPricingProductInput
            formKeyPath={`prices.${index}.fixedPriceInfo`}
          />
        )
      case pricingProductType.stepped:
        return (
          <SteppedPricingProductTableDetail
            info={pricingProduct.steppedPriceInfo}
            pricingProduct={pricingProduct}
            onChange={this.handleSteppedPricingProductStepChange}
            onBlur={this.handleSteppedPricingProductStepBlur}
            formKeyPath={`prices.${index}.steppedPriceInfo`}
            formSyncErrors={formSyncErrors}
          />
        )

      default:
        throw Error(
          'pricingProduct ' + pricingProduct.type + ' is not supported',
        )
    }
  }
}

export default connect(null, {
  updatePricingProduct,
  removeTrailingStepPricingProduct,
  initialSteppedPricingProduct,
})(PricingProductInput)
