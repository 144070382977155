import React from 'react'
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Button,
  Alert,
  Tooltip,
  Overlay,
  Col,
  Row,
} from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { checkmark, alert } from 'react-icons-kit/ionicons'
import { Field, FieldArray } from 'redux-form'
import { I18n } from 'react-redux-i18n'
import Select from 'react-select'
import DateTime from 'react-datetime'
import Switch from 'react-bootstrap-switch'
import styled from 'styled-components'
import { isNil } from 'lodash'
import Option from './Option'

const makeValidationState = (error, touched, pristine, asyncValidating) =>
  !touched && pristine ? null : error || asyncValidating ? 'error' : 'success'

const makeLabelValidationStateClassName = (validationState) =>
  validationState
    ? `text-${transformValidationStateToClassName(validationState)}`
    : null

const transformValidationStateToClassName = (validationState) =>
  validationState === 'danger' ? 'error' : validationState

const renderLabel = (label, i18nLabel, addStar) => {
  const star = addStar ? ' *' : ''
  if (label) return `${label}${star}`
  else if (i18nLabel !== undefined) return `${I18n.t(i18nLabel)}${star}`
  return null
}

const ValidationIcon = ({ validationState }) =>
  validationState ? (
    <Icon icon={validationState === 'error' ? alert : checkmark} size={12} />
  ) : null

const noMarginStyle = { margin: 0 }

export const FieldGroup = ({
  id,
  label,
  i18nLabel,
  inputField,
  validationState,
  error,
  help,
  addStar,
  isFormGroupValidate = false,
  isNoMargin,
  ...props
}) => {
  return (
    <FormGroup
      style={isNoMargin ? noMarginStyle : undefined}
      controlId={id}
      validationState={isFormGroupValidate ? validationState : undefined}
    >
      <FormLabel
        label={label}
        i18nLabel={i18nLabel}
        validationState={validationState}
        error={error}
        addStar={addStar}
      />
      <InputFieldContainer {...props} inputField={inputField} />

      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  )
}

class InputFieldContainer extends React.Component {
  handleChange = (e) => {
    if (this.props.willChange) this.props.willChange(e)

    this.props.onChange(e)

    if (this.props.didChange) this.props.didChange(e)
  }

  handleBlur = (e) => {
    if (this.props.willBlur) this.props.willBlur(e)

    this.props.onBlur(e)

    if (this.props.didBlur) this.props.didBlur(e)
  }

  render() {
    const {
      inputField: InputField,
      willChange,
      didChange,
      willBlur,
      didBlur,
      ...rest
    } = this.props
    return (
      <InputField
        {...rest}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    )
  }
}

class FormLabel extends React.Component {
  state = {
    isMouseEntered: false,
    target: () => this.target || undefined,
  }

  onMouseEnter = () => this.setState({ isMouseEntered: true })
  onMouseLeave = () => this.setState({ isMouseEntered: false })

  componentWillReceiveRef = (ref) => {
    if (ref) this.target = ref
  }

  render() {
    const { label, i18nLabel, validationState, error, addStar } = this.props
    const hasError = error !== undefined
    const { isMouseEntered, target } = this.state
    const labelComponent = renderLabel(label, i18nLabel, addStar)
    if (!labelComponent) return null
    return (
      <div>
        <ControlLabel
          className={makeLabelValidationStateClassName(validationState)}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          ref={this.componentWillReceiveRef}
        >
          {labelComponent} <ValidationIcon validationState={validationState} />
        </ControlLabel>

        <Overlay
          show={isMouseEntered && hasError}
          container={this}
          target={target}
          placement="top"
        >
          <Tooltip id="tooltip">{error}</Tooltip>
        </Overlay>
      </div>
    )
  }
}

export const renderFieldGroup = ({
  input,
  meta: { touched, error, pristine, asyncValidating },
  inputField,
  isValidate = true,
  ...props
}) => (
  <FieldGroup
    inputField={inputField || FormControl}
    validationState={
      isValidate
        ? makeValidationState(error, touched, pristine, asyncValidating)
        : null
    }
    error={error}
    {...input}
    {...props}
  />
)

// MARK: Support Fields

export const FieldLabel = ({ component, ...props }) => (
  <Field {...props} component={renderFieldGroup} inputField={component} />
)

export const TextField = (props) => (
  <Field {...props} component={renderFieldGroup} />
)

export const TextAreaField = (props) => (
  <Field
    {...props}
    component={renderFieldGroup}
    inputField={FormControlTextArea}
  />
)

export const SwitchField = (props) => (
  <Field {...props} component={renderFieldGroup} inputField={SwitchContainer} />
)

export const DateTimeField = (props) => (
  <Field {...props} component={renderFieldGroup} inputField={DateTime} />
)

export const OptionFieldGroup = (props) => (
  <Field {...props} component={renderFieldGroup} inputField={Option} />
)

export const SelectField = (props) => (
  <Field
    {...props}
    component={renderFieldGroup}
    inputField={SelectInputField}
  />
)

export const SelectTextArea = (props) => (
  <Field
    {...props}
    component={renderFieldGroup}
    inputField={SelectTextAreaField}
  />
)

export const MultiSelectField = (props) => (
  <Field
    {...props}
    component={renderFieldGroup}
    inputField={MultiSelectInputField}
  />
)

export const SelectAsyncField = (props) => (
  <Field
    {...props}
    component={renderFieldGroup}
    inputField={SelectAsyncInputField}
  />
)

export const ListField = (props) => (
  <FieldArray
    {...props}
    component={ListFieldGroup}
    inputFieldItem={props.inputFieldItem}
  />
)

export { default as ImageUploadField } from './ImageUploadField'

// MARK: - Components

const FormControlTextArea = (props) => (
  <FormControl componentClass="textarea" {...props} />
)

export const SwitchContainer = ({
  value,
  onChange,
  label,
  initialValue,
  isLocked,
  disabled,
}) => (
  <div>
    <Switch
      value={value}
      onChange={(elm, state) => onChange(state)}
      state={initialValue}
      labelText={renderLabel(label)}
      disabled={disabled || isLocked}
    />
  </div>
)

const SelectInputField = ({
  value,
  onChange,
  onBlur,
  options,
  simpleValue = true,
  onInputChange,
  onBlurResetsInput,
  disabled = false,
  placeholder,
  filterOption,
  className,
}) => {
  return (
    <Select
      className={className}
      value={value}
      simpleValue={simpleValue}
      onChange={(optionOrValue) =>
        onChange(selectValue(optionOrValue, simpleValue))
      }
      onBlur={() => onBlur(selectValue(value, simpleValue))}
      onInputChange={onInputChange}
      onBlurResetsInput={onBlurResetsInput}
      options={options}
      placeholder={placeholder}
      openOnFocus
      filterOption={filterOption}
      disabled={disabled}
    />
  )
}

const SelectTextAreaField = styled(SelectInputField)`
  .Select-control {
    height: 80px !important;

    .Select-clear-zone {
      vertical-align: top;
      line-height: 34px;
    }

    .Select-arrow-zone {
      padding-top: 7.5px;
      vertical-align: top;
    }
    .Select-value-label {
      white-space: normal;
    }
    .Select-value {
      overflow-y: auto;
      line-height: 24px;
      padding-top: 5px;
    }
  }
`

const MultiSelectInputField = ({
  value,
  onChange,
  onBlur,
  stayOpen = true,
  rlt = false,
  options,
  simpleValue = true,
  onInputChange,
  onBlurResetsInput,
  disabled = false,
}) => {
  return (
    <Select
      value={value}
      closeOnSelect={!stayOpen}
      simpleValue={simpleValue}
      onChange={(optionOrValue) =>
        onChange(multiSelectValue(optionOrValue, simpleValue))
      }
      onBlur={() => onBlur(multiSelectValue(value, simpleValue))}
      onInputChange={onInputChange}
      onBlurResetsInput={onBlurResetsInput}
      options={options}
      openOnFocus
      multi
      rtl={rlt}
      disabled={disabled}
    />
  )
}

const SelectAsyncInputField = ({
  value,
  onChange,
  onBlur,
  loadOptions,
  simpleValue = true,
  onInputChange,
  onBlurResetsInput,
  disabled = false,
}) => {
  return (
    <Select.Async
      value={value}
      simpleValue={simpleValue}
      onChange={(optionOrValue) =>
        onChange(selectValue(optionOrValue, simpleValue))
      }
      onBlur={() => onBlur(selectValue(value, simpleValue))}
      onInputChange={onInputChange}
      onBlurResetsInput={onBlurResetsInput}
      loadOptions={loadOptions}
      openOnFocus
      disabled={disabled}
    />
  )
}

const selectValue = (optionOrValue, isSimpleValue) => {
  if (isSimpleValue) return optionOrValue || null // it's is value already
  return optionOrValue ? optionOrValue.value : null
}

const multiSelectValue = (optionOrValue, isSimpleValue) => {
  var values = optionOrValue
  if (optionOrValue.includes(',')) values = optionOrValue.split(',')
  if (isSimpleValue) return values || null // it's is value already
  return values ? values : null
}

export const createSelectValue = (displayValue) => (props) => (
  <div className="Select-value">
    <span className="Select-value-label">{displayValue(props)}</span>
  </div>
)

export const createSelectOption = (display) => (props) => (
  <SelectOption {...props} displayComponent={display} />
)

class SelectOption extends React.Component {
  handleMouseDown = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onSelect(this.props.option, event)
  }

  handleMouseEnter = (event) => {
    this.props.onFocus(this.props.option, event)
  }

  handleMouseMove = (event) => {
    if (this.props.isFocused) return
    this.props.onFocus(this.props.option, event)
  }

  render() {
    const { displayComponent: Display, option } = this.props
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
      >
        <Display option={option} />
      </div>
    )
  }
}

const ListFieldGroup = ({ label, disabled = false, ...props }) => {
  const isMutable = disabled === false && props.makeNewItem
  const noDataSection = (
    <Alert bsStyle="warning" style={{ paddingLeft: '15px', marginTop: 10 }}>
      <strong>No Data to Display.</strong> You could add item using ADD button
      on top right.
    </Alert>
  )
  return (
    <div>
      {isMutable && (
        <div style={{ height: '33px' }}>
          <div className="pull-left">
            <strong>{label}</strong>
          </div>
          <div className="pull-right">
            {disabled === false && props.makeNewItem && (
              <Button
                onClick={makeAddItemHandler(props.fields, props.makeNewItem)}
              >
                <span className="glyphicon glyphicon-plus" />{' '}
                {I18n.t('action.add').toUpperCase()}
              </Button>
            )}
          </div>
        </div>
      )}

      {props.fields.length > 0 ? (
        <div style={{ paddingLeft: '15px' }}>
          <ListFieldItems {...props} disabled={disabled} />
        </div>
      ) : isMutable ? (
        noDataSection
      ) : null}
    </div>
  )
}

const ListFieldItems = ({
  fields,
  inputFieldItem: InputFieldItem,
  ...rest
}) => {
  return (
    <div>
      {fields.map((path, index, fields) => (
        <InputFieldItem
          {...rest}
          key={index}
          path={path}
          index={index}
          onRemove={makeRemoveItemHandler(fields, index)}
        />
      ))}
    </div>
  )
}

const makeAddItemHandler = (fields, makeNewItem) => () =>
  fields.push(makeNewItem ? makeNewItem() : {})
const makeRemoveItemHandler = (fields, index) => () => fields.remove(index)

// Toolbar

export const Toolbar = ({
  title,
  badgeMessage,
  titleComponent: Title,
  children,
  customTitle,
}) => (
  <Row>
    <Col sm={12} className="page-header">
      <h1>
        {customTitle ? customTitle : null}
        {Title ? <Title title={title} /> : null}
        {title && !Title ? title : null}
        <span className="pull-right">{children}</span>
      </h1>
    </Col>
  </Row>
)

// MARK: - Business Field

export const formMode = (id) => (isNil(id) ? 'new' : 'update')
