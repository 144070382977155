import React from 'react'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { cancelInvoice } from '../../actions/remote_api'
import ConfirmModal from './manualInvoice/confirmModal/ConfirmModal'
import { RightBarButtonHolder, createShowToast } from '../detailPopup'
import { I18n } from 'react-redux-i18n'
import { BtnInBar, IconInBtnInBar } from '../detailPopup/DetailPopup'
import { ban } from 'react-icons-kit/fa'
import { isCancellable } from '../../services/invoice'

const cancelSuccessToast = createShowToast('CANCEL SUCCESS')

const withCancelInvoice = compose(
  connect(null, { cancelInvoice }),
  withHandlers({
    cancelInvoice:
      ({ cancelInvoice, invoice, onRefetchStart }) =>
      (onCancaled) => {
        cancelInvoice(invoice, {
          onResponse: (response) => {
            onRefetchStart()
            onCancaled()
            cancelSuccessToast(response)
          },
        })
      },
  }),
)

const CancelInvoiceAction = withCancelInvoice(({ children, cancelInvoice }) =>
  children(cancelInvoice),
)

const InvoiceDetailPopupRightBarButtons = ({ instance, onRefetchStart }) => (
  <CancelInvoiceAction invoice={instance} onRefetchStart={onRefetchStart}>
    {(cancelInvoice) => (
      <ConfirmModal
        onConfirm={cancelInvoice}
        title={I18n.t('invoicePage.cancelModal.title')}
        subtitle={I18n.t('invoicePage.cancelModal.subtitle')}
      >
        {({ onShow, modal }) => (
          <RightBarButtonHolder>
            {instance && isCancellable(instance.status) && (
              <BtnInBar bsStyle="danger" onClick={onShow}>
                <IconInBtnInBar icon={ban} size={16} />{' '}
                {I18n.t('action.cancel')}
                {modal}
              </BtnInBar>
            )}
          </RightBarButtonHolder>
        )}
      </ConfirmModal>
    )}
  </CancelInvoiceAction>
)

export default InvoiceDetailPopupRightBarButtons
