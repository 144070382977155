import immutable from 'object-path-immutable'
import { compose } from 'recompose'
import { isNil, isEmpty } from 'lodash'
import actionTypes from '../constants/index'

export default (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_STEP_PRICING_PRODUCT: {
      let { priceIndex } = action
      let { steps } = state.values.prices[priceIndex].steppedPriceInfo
      return immutable.set(
        state,
        `values.prices.${priceIndex}.steppedPriceInfo.steps`,
        updateStepPricingProduct(steps),
      )
    }
    case actionTypes.REMOVE_TRAILING_STEP_PRICING_PRODUCT: {
      let { priceIndex } = action
      let { steps } = state.values.prices[priceIndex].steppedPriceInfo
      return immutable.set(
        state,
        `values.prices.${priceIndex}.steppedPriceInfo.steps`,
        removeTrailingSteps(steps),
      )
    }
    case actionTypes.INIT_STEP_PRICING_PRODUCT: {
      let { priceIndex } = action
      return immutable.set(
        state,
        `values.prices.${priceIndex}.steppedPriceInfo.steps`,
        [createEmptyStep(action.initialPrice)],
      )
    }
    default:
      return state
  }
}

const updateStepPricingProduct = (steps) => {
  return compose(updateStepPricingProductStart, addStepIfNeeded)(steps)
}

const updateStepPricingProductStart = (steps, start = 1) => {
  const currentStep = steps[0]
  if (steps.length === 1) return [{ ...currentStep, start }]
  const newSteps = [
    { ...currentStep, start },
    ...updateStepPricingProductStart(
      steps.slice(1, steps.length),
      start + (currentStep.limit || 0),
    ),
  ]
  return newSteps
}

const addStepIfNeeded = (steps) => {
  if (isEmpty(steps)) return [createEmptyStep()]
  if (!isNil(steps[steps.length - 1].limit)) {
    return [...steps, createEmptyStep()]
  }

  return steps
}

const createEmptyStep = (initialPrice) => ({
  limit: undefined,
  start: undefined,
  price: initialPrice,
})

const removeTrailingSteps = (steps, currentIndex = 0) => {
  if (isEmpty(steps) || currentIndex === steps.length - 1) return steps

  if (isNil(steps[currentIndex].limit)) {
    return steps.slice(0, currentIndex + 1)
  }

  return removeTrailingSteps(steps, currentIndex + 1)
}
