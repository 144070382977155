import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { Label } from 'react-bootstrap'
import styledComponents from 'styled-components'
import { get } from 'lodash'

import { fetchReservation } from '../../actions/fetch_reservation'
import { withDetailPopup } from '../detailPopup'
import { dateTimeFormat } from '../table'
import ReservationDetailRightBarButton from './ReservationDetailRightBarButton'
import StepperForReservation from './StepperForReservation'
import { makeStationByServiceTitle } from '../index'

const productFormat = (p) => (p ? p.name : undefined)
const agentFormat = (a) => (a ? `${a.name} (${a.code})` : '-')
const optionalTextFormat = (text) => (text ? text : '-')

const StatusLayout = styledComponents.span`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 10px;
  }
`
const StatusText = ({ status, isRefunded, isAmended }) => {
  return (
    <StatusLayout>
      <strong>{status ? status.toUpperCase() : '-'}</strong>
      {isRefunded && <Label bsStyle="warning">REFUNDED</Label>}
      {isAmended && <Label bsStyle="info">AMENDED</Label>}
    </StatusLayout>
  )
}

const AgentEmails = styledComponents.p`
  word-wrap: break-word;
`

const renderJobAssignments = (jobAssignments) => {
  const { table } = I18n.t('reservationPage')
  const { fields } = I18n.t('reservationPage')

  if (jobAssignments.length > 0)
    return (
      <table className="table">
        <thead>
          <tr>
            <th>{fields.jobAssignmentItems.staff}</th>
            <th>{fields.jobAssignmentItems.service}</th>
            <th>{fields.jobAssignmentItems.issuedServiceDateTime}</th>
          </tr>
        </thead>
        <tbody>
          {jobAssignments.map((j, index) => (
            <tr key={j._id}>
              <td>
                {j.staff && j.staff.firstName} {j.staff && j.staff.lastName}
              </td>
              <td>{j.service.name}</td>
              <td>
                {j.issuedServiceDateTime
                  ? dateTimeFormat(j.issuedServiceDateTime)
                  : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  return <div>{table.emptyJobAssignmentMsg}</div>
}

const ReservationDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('reservationPage')

  return (
    <div className="detailForm">
      <StepperForReservation instance={instance} />

      <form className="form-horizontal">
        <div className="row">
          <div className="col-sm-7">
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.confirmationNumber}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.confirmationNumber
                    ? instance.confirmationNumber
                    : '-'}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">{fields.status}</label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && (
                    <StatusText
                      status={instance.status}
                      isRefunded={instance.isRefunded}
                      isAmended={instance.isAmended}
                    />
                  )}
                </p>
              </div>
            </div>

            {instance && instance.isExtraCharged && (
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {fields.isExtraCharged}
                </label>
                <div className="col-sm-8">
                  <p className="form-control-static">Yes</p>
                </div>
              </div>
            )}

            <div className="form-group">
              <label className="col-sm-4 control-label">{fields.product}</label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && productFormat(instance.product)}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.mainPax.name}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.mainPax.fullName}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.serviceDateTime}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.serviceDateTime
                    ? dateTimeFormat(instance.serviceDateTime)
                    : '-'}
                </p>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.boardingDateTime}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.boardingDateTime
                    ? dateTimeFormat(instance.boardingDateTime)
                    : '-'}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.jobAssignments}
              </label>
              <div className="col-sm-8">
                <div className="form-control-static">
                  {instance && renderJobAssignments(instance.jobAssignments)}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.displayPrice}
              </label>
              <div className="col-sm-8">
                <div className="form-control-static">
                  {instance && instance.displayPrice
                    ? '฿' + instance.displayPrice
                    : '-'}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.targetStationsByService}
              </label>
              <div className="col-sm-8">
                <div className="form-control-static">
                  {instance && instance.targetStationsByService
                    ? makeStationByServiceTitle(
                        instance.targetStationsByService,
                      )
                    : '-'}
                </div>
              </div>
            </div>

            <small>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {fields.createdDateTime}
                </label>
                <div className="col-sm-8">
                  <p className="form-control-static">
                    {instance &&
                      instance.log.createdDateTime &&
                      dateTimeFormat(instance.log.createdDateTime)}
                    {instance && instance.log.createdBy
                      ? ` by ${instance.log.createdBy}`
                      : ''}
                  </p>
                </div>
              </div>

              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {fields.lastUpdatedDateTime}
                </label>
                <div className="col-sm-8">
                  <p className="form-control-static">
                    {instance &&
                      instance.log.lastUpdatedDateTime &&
                      dateTimeFormat(instance.log.lastUpdatedDateTime)}
                    {instance && instance.log.lastUpdatedBy
                      ? ` by ${instance.log.lastUpdatedBy}`
                      : ''}
                  </p>
                </div>
              </div>
            </small>
          </div>

          <div className="col-sm-5">
            <div className="form-group">
              <label className="col-sm-4 control-label">{fields.agent}</label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && agentFormat(instance.agent)}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">{fields.airport}</label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.airport ? instance.airport : '-'}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.flightNumber}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.flightNumber
                    ? instance.flightNumber
                    : '-'}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.phoneNumber}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.phoneNumber
                    ? instance.phoneNumber
                    : '-'}
                </p>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.agentEmails}
              </label>
              <div className="col-sm-8">
                <AgentEmails className="form-control-static">
                  {instance && instance.agentEmails}
                </AgentEmails>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.mainPax.email}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.mainPax.email}
                </p>
              </div>
            </div>

            <div className="col-sm-12 ">
              <div className="form-group col-sm-4">
                <label className="col-sm-4 control-label">
                  {fields.guestPaxAdult}
                </label>
                <div className="col-sm-8">
                  <p className="form-control-static">
                    {instance && instance.guestPaxAdult}
                  </p>
                </div>
              </div>
              <div className="form-group col-sm-4">
                <label className="col-sm-4 control-label">
                  {fields.guestPaxChild}
                </label>
                <div className="col-sm-8">
                  <p className="form-control-static">
                    {instance && instance.guestPaxChild}
                  </p>
                </div>
              </div>
              <div className="form-group col-sm-4">
                <label className="col-sm-4 control-label">
                  {fields.guestPaxInfant}
                </label>
                <div className="col-sm-8">
                  <p className="form-control-static">
                    {instance && instance.guestPaxInfant}
                  </p>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.luggageAmount}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && instance.luggageAmount}
                </p>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.importantNote}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && optionalTextFormat(instance.importantNote)}
                </p>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.privateNote}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && optionalTextFormat(instance.privateNote)}
                </p>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.publicNote}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance && optionalTextFormat(instance.publicNote)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

let ReservationDetailPopupContainer = withDetailPopup(
  ReservationDetailPopup,
  () => ({
    header: I18n.t('reservationPage.detail.header'),
    customClass: 'modal-reservation',
  }),
  ReservationDetailRightBarButton,
)

export default connect(
  (state) => ({
    instance: get(state, 'fetch.reservation.single'),
  }),
  { fetchInstance: fetchReservation },
)(ReservationDetailPopupContainer)
