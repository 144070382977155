import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { toastr } from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n'

import { updatePricingContract } from '../../actions/remote_api'

export const withUpdatePricingContractStatus = compose(
  connect(null, { updatePricingContract }),
  withHandlers({
    updatePricingContractStatus:
      ({ updatePricingContract, status, pricingContract, fetchId }) =>
      () => {
        return updatePricingContract(
          { ...pricingContract, status },
          {
            fetchId,
            onResponse: ({ ok }) => {
              if (ok)
                toastr.success(
                  I18n.t('pricingContractPage.table.updateStatusToast.succeed'),
                )
              else
                toastr.error(
                  I18n.t('pricingContractPage.table.updateStatusToast.failed'),
                )
            },
          },
        )
      },
  }),
)

export const UpdatePricingContractStatus = withUpdatePricingContractStatus(
  ({ children, updatePricingContractStatus }) =>
    children(updatePricingContractStatus),
)
