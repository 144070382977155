import React from 'react'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { get, debounce } from 'lodash'
import { fetchAgents, deleteAgent } from '../../actions/remote_api'
import AgentTable from './AgentTable'
import { escape } from '../../utils/regex'

class AgentTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = (filter) => {
    this.props.fetchAgents({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = (filter) => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.code) queryfilter.code_like = escape(filter.code)
    if (filter.name) queryfilter.name_like = escape(filter.name)
    if (filter.phone1) queryfilter.phone1_like = escape(filter.phone1)
    if (filter.phone2) queryfilter.phone2_like = escape(filter.phone2)
    if (filter.email) queryfilter.email_like = escape(filter.email)
    if (filter.type) queryfilter.type = filter.type
    return queryfilter
  }

  render() {
    return (
      <AgentTable
        {...this.props}
        customDefaultSizePerPage={10}
        onDataChange={this.handleDataChange}
      />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    isLoading: get(fetch, 'agent.isLoading.list', false),
    instances: get(fetch, 'agent.list', []),
    totalCount: get(fetch, 'agent.totalCount'),
  }),
  { fetchAgents, deleteInstance: deleteAgent },
)(AgentTableContainer)
