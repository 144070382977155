import React from 'react'
import CardReport from './BetaCardReport'
import { requestReport } from '../../api'
import moment from 'moment'
import _ from 'lodash'
import { handleReportRequest } from '../../modules/report'
import { rateLimit } from '../../utils/rate-limit'

export default class CardReportContainer extends React.Component {
  state = {
    walkinType: 'all',
    cardId: null,
    stationId: null,
    startDate: moment(),
    endDate: moment(),
    extraPrivilege: null,
    isLoading: false,
  }

  reportName = '/api/reports/queue/cardReportBeta'

  createQuery = (type) => ({
    type,
    walkinType: this.state.walkinType,
    cardId: this.state.cardId,
    stationId: this.state.stationId,
    extraPrivilege: this.state.extraPrivilege,
    startDate: moment(this.state.startDate).toISOString(),
    endDate: moment(this.state.endDate).toISOString(),
  })

  handleFilterChange = (key, value) => {
    this.setState({
      ...this.state,
      [key]: value,
    })
  }

  handleDownloadPDF = async () => {
    rateLimit(
      async () =>
        await handleReportRequest(
          requestReport(this.reportName, this.createQuery('pdf')),
        ),
      3000,
      (isLoading) => {
        this.setState((state) => ({
          ...state,
          isLoading,
        }))
      },
    )
  }

  handleDownloadCSV = async () => {
    rateLimit(
      async () =>
        await handleReportRequest(
          requestReport(this.reportName, this.createQuery('csv')),
        ),
      3000,
      (isLoading) => {
        this.setState((state) => ({
          ...state,
          isLoading,
        }))
      },
    )
  }

  handleViewWebClick = () => {
    window.open(this.downloadHTMLLink(), '_blank')
  }

  downloadHTMLLink = () => {
    const url = new URL('/report/web', window.location.href)
    const query = this.createQuery()
    url.searchParams.set('name', 'cardReportBeta')
    for (const key of Object.keys(query)) {
      if (query[key]) url.searchParams.set(key, query[key])
    }
    return url.href
  }

  isDownloadable = () => {
    if (
      this.state.isLoading ||
      !this.state.startDate ||
      typeof this.state.startDate !== 'object' ||
      !this.state.endDate ||
      typeof this.state.endDate !== 'object'
    )
      return false
    return (
      this.state.startDate
        .startOf('day')
        .isBefore(this.state.endDate.endOf('day')) &&
      (this.state.walkinType === 'card' ? !_.isNil(this.state.cardId) : true)
    )
  }

  render() {
    return (
      <CardReport
        onFilterChange={this.handleFilterChange}
        onDownloadCSV={this.handleDownloadCSV}
        onDownloadPDF={this.handleDownloadPDF}
        onViewWebClick={this.handleViewWebClick}
        isDownloadable={this.isDownloadable()}
        filter={this.state}
      />
    )
  }
}
