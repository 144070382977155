import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
import { withDetailPopupProvider } from '../detailPopup'
import {
  withTableConfigs,
  withDeleteModal,
  makeRenderThumbnail,
} from '../table'
import StaffDetailPopup from './StaffDetailPopup'
import StaffTableFilterPanel from './StaffTableFilterPanel'
import TableLoading from '../table/TableLoading'
import Loader from '../Loader'

let StaffTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
  isLoading,
}) => {
  const { fields, table } = I18n.t('staffPage')
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <Loader loading={<TableLoading />} isLoading={isLoading}>
        <BootstrapTable
          {...defaultSettings}
          trClassName="staff-table-row"
          options={defaultOptions}
        >
          <TableHeaderColumn
            dataField="photo"
            dataFormat={renderThumbnail}
            width="100px"
            dataAlign="center"
          >
            {fields.photo}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="code"
            dataFormat={detailLink}
            dataSort
            width="5%"
          >
            {fields.code}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="firstName" dataSort>
            {fields.firstName}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="lastName" dataSort>
            {fields.lastName}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="gender"
            dataFormat={genderFormat}
            dataSort
            width="8%"
          >
            {fields.gender}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="birthDate"
            dataFormat={ageFormat}
            dataSort
            width="7%"
          >
            {fields.age}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="phone1" dataSort width="10%">
            {fields.phone1}
          </TableHeaderColumn>
          <TableHeaderColumn dataField="email" dataSort>
            {fields.email}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            editable={false}
            dataFormat={editButton}
            width="100px"
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Loader>
    </div>
  )
}

const renderThumbnail = makeRenderThumbnail({
  maxHeight: '75px',
  maxWidth: '75px',
})

const genderFormat = (gender) =>
  gender ? I18n.t(`fields.${gender}`) : undefined
const ageFormat = (birthDate) =>
  birthDate ? moment(birthDate).fromNow(true) : undefined

StaffTable = withTableConfigs('staff')(StaffTable, StaffTableFilterPanel)
StaffTable = withDeleteModal(StaffTable)
StaffTable = withDetailPopupProvider(StaffTable, StaffDetailPopup)

export default StaffTable
