import React from 'react'
import { Panel, Form, Col, Button, FormControl } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import Select from 'react-select'
import _ from 'lodash'
import DebtorSearchSelect from '../debtor/DebtorSearchSelect'
import { invoiceStatus } from '../../services/invoice'

const InvoiceTableFilterPanel = ({
  isShow,
  filter,
  onFilterChange,
  onClearFilter,
  hasFilterByStatus = true,
}) => {
  const fieldsLabel = I18n.t('invoicePage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Col md={2}>
          <FormControl
            value={filter.code}
            onChange={(e) => onFilterChange('code', e.target.value)}
            placeholder={fieldsLabel.code}
          />
        </Col>
        <Col md={4}>
          <DebtorSearchSelect
            value={filter.debtorId}
            onChange={(value) => onFilterChange('debtorId', value)}
            selectValue={(debtor) => debtor._id}
            initialValue={filter.debtorId}
          />
        </Col>
        <Col md={2}>
          {hasFilterByStatus && (
            <Select
              value={filter.status}
              onChange={(value) => onFilterChange('status', value)}
              placeholder={fieldsLabel.status}
              simpleValue
              options={statusOptions}
            />
          )}
          <br />
        </Col>
        <Col md={4}>
          <Button className="pull-right" onClick={onClearFilter}>
            <span className="glyphicon glyphicon-trash" /> Clear Filter
          </Button>
        </Col>
      </Form>
    </Panel>
  )
}

export const createInvoiceTableFilterPanel =
  ({ hasFilterByStatus = true }) =>
  (props) => (
    <InvoiceTableFilterPanel {...props} hasFilterByStatus={hasFilterByStatus} />
  )

const statusOptions = _.map(invoiceStatus, (status) => ({
  label: status.toUpperCase(),
  value: status,
}))
