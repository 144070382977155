import React from 'react'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, get } from 'lodash'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'
import { ButtonGroup } from 'react-bootstrap'
import styledComponents from 'styled-components'

import {
  fetchReservations,
  deleteReservation,
  confirmReservation,
} from '../../actions/fetch_reservation'
import { AllReservationTable } from './ReservationTable'
import { withAlertModal } from '../alertModal'
import { showAlert, hideAlert } from '../../actions/alert'
import { createQueryFilter } from './createQueryFilter'

class ReservationTableContainer extends React.Component {
  selectedReservations = {}

  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isPrimaryTriggered &&
      nextProps.alertMeta &&
      nextProps.alertMeta.reservationsToConfirm
    ) {
      const reservationsToConfirm = nextProps.alertMeta.reservationsToConfirm
      reservationsToConfirm.forEach((r) => {
        this.props.confirmReservation(r.id, { fetchId: 'reservation' })
      })
      this.props.hideAlert()
      toastr.success('Confirm success')
    }
  }

  handleDataChange = (filter) => {
    this.props.fetchReservations({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      createByKiosk: false,
      ...createQueryFilter(filter.query),
    })
  }

  onReservationSelect = (reservation, value) => {
    this.selectedReservations = {
      ...this.selectedReservations,
      [reservation.id]: value ? reservation : undefined,
    }
  }

  onReservationSelectAll = (value) => {
    if (value) {
      this.selectedReservations = this.props.instances.reduce(
        (obj, r) => ({ ...obj, [r.id]: r }),
        {},
      )
    } else {
      this.selectedReservations = {}
    }
  }

  handleConfirmReservation = () => {
    const selectedReservations = this.getSelectedReservations()
    this.showConfirmReservationAlert(selectedReservations)
  }

  showConfirmReservationAlert = (selectedReservations) => {
    const reservationsToConfirm = selectedReservations.reduce(
      (list, r) => (r.status === 'tentative' ? [...list, r] : list),
      [],
    )

    if (reservationsToConfirm.length)
      this.props.showAlert(
        {
          header: 'Confirm Reservations',
          description: `Are you sure to confirm reservation ${
            reservationsToConfirm.length
          }/${selectedReservations.length}`,
          primary: {
            title: 'Confirm',
            bsStyle: 'primary',
          },
          defaultCancelButton: true,
        },
        { reservationsToConfirm },
      )
  }

  getSelectedReservations = () => {
    const reservations = []
    for (const id in this.selectedReservations) {
      const reservation = (this.props.instances || []).find((r) => r._id === id)
      if (reservation) reservations.push(reservation)
    }
    return reservations
  }

  tableSettings = {
    selectRow: undefined,
  }

  render() {
    return (
      <AllReservationTable
        {...this.props}
        onDataChange={this.handleDataChange}
        defaultSortName="log.lastUpdatedDateTime"
        defaultSortOrder="desc"
        onConfirm={this.handleReservationConfirm}
        options={{
          btnGroup: createButtonGroup(
            this.props.path,
            this.handleConfirmReservation,
          ),
        }}
        settings={this.tableSettings}
      />
    )
  }
}

const createButtonGroup = (path, onConfirmReservation) => (props) => {
  return (
    <ButtonGroup>
      <InsertButton to={`${path}/new`}>
        <i className="glyphicon glyphicon-plus" />{' '}
        {I18n.t('action.add').toUpperCase()}
      </InsertButton>
    </ButtonGroup>
  )
}

const InsertButton = styledComponents(Link).attrs({
  className: 'btn btn-info',
})`
`

export default connect(
  ({ fetch }) => ({
    isLoading: get(fetch, 'reservation.isLoading.list', false),
    instances: get(fetch, 'reservation.list', []),
    totalCount: get(fetch, 'reservation.totalCount'),
  }),
  {
    fetchReservations,
    deleteInstance: deleteReservation,
    confirmReservation,
    showAlert,
    hideAlert,
  },
)(withAlertModal(ReservationTableContainer))
