import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Icon from 'react-icons-kit'
import { Link } from 'react-router-dom'

const sidebarWidth = 240
const sidebarMargin = 20

export const Sidebar = ({ isOpen, children }) => (
  <div
    className="sidebar"
    style={{
      width: isOpen ? 65 : sidebarWidth,
      paddingLeft: sidebarMargin,
      paddingRight: isOpen ? sidebarMargin : 0,
    }}
  >
    {children}
  </div>
)

const createPopOver = (title, isOpen) => (
  <Popover
    id="popover-positioned-right"
    style={{ display: isOpen ? 'block' : 'none' }}
  >
    <strong>{title}</strong>
  </Popover>
)

export const SidebarMenu = ({ path, icon, title, isOpen, highlightPath }) => (
  <OverlayTrigger placement="right" overlay={createPopOver(title, isOpen)}>
    <LinkContainer to={highlightPath || path}>
      <li>
        <IconLink to={path}>
          <Icon className="menu-icon" icon={icon} size={24} />{' '}
          <span className={isOpen ? 'menu-text-hide' : 'menu-text'}>
            {title}
          </span>
        </IconLink>
      </li>
    </LinkContainer>
  </OverlayTrigger>
)

export const SidebarMenuItem = ({ path, icon, title, highlightPath }) => (
  <LinkContainer to={highlightPath || path}>
    <li>
      <IconLink to={path}>
        <Icon className="menu-icon" icon={icon} size={24} />{' '}
        <span>{title}</span>
      </IconLink>
    </li>
  </LinkContainer>
)

const IconLink = ({ children, ...props }) => (
  <Link {...props} className="menu-link">
    {children}
  </Link>
)

export const MainPanel = ({ children }) => (
  <div className="main">{children}</div>
)
