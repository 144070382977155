import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { get } from 'lodash'

import { fetchAirline } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { renderClassToServiceMapping, ThumbnailContainer } from '../table'

const thumbnailFormat = (thumbnail) => (
  <div
    style={{
      height: 100,
      width: 100,
      border: '2px solid #72421a',
      position: 'relative',
      borderRadius: 10,
    }}
  >
    <ThumbnailContainer
      thumbnail={thumbnail}
      className="img-responsive"
      alt="thumbnail"
    />
  </div>
)

const AirlineDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('airlinePage')

  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.logo}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && thumbnailFormat(instance.logo)}
          </p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.code}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.code}</p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.name}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.name}</p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">
          {fields.classToServiceMapping}
        </label>
        <ClassToServiceMappingCol>
          {renderClassToServiceMapping(
            instance ? instance.classToServiceMapping : undefined,
          )}
        </ClassToServiceMappingCol>
      </div>
    </form>
  )
}

const ClassToServiceMappingCol = styled.div.attrs({ className: 'col-sm-8' })`
  margin-top: 8px;
`

let AirlineDetailPopupContainer = withDetailPopup(AirlineDetailPopup, () => ({
  header: I18n.t('airlinePage.detail.header'),
}))

export default connect(
  (state) => ({ instance: get(state, 'fetch.airline.single') }),
  { fetchInstance: fetchAirline },
)(AirlineDetailPopupContainer)
