import { isMoment } from 'moment'
import _ from 'lodash'

export const hourOfServiceDateTimeBeforeFlightDateTime = 2

export const createGetServiceDateTime =
  ({ hourOfServiceDateTimeBeforeFlightDateTime = 0 }) =>
  (flightDateTime, flightType, serviceDateTime) => {
    if (_.isEmpty(flightDateTime) || !isMoment(flightDateTime))
      return serviceDateTime

    if (flightType === 'departure')
      return flightDateTime
        .clone()
        .add({ hours: -hourOfServiceDateTimeBeforeFlightDateTime })

    return flightDateTime.clone()
  }
