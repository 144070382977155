import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, get } from 'lodash'

import { fetchVoucherBooks, deleteVoucherBook } from '../../actions/remote_api'
import { withDetailPopupProvider } from '../detailPopup'
import { withTableConfigs, withDeleteModal, dateFormat } from '../table'
import VoucherBookDetailPopup from './VoucherBookDetailPopup'
import VoucherBookTableFilterPanel from './VoucherBookTableFilterPanel'
import { priceFormat } from '../../services/currency'
import { escape } from '../../utils/regex'

class VoucherBookTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = (filter) => {
    this.props.fetchVoucherBooks({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = (filter) => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.name) queryfilter.name_like = escape(filter.name)

    if (filter.status) queryfilter.status = filter.status

    return queryfilter
  }

  render() {
    return (
      <VoucherBookTable {...this.props} onDataChange={this.handleDataChange} />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    instances: get(fetch, 'voucherBook.list', []),
    totalCount: get(fetch, 'voucherBook.totalCount'),
  }),
  { fetchVoucherBooks, deleteInstance: deleteVoucherBook },
)(VoucherBookTableContainer)

let VoucherBookTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
}) => {
  const { fields, table } = I18n.t('voucherBookPage')
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <BootstrapTable {...defaultSettings} options={defaultOptions}>
        <TableHeaderColumn
          width="30%"
          dataField="name"
          dataSort
          dataFormat={detailLink}
        >
          {fields.name}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="price"
          dataFormat={priceFormat}
          dataAlign="right"
        >
          {fields.price}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="remainingUsageAmount"
          dataAlign="right"
        >
          {fields.remainingUsageAmount}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="totalUsageAmount"
          dataAlign="right"
        >
          {fields.totalUsageAmount}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="8%"
          dataField="startDate"
          dataSort
          dataFormat={dateFormat}
        >
          {fields.startDate}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="8%"
          dataField="endDate"
          dataSort
          dataFormat={dateFormat}
        >
          {fields.endDate}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="status"
          dataAlign="center"
          dataFormat={statusFormat}
        >
          {fields.status}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="edit"
          editable={false}
          dataFormat={editButton}
          width="100px"
          dataAlign="center"
          hiddenOnInsert
        />
      </BootstrapTable>
    </div>
  )
}

const statusFormat = (status, row) => {
  return status.toUpperCase()
}

VoucherBookTable = withTableConfigs('voucherBook')(
  VoucherBookTable,
  VoucherBookTableFilterPanel,
)
VoucherBookTable = withDetailPopupProvider(
  VoucherBookTable,
  VoucherBookDetailPopup,
)
VoucherBookTable = withDeleteModal(VoucherBookTable)
