import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, get, isNil } from 'lodash'

import { fetchTopups, deleteTopup } from '../../actions/remote_api'
import { withDetailPopupProvider } from '../detailPopup'
import { withTableConfigs, withDeleteModal, dateFormat } from '../table'
import TopupDetailPopup from './TopupDetailPopup'
import TopupTableFilterPanel from './TopupTableFilterPanel'
import { priceFormat } from '../../services/currency'

class TopupTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = (filter) => {
    this.props.fetchTopups({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = (filter) => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.agentId) queryfilter.agentId = filter.agentId

    if (!isNil(filter.isPaid)) queryfilter.isPaid = filter.isPaid

    return queryfilter
  }

  render() {
    return <TopupTable {...this.props} onDataChange={this.handleDataChange} />
  }
}

export default connect(
  ({ fetch }) => ({
    instances: get(fetch, 'topup.list', []),
    totalCount: get(fetch, 'topup.totalCount'),
  }),
  { fetchTopups, deleteInstance: deleteTopup },
)(TopupTableContainer)

let TopupTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
}) => {
  const { fields, table } = I18n.t('topupPage')
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <BootstrapTable {...defaultSettings} options={defaultOptions}>
        <TableHeaderColumn
          width="30%"
          dataField="agent"
          dataSort
          dataFormat={agentName(detailLink)}
        >
          {fields.agent}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="8%"
          dataField="issuedDate"
          dataSort
          dataFormat={dateFormat}
        >
          {fields.issuedDate}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="price"
          dataFormat={priceFormat}
          dataAlign="right"
        >
          {fields.price}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="isPaid"
          dataAlign="center"
          dataFormat={isPaidFormat}
        >
          {fields.isPaid}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="edit"
          editable={false}
          dataFormat={editButton}
          width="100px"
          dataAlign="center"
          hiddenOnInsert
        />
      </BootstrapTable>
    </div>
  )
}

const agentName =
  (detailLink) =>
  (_, { agent: { name }, ...topup }) =>
    detailLink(name, topup)

const isPaidFormat = (isPaid, row) => {
  return I18n.t('topupPage.fields.isPaidValue.' + isPaid)
}

TopupTable = withTableConfigs('topup')(TopupTable, TopupTableFilterPanel)
TopupTable = withDetailPopupProvider(TopupTable, TopupDetailPopup)
TopupTable = withDeleteModal(TopupTable)
