import React from 'react'
import { connect } from 'react-redux'
import { submit, initialize } from 'redux-form'
import ServiceFormView from './ServiceFormView'
import { Button } from 'react-bootstrap'

const formId = 'serviceForm'

class ServiceFormContainer extends React.Component {
  submit = () => {
    this.props.submit(formId)
  }

  handleSubmit = (formValues) => {
    if (this.props.onSubmit) this.props.onSubmit(formValues)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.serviceTxn !== nextProps.serviceTxn) {
      this.props.initialize(formId, nextProps.serviceTxn)
    }
  }

  isCancel = () =>
    this.props.serviceTxn && this.props.serviceTxn.status === 'canceled'

  render() {
    return (
      <div>
        <div className="panel panel-body">
          <ServiceFormView
            form={formId}
            isCancel={this.isCancel()}
            serviceTxn={this.props.serviceTxn}
            onSubmit={this.handleSubmit}
          />
        </div>
        <div className="panel-footer text-right">
          <Button
            disabled={this.isCancel()}
            className="btn btn-primary "
            onClick={this.submit}
          >
            <span className="glyphicon glyphicon-edit"></span> Submit
          </Button>
        </div>
      </div>
    )
  }
}

export default connect(null, { submit, initialize })(ServiceFormContainer)
