import React from 'react'
import AssignJobForm from './AssignJobForm'
import { connect } from 'react-redux'
import {
  fetchReservation,
  createReservation,
  updateReservation,
  clearReservation,
} from '../../actions/fetch_reservation'
import { submit, stopSubmit, getFormValues } from 'redux-form'
import { isEmpty } from 'lodash'
import { showAlert, hideAlert } from '../../actions/alert'
import { withAlertModal } from '../alertModal'
import { isReservationHasJobAssignmentWarning } from '../../services/jobAssignment'
import { toastr } from 'react-redux-toastr'

const formId = 'jobAssignments'

class AssignJobFormPage extends React.Component {
  state = {
    formData: {},
  }
  markAsCompleted = false

  componentDidMount() {
    const { fetchReservation, id, clearReservation } = this.props

    clearReservation()
    if (id !== undefined) fetchReservation(id)
  }

  componentWillUnmount() {
    this.props.clearReservation()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reservation) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.reservation },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(formId, nextProps.errors)
    }
    if (
      nextProps.isPrimaryTriggered &&
      nextProps.alertMeta &&
      nextProps.alertMeta.action === 'saveAsAssigned'
    ) {
      this.saveAsAssigned()
    }
  }

  handleSubmit = (formData) => {
    const { createReservation, updateReservation, id } = this.props
    const reservation = formData
    if (this.markAsCompleted) {
      reservation.jobAssignStatus = 'completed'
    } else {
      reservation.jobAssignStatus = 'inComplete'
    }
    if (id === undefined)
      createReservation(reservation, { onResponse: this.handleResponse })
    else updateReservation(reservation, { onResponse: this.handleResponse })

    if (this.props.isPrimaryTriggered) {
      this.props.hideAlert()
    }
  }

  handleResponse = ({ ok }) => {
    if (ok) toastr.success('Save completed')
    //   this.props.onSubmit()
  }

  submit = (e) => {
    this.props.submit(formId)
    if (e) e.preventDefault()
  }

  handleNewJobAssignment = () => ({
    issuedServiceDateTime: this.state.formData.serviceDateTime,
  })

  isCompleteState() {
    if (!isEmpty(this.state.formData.jobAssignStatus))
      return this.state.formData.jobAssignStatus === 'completed'
    return undefined
  }

  handleDraftClick = () => {
    this.saveAsDraft()
  }

  handleAssignedClick = () => {
    if (isReservationHasJobAssignmentWarning(this.props.formValues))
      this.props.showAlert(
        {
          header: 'Are you sure to save as assigned?',
          description:
            "There's service not being assigned, Do you still want to save as assigned?",
          primary: {
            title: 'Save',
            bsStyle: 'primary',
          },
          defaultCancelButton: true,
        },
        { action: 'saveAsAssigned' },
      )
    else {
      this.saveAsAssigned()
    }
  }

  saveAsDraft = () => {
    this.markAsCompleted = false
    this.submit()
  }

  saveAsAssigned = () => {
    this.markAsCompleted = true
    this.submit()
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <AssignJobForm
          form={formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          onFinishedAssign={this.props.onSubmit}
          onNewJobAssignment={this.handleNewJobAssignment}
          isCompleteState={this.isCompleteState()}
          onDraftClick={this.handleDraftClick}
          onAssignedClick={this.handleAssignedClick}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { reservation, staff }, ...state }) => ({
    reservation: reservation ? reservation.single : undefined,
    errors: reservation ? reservation.singleErrors : null,
    formValues: getFormValues(formId)(state),
  }),
  {
    createReservation,
    updateReservation,
    fetchReservation,
    clearReservation,
    submit,
    stopSubmit,
    showAlert,
    hideAlert,
  },
)(withAlertModal(AssignJobFormPage))
