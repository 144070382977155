import React from 'react'
import { connect } from 'react-redux'
import {
  showAlert,
  hideAlert,
  triggerAction,
  actionEnd,
} from '../../actions/alert'

export default (Component) => {
  const AlertModalWrapper = (props) => <Component {...props} />
  return connect(
    ({ alert }) => ({
      isShowAlert: alert.isShowAlert,
      isPrimaryTriggered: alert.isPrimaryTriggered,
      isSecondaryTriggered: alert.isSecondaryTriggered,
      alertMeta: alert.meta,
    }),
    { showAlert, hideAlert, triggerAction, actionEnd },
  )(AlertModalWrapper)
}
