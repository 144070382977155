import React from 'react'
import './custom-bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './react-datetime.css'
import 'react-select/dist/react-select.css'
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.css'
import './App.css'
import { Route, Link, Switch, Redirect } from 'react-router-dom'
import { Navbar, Nav, Button, MenuItem, NavDropdown } from 'react-bootstrap'
import Sidebar from './components/layout/Sidebar'

import PrivateRoute from './components/auth/PrivateRoute'

import Dashboard from './components/Dashboard'
// import LangSwitcher from './components/LangSwicher';
import UserInfoContainer from './components/auth/UserInfoContainer'
import LogoutContainer from './components/auth/LogoutContainer'
import { AlertModal } from './components/alertModal'
import { MainPanel, SidebarMenuItem } from './components/layout'
import { connect } from 'react-redux'
import { accessRoutes, menusByAuth } from './modules/accessRoute'
import PermissionDeninedPage from './components/auth/PermissionDeninedPage'
import TableLoading from './components/table/TableLoading'
import WebReport, { isReportWebRoute } from './components/report/WebReport'
import WebReportHelp from './components/report/WebReportHelp'

const SidebarToggleButton = ({ onToggleSidebar }) => (
  <Button
    type="button"
    className="navbar-toggle collapsed"
    onClick={onToggleSidebar}
    style={{ float: 'left', display: 'block', borderWidth: 0 }}
  >
    <span className="sr-only">Toggle navigation</span>
    <span className="icon-bar" />
    <span className="icon-bar" />
    <span className="icon-bar" />
  </Button>
)

class AppRouter extends React.Component {
  state = { isSidebarOpen: false }

  toggleSidebar = () => {
    this.setState(({ isSidebarOpen }) => ({ isSidebarOpen: !isSidebarOpen }))
  }

  render() {
    const { isPrepared, isLoggedIn, isFetching } = this.props.auth
    if (!isPrepared || isFetching) return <TableLoading>Loading</TableLoading>
    if (!isLoggedIn) return <Redirect to="/login" />

    if (this.props.location.pathname === '/report/web/help') {
      return <WebReportHelp />
    }

    if (isReportWebRoute(this.props.location)) return <WebReport />

    return (
      <div>
        <Navbar fixedTop collapseOnSelect className="app-bar">
          <Navbar.Header>
            <SidebarToggleButton onToggleSidebar={this.toggleSidebar} />
            <Navbar.Brand>
              <Link to="/">
                <img
                  style={{ maxHeight: 25, marginTop: -3 }}
                  src={require('./img/coral-logo.png')}
                  alt="logo"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle style={{ display: 'none' }} />
          </Navbar.Header>

          <Nav pullRight>
            <NavDropdown title={<UserInfoContainer />} id="basic-nav-dropdown">
              {/* <MenuItem ><LangSwitcher lang="th"><div>TH</div></LangSwitcher></MenuItem>
                <MenuItem ><LangSwitcher lang="en"><div>EN</div></LangSwitcher></MenuItem>
                <MenuItem divider /> */}
              <MenuItem>
                <LogoutContainer>
                  <div>Log Out</div>
                </LogoutContainer>
              </MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar>

        <div className="container-fluid" style={{ marginTop: 50 }}>
          <div className="row">
            <Sidebar
              side="left"
              isVisible={this.state.isSidebarOpen}
              onHide={this.toggleSidebar}
            >
              {menusByAuth(this.props.auth).map((menu) => (
                <div key={menu.title}>
                  <h5>{menu.title}</h5>
                  <ul className="nav nav-sidebar">
                    {menu.menuItems.map((menuItem) => (
                      <SidebarMenuItem {...menuItem} key={menuItem.path} />
                    ))}
                  </ul>
                </div>
              ))}
            </Sidebar>

            <MainPanel>
              <Switch>
                <Route
                  path="/permission-denined"
                  component={PermissionDeninedPage}
                />
                {/* <PrivateRoute path="/customer" component={CustomerPage} ifAnyGranted={['user', 'supervisor', 'admin']} /> */}
                {accessRoutes.map((route) => (
                  <PrivateRoute key={route.path} {...route} />
                ))}
                <Route path="/" component={Dashboard} />
              </Switch>
              <AlertModal />
            </MainPanel>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(({ auth }) => ({
  auth,
}))(AppRouter)
