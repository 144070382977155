import React from 'react'

class DetailPopupContainer extends React.Component {
  state = {
    isDetailPopupOpen: false,
    openingDetailId: undefined,
    isRefetchItems: false,
  }

  handleOpenDetailPopup = (id) =>
    this.setState({
      ...this.state,
      isDetailPopupOpen: true,
      openingDetailId: id,
    })
  handleCloseDetailPopup = () =>
    this.setState({
      ...this.state,
      isDetailPopupOpen: false,
      openingDetailId: undefined,
    })

  handleRefetchItemStart = () =>
    this.setState({ ...this.state, isRefetchItems: true })
  handleRefetchItemSuccess = () =>
    this.setState({ ...this.state, isRefetchItems: false })

  render() {
    const { isDetailPopupOpen, openingDetailId, isRefetchItems } = this.state
    const {
      children: Children,
      popupComponent: Popup,
      onConfirm,
      ...rest
    } = this.props
    return (
      <div>
        <Popup
          {...rest}
          onConfirm={onConfirm}
          isOpen={isDetailPopupOpen}
          onClose={this.handleCloseDetailPopup}
          detailId={openingDetailId}
          onRefetchStart={this.handleRefetchItemStart}
        />
        <Children
          {...rest}
          onOpenDetailPopup={this.handleOpenDetailPopup}
          isRefetchItems={isRefetchItems}
          onRefetchSuccess={this.handleRefetchItemSuccess}
        />
      </div>
    )
  }
}

export const withDetailPopupProvider =
  (component, popupComponent) => (props) => (
    <DetailPopupContainer
      {...props}
      children={component}
      popupComponent={popupComponent}
    />
  )
