import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchUser } from '../../actions/auth'
import Login from './Login'
import { toastr } from 'react-redux-toastr'

class LoginContainer extends React.Component {
  state = {
    redirectToReferrer: false,
  }

  login = (info) => {
    //after login completed
    this.props.fetchUser(info)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isLoggedIn && !nextProps.auth.isFetching) {
      this.setState({ redirectToReferrer: true })
    } else if (
      !nextProps.auth.isFetching &&
      !nextProps.auth.isLoggedIn &&
      this.props.auth.error !== nextProps.auth.error &&
      nextProps.auth.error
    ) {
      toastr.error(nextProps.auth.error)
    }
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const { redirectToReferrer } = this.state

    if (redirectToReferrer) {
      return <Redirect to={from} />
    }

    return <Login onLogin={this.login} from={from} />
  }
}
export default connect(
  (state) => ({
    auth: state.auth,
  }),
  { fetchUser },
)(LoginContainer)
