import React from 'react'
import { Panel, Form, Col, FormControl, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import Select from 'react-select'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {
  const fieldsLabel = I18n.t('voucherBookPage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Col md={4}>
          <FormControl
            type="text"
            value={filter.name || ''}
            onChange={(e) => onFilterChange('name', e.currentTarget.value)}
            placeholder={fieldsLabel.name}
          />
        </Col>
        <Col md={4}>
          <Select
            value={filter.status}
            onChange={(value) => onFilterChange('status', value)}
            placeholder={fieldsLabel.status}
            simpleValue
            options={statusOptions}
          />
        </Col>
        <Col md={4}>
          <Button className="pull-right" onClick={onClearFilter}>
            <span className="glyphicon glyphicon-trash" /> Clear Filter
          </Button>
        </Col>
      </Form>
    </Panel>
  )
}

const statusOptions = [
  { label: 'VALID', value: 'valid' },
  { label: 'INVALID', value: 'invalid' },
]
