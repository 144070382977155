import React from 'react'
import PrivateRoute from '../auth/PrivateRoute'
import { withRouter } from 'react-router-dom'
import StaffFormPage from './StaffFormPage'
import { findAccessLevel } from '../../modules/accessRoute'
import StaffTableContainer from './StaffTableContainer'

export class StaffPage extends React.Component {
  handleSubmit = () => {
    this.props.history.push(`/staff`)
  }

  table = () => <StaffTableContainer path="/staff" />

  formPage = ({
    match: {
      params: { id },
    },
  }) => <StaffFormPage id={id} onSubmit={this.handleSubmit} />

  render() {
    return (
      <div>
        <PrivateRoute
          exact
          path="/staff"
          component={this.table}
          ifAnyGranted={findAccessLevel('/staff')}
        />

        <PrivateRoute
          path="/staff/new"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/staff')}
        />

        <PrivateRoute
          path="/staff/edit/:id"
          component={this.formPage}
          ifAnyGranted={findAccessLevel('/staff')}
        />
      </div>
    )
  }
}
export default withRouter(StaffPage)
