import React from 'react'
import { reduxForm } from 'redux-form'
import { TextField, Toolbar } from '../form'
import {
  required,
  validateWith,
  isPositiveNumber,
  isInteger,
  date,
} from '../form/validator'
import { Button, Col, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

import VoucherBookFormLayout from './VoucherBookFormLayout'
import ProductIdCheckboxSelectTableContainer from '../product/ProductIdCheckboxSelectTableContainer'
import FormattedDateTimeField from '../form/FormattedDateTimeField'
import DebtorSearchSelectField from '../debtor/DebtorSearchSelectField'

const validate = (values) => {
  const errors = {}
  errors.name = validateWith(values.name, [required])
  errors.debtorId = validateWith(values.debtorId, [required])
  errors.totalUsageAmount = validateWith(values.totalUsageAmount, [
    required,
    isPositiveNumber,
    isInteger,
  ])
  errors.price = validateWith(values.price, [required, isPositiveNumber])
  errors.startDate = validateWith(values.startDate, [required, date])
  errors.endDate = validateWith(values.endDate, [required, date])
  return errors
}

const VoucherBookForm = ({
  initialValues,
  formMode,
  form: formId,
  isLocked = false,
}) => {
  const { form, fields } = I18n.t('voucherBookPage')
  return (
    <div className="container">
      <Toolbar title={form[formMode]}>
        <Button className="btn-primary" type="submit" disabled={isLocked}>
          Submit
        </Button>
      </Toolbar>
      <VoucherBookFormLayout
        renderLeftPanel={() => (
          <div>
            <Row>
              <Col sm={12}>
                <TextField
                  name="name"
                  label={fields.name}
                  addStar
                  disabled={isLocked}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <DebtorSearchSelectField
                  name="debtorId"
                  label={fields.debtorId}
                  selectValue={(debtor) => debtor._id}
                  initialValue={initialValues.debtorId}
                  addStar
                  disabled={isLocked}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <TextField
                  name="totalUsageAmount"
                  type="number"
                  min="0"
                  label={fields.totalUsageAmount}
                  addStar
                  disabled={isLocked}
                />
              </Col>
              <Col sm={6}>
                <TextField
                  name="price"
                  type="number"
                  min="0"
                  label={fields.price}
                  addStar
                  disabled={isLocked}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormattedDateTimeField
                  name="startDate"
                  label={fields.startDate}
                  type="date"
                  addStar
                  inputProps={{ disabled: isLocked }}
                />
              </Col>
              <Col md={6}>
                <FormattedDateTimeField
                  name="endDate"
                  label={fields.endDate}
                  type="date"
                  addStar
                  inputProps={{ disabled: isLocked }}
                />
              </Col>
            </Row>
          </div>
        )}
        renderRightPanel={() => (
          <ProductIdCheckboxSelectTableContainer
            formId={formId}
            fieldName="productIds"
            disabled={isLocked}
          />
        )}
      />
    </div>
  )
}

export default reduxForm({
  validate,
})(VoucherBookForm)
