import constants from '../constants'

export const updateFilterQuery = (name, keyPath, value) => ({
  type: constants.UPDATE_QUERY_FILTER,
  name,
  keyPath,
  value,
})

export const initFilter = (name, defaults) => ({
  type: constants.INIT_FILTER,
  name,
  defaults,
})

export const updateFilterProps = (name, props) => ({
  type: constants.UPDATE_FILTER_PROPS,
  name,
  props,
})

export const clearFilter = (name) => ({
  type: constants.CLEAR_FILTER,
  name,
})
