import React from 'react'
import { I18n } from 'react-redux-i18n'
import styledComponents from 'styled-components'
import { pricingProductType } from '../../services/pricingProduct'
import SteppedPricingProductTableDetailStepItem, {
  SteppedPricingProductTableDetailStepAddendumPrice,
} from './pricingContractInput/SteppedPricingProductTableDetailStepItem'
import { priceFormat } from '../../services/currency'

const ShowPricingProductTable = ({ prices }) => (
  <table className="table table-hover">
    <thead>
      <tr>
        <th>Product</th>
        <th>Type</th>
        <th className="text-right">Price</th>
        <th className="text-right">Addendum Price</th>
      </tr>
    </thead>
    <tbody>
      {prices &&
        prices.map((price) => (
          <TableRow key={price.id}>
            <td>{price.product.name}</td>
            <td>
              {I18n.t(
                `pricingContractPage.fields.pricingProduct.${price.type}`,
              )}
            </td>
            <td>{renderPriceInfo(price)}</td>
            <td>{renderAddendumPrice(price)}</td>
          </TableRow>
        ))}
    </tbody>
  </table>
)

const TableRow = styledComponents.tr`
  vertical-align: text-top;
`

const renderPriceInfo = (price) => {
  switch (price.type) {
    case pricingProductType.fixed:
      return (
        <div className="text-right">
          {priceFormat(price.fixedPriceInfo.price)}
        </div>
      )
    case pricingProductType.stepped:
      return (
        price.steppedPriceInfo.steps &&
        price.steppedPriceInfo.steps.map((step, index) => (
          <SteppedPricingProductTableDetailStepItem
            key={step._id || index}
            step={step}
            seq={index + 1}
          />
        ))
      )
    default:
      throw Error('type is not support', price)
  }
}

const renderAddendumPrice = (price) => {
  switch (price.type) {
    case pricingProductType.fixed:
      return (
        <div className="text-right">
          {priceFormat(price.fixedPriceInfo.addendumPrice)}
        </div>
      )
    case pricingProductType.stepped:
      return (
        price.steppedPriceInfo.steps &&
        price.steppedPriceInfo.steps.map((step, index) => (
          <SteppedPricingProductTableDetailStepAddendumPrice
            key={step._id || index}
            step={step}
            seq={index + 1}
          />
        ))
      )
    default:
      throw Error('type is not support', price)
  }
}

export default ShowPricingProductTable
