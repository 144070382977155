import React from 'react'

export default function WebCardReport({
  data: {
    options: { data, dataset },
  },
}) {
  const {
    IMAGE_URL,
    title,
    stationName,
    startDate,
    endDate,
    user_displayName,
    totalGuestPaxAdult,
    totalGuestPaxChild,
  } = data
  return (
    <div className="w-[840px] mx-auto py-6 px-6 bg-white print:bg-transparent shadow print:shadow-none">
      <div className="w-full py-6 px-1 flex flex-col space-y-4">
        <div className="relative w-full flex justify-center p-4">
          <span className="text-2xl font-semibold">{title}</span>
          <img
            className="absolute top-[0.5rem] right-0 object-contain h-8"
            src={`${IMAGE_URL}/coral-logo.png`}
            alt="coral"
          />
        </div>

        <div className="w-full flex">
          <span className="font-semibold flex-1">{stationName}</span>
          <span className="space-x-2">
            <span className="font-semibold uppercase">Date:</span>
            <span>
              {startDate === endDate ? startDate : `${startDate} - ${endDate}`}
            </span>
          </span>
        </div>

        {dataset.serviceTxns.length > 0 ? (
          <table className="table table-fixed w-full text-base text-left rtl:text-right text-stone-500 rounded-lg overflow-hidden mt-4">
            <thead className="text-base text-stone-700 uppercase bg-stone-100">
              <tr>
                <th className="px-3 py-2 w-[30px] text-right">No.</th>
                <th className="px-3 py-2">Passenger Name</th>
                <th className="px-3 py-2 w-[45px]">Flight</th>
                <th className="px-3 py-2 w-[45px]">Seat</th>
                <th className="px-3 py-2 w-[45px]">IN</th>
                <th className="px-3 py-2 w-[45px]">OUT</th>
                <th className="px-3 py-2 w-[40px]">Adult</th>
                <th className="px-3 py-2 w-[40px]">Child</th>
                <th className="px-3 py-2">Staff</th>
                <th className="px-3 py-2">Remark</th>
              </tr>
            </thead>
            <tbody>
              {dataset.serviceTxns.map((item, index) => {
                return (
                  <tr
                    key={item._id}
                    className="odd:bg-white even:bg-stone-50 border-b"
                  >
                    <td className="px-3 py-2 text-right">{index + 1}</td>
                    <td className="px-3 py-2">{item.paxName}</td>
                    <td className="px-3 py-2">{item.flightNumber}</td>
                    <td className="px-3 py-2">{item.seatNumber}</td>
                    <td className="px-3 py-2">{item.inTime}</td>
                    <td className="px-3 py-2">{item.outTime}</td>
                    <td className="px-3 py-2">{item.guestPaxAdult}</td>
                    <td className="px-3 py-2">{item.guestPaxChild}</td>
                    <td className="px-3 py-2">{item.userDisplayName || '-'}</td>
                    <td className="px-3 py-2">{item.remark}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <div className="p-10 w-full text-center text-2xl font-semibold">
            No Results
          </div>
        )}

        <div className="w-full flex pt-6">
          <span className="">
            <span className="font-semibold">REPORTED BY:</span>{' '}
            {user_displayName}
          </span>
        </div>

        <div className="w-full flex justify-center my-4">
          <span className="flex rounded-lg py-4 px-6 bg-stone-100  font-semibold space-x-6">
            <span className="">
              TOTAL {(totalGuestPaxAdult + totalGuestPaxChild).toLocaleString()}{' '}
              PAX
            </span>
            <span className="">
              ADULT {totalGuestPaxAdult.toLocaleString()} PAX
            </span>
            <span className="">
              CHILD {totalGuestPaxChild.toLocaleString()} PAX
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
