import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import { fetchAgents, clearAgent } from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'

class AgentSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId="agentOptions"
      />
    )
  }
}

export const createQuery = (searchText) => ({
  q: searchText ? searchText.toLowerCase() : undefined,
})

const defaultValueQuery = (initialId) => ({
  _id: initialId,
})

const createLabel = (a) => {
  return [
    a.name,
    a.code ? `(${a.code})` : [],
    a.markAsDeleted ? `[DELETED]` : [],
  ]
    .flat()
    .join(' ')
}

export default connect(null, {
  fetchOptions: fetchAgents,
  clearOptions: clearAgent,
})(AgentSearchSelect)
