import React from 'react'
import { connect } from 'react-redux'
import { debounce, get } from 'lodash'

import { fetchStaffs, deleteStaff } from '../../actions/remote_api'
import StaffTable from './StaffTable'
import { escape } from '../../utils/regex'

class StaffTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = (filter) => {
    this.props.fetchStaffs({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = (filter) => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.code) queryfilter.code_like = escape(filter.code)
    if (filter.firstName) queryfilter.firstName_like = escape(filter.firstName)
    if (filter.lastName) queryfilter.lastName_like = escape(filter.lastName)
    if (filter.gender) queryfilter.gender = filter.gender
    if (filter.email) queryfilter.email_like = escape(filter.email)
    return queryfilter
  }

  render() {
    return <StaffTable {...this.props} onDataChange={this.handleDataChange} />
  }
}

export default connect(
  ({ fetch }) => ({
    isLoading: get(fetch, 'staff.isLoading.list', false),
    instances: get(fetch, 'staff.list', []),
    totalCount: get(fetch, 'staff.totalCount'),
  }),
  { fetchStaffs, deleteInstance: deleteStaff },
)(StaffTableContainer)
