import { Link } from 'react-router-dom'
import styledComponents from 'styled-components'
import { withHandlers } from 'recompose'

const LinkButton = styledComponents(Link).attrs({
  className: 'btn btn-default',
})`
`

export default withHandlers({
  onClick: (props) => (e) => {
    if (props.disabled) {
      e.preventDefault()
    }

    if (props.onClick) props.onClick(e)
  },
})(LinkButton)
