import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import {
  fetchServiceTxnForms,
  updateServiceTxnForm,
  clearServiceTxnForm,
  fetchServiceTxnsCount,
  clearServiceTxnsCount,
} from '../../actions/remote_api'
import ServiceFormPagerContainer from './ServiceFormPager.container'
import { isExtraCharged } from '../../services/serviceTxn'

class ServiceFormAPI extends React.Component {
  isLoadServiceTxn = false
  state = {
    currentPage: undefined,
    totalPage: undefined,
    dateValue: moment(),
  }

  componentDidMount() {
    this.clearServiceTxnForm()

    if (this.props.loadServiceTxn) {
      this.isLoadServiceTxn = true
      this.setState(
        {
          dateValue: moment(this.props.loadServiceTxn.serviceDateTime),
        },
        this.fetchServiceTxnForms,
      )
    } else {
      this.fetchServiceTxnForms()
    }
  }

  fetchServiceTxnBySeq = (seq) => {
    if (!seq) return
    this.fetchServiceTxn(seq)
  }

  getCurrentPage = (props) =>
    props.count > 0 ? _.get(props, 'loadServiceTxn.seq', 1) : undefined

  componentWillReceiveProps(nextProps) {
    if (nextProps.count !== this.props.count) {
      this.setState(
        {
          currentPage: this.getCurrentPage(nextProps),
          totalPage: nextProps.count,
        },
        () => {
          if (this.state.currentPage > 0)
            this.fetchServiceTxn(this.state.currentPage)
          else this.clearServiceTxnForm()
        },
      )
    }
  }

  clearServiceTxnForm = () => {
    this.props.clearServiceTxnForm('list')
    this.props.clearServiceTxnsCount()
  }

  fetchServiceTxnForms() {
    this.props.fetchServiceTxnsCount(this.createQuery())
  }

  fetchServiceTxn(seq = 1) {
    this.setState(
      {
        currentPage: seq,
      },
      () => {
        this.props.fetchServiceTxnForms({
          ...this.createQuery(seq),
          sort: 'seq',
          order: 'asc',
          seq,
        })
      },
    )
  }

  createQuery = () => ({
    needWorkSheet: true,
    serviceDateTime_gte: this.state.dateValue.startOf('day').toISOString(),
    serviceDateTime_lte: this.state.dateValue.endOf('day').toISOString(),
    noCancel: true,
  })

  handleDateChange = (dateValue) => {
    // this callback always happens after initialized which could means it load service txn from router state
    // but this prevent loading from router state twice
    this.isLoadServiceTxn = false
    if (!this.state.dateValue.isSame(dateValue, 'day')) {
      this.clearServiceTxnForm()
      this.setState(
        (state) => ({
          ...state,
          dateValue,
        }),
        this.fetchServiceTxnForms,
      )
    }
  }

  handleUpdateServiceTxnForm = (serviceTxn, option) => {
    serviceTxn.isExtraCharged = isExtraCharged(serviceTxn)
    this.props.updateServiceTxnForm(serviceTxn, option)
  }

  render() {
    return (
      <ServiceFormPagerContainer
        dateValue={this.state.dateValue}
        onDateChange={this.handleDateChange}
        updateServiceTxnForm={this.handleUpdateServiceTxnForm}
        serviceTxn={this.props.serviceTxn}
        currentPage={this.state.currentPage}
        totalPage={this.state.totalPage}
        fetchServiceTxnBySeq={this.fetchServiceTxnBySeq}
      />
    )
  }
}

export default connect(
  ({ fetch: { serviceTxnForm, serviceTxnsCount } }) => ({
    serviceTxn: _.get(serviceTxnForm, 'list.0', undefined),
    count: _.get(serviceTxnsCount, 'single.count', 0),
  }),
  {
    fetchServiceTxnForms,
    updateServiceTxnForm,
    clearServiceTxnForm,
    fetchServiceTxnsCount,
    clearServiceTxnsCount,
  },
)(ServiceFormAPI)
