import React from 'react'
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import styled from 'styled-components'
import { plusCircled, checkmarkCircled } from 'react-icons-kit/ionicons'
import { ButtonIcon, ButtonIconIcon } from './ButtonIcon'

export default ({ onAddClick, onSaveClick }) => {
  return (
    <ToolbarLayout>
      <ButtonToolbar>
        <ButtonGroup>
          <ButtonIcon bsStyle="primary" onClick={onAddClick}>
            <ButtonIconIcon icon={plusCircled} /> Add
          </ButtonIcon>
          <ButtonIcon onClick={onSaveClick} bsStyle="success">
            <ButtonIconIcon icon={checkmarkCircled} /> Save
          </ButtonIcon>
        </ButtonGroup>
      </ButtonToolbar>
    </ToolbarLayout>
  )
}

const ToolbarLayout = styled.div`
  margin-bottom: 15px;
`
