import React from 'react'
import { reduxForm } from 'redux-form'
import { TextField, Toolbar } from '../form'
import {
  required,
  validateWith,
  isPositiveNumber,
  date,
} from '../form/validator'
import { Button, Col, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

import FormattedDateTimeField from '../form/FormattedDateTimeField'
import AgentSearchSelectField from '../agent/AgentSearchSelectField'
import { agentTypes } from '../../services/agentType'

const validate = (values) => {
  const errors = {}
  errors.agentId = validateWith(values.agentId, [required])
  errors.price = validateWith(values.price, [required, isPositiveNumber])
  errors.issuedDate = validateWith(values.issuedDate, [required, date])
  return errors
}

const TopupForm = ({
  initialValues,
  formMode,
  form: formId,
  isLocked = false,
}) => {
  const { form, fields } = I18n.t('topupPage')
  return (
    <div className="container">
      <Toolbar title={form[formMode]}>
        <Button className="btn-primary" type="submit" disabled={isLocked}>
          Submit
        </Button>
      </Toolbar>
      <Row>
        <Col sm={6}>
          <AgentSearchSelectField
            name="agentId"
            label={fields.agent}
            initialValue={initialValues.agentId}
            disabled={isLocked}
            selectValue={(agent) => agent._id}
            createQuery={(searchText) => ({
              q: searchText ? searchText.toLowerCase() : undefined,
              type: agentTypes.prepaid,
            })}
            addStar
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <TextField
            name="price"
            type="number"
            min="0"
            label={fields.price}
            addStar
            disabled={isLocked}
          />
        </Col>
        <Col md={3}>
          <FormattedDateTimeField
            name="issuedDate"
            label={fields.issuedDate}
            type="date"
            addStar
            inputProps={{ disabled: isLocked }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default reduxForm({
  validate,
})(TopupForm)
