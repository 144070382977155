import React from 'react'
import { FieldLabel } from './'
import styled from 'styled-components'
import { I18n } from 'react-redux-i18n'
import { Button } from 'react-bootstrap'
import { uploadImage, removeImage } from '../../api'
import { connect } from 'react-redux'
import { urlWithTimeQuery } from '../table'

export default (props) => (
  <FieldLabel {...props} component={ImageUploadContainer} />
)

const ImageUpload = ({
  value,
  onChange,
  name,
  height,
  width,
  uploadURL,
  emptyLabel,
  jwt,
  ...props
}) => {
  const { upload, remove } = I18n.t('action')
  return (
    <div>
      <div className="text-center">
        <ImageUploadToolbar>
          <span className="btn btn-default btn-file">
            {upload.toUpperCase()}{' '}
            <input
              type="file"
              onChange={makeUploadImageHandler(uploadURL, value, onChange, jwt)}
            />
          </span>
          <Button
            onClick={makeRemoveImageHandler(uploadURL, value, onChange, jwt)}
          >
            {remove.toUpperCase()}
          </Button>
        </ImageUploadToolbar>
        <LogoPreviewContainer src={value} alt={name} />
      </div>
    </div>
  )
}

const ImageUploadContainer = connect(({ auth }) => ({ jwt: auth.jwt }))(
  ImageUpload,
)

class LogoPreviewContainer extends React.Component {
  state = {
    src: urlWithTimeQuery(this.props.src) || '/assets/images/no_image.png',
  }

  updateImageSource(src = this.props.src) {
    this.setState({
      src: urlWithTimeQuery(src) || '/assets/images/no_image.png',
    })
  }

  handleError = () => {
    this.updateImageSource(null)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.updateImageSource(nextProps.src)
    }
  }

  render() {
    return (
      <LogoPreview
        src={this.state.src}
        onError={this.handleError}
        alt={this.props.alt}
      />
    )
  }
}

const LogoPreview = styled.img.attrs({ className: 'img-responsive' })`
  padding: 10px;
  margin: 0 auto;
  margin-top: 10px;
  background-size: contain;
  border: 2px solid #72421a;
  border-radius: 15px;
  height: 200px;
  width: auto;
`

const makeUploadImageHandler =
  (uploadURL, imageURL, handleImageChange, jwt) => (event) => {
    if (!event.target.files) return
    if (!event.target.files[0].type.startsWith('image/')) return

    handleImageChange(null)
    uploadImage(uploadURL, event.target.files[0], jwt).then((url) =>
      handleImageChange(url),
    )
  }

const makeRemoveImageHandler =
  (uploadURL, imageURL, handleImageChange, jwt) => () => {
    removeImage(uploadURL, jwt).then((response) => {
      if (response.ok) handleImageChange(null)
    })
  }

const ImageUploadToolbar = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  > * {
    margin-left: 7px;
    margin-right: 7px;
  }
`
