import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

export default ({ closeModal, showModal, performDeletion }) => {
  const confirmDelete = () => {
    performDeletion()
    closeModal()
  }
  const i18nAction = I18n.t('action')
  const i18nDeleteModal = I18n.t('deleteModal')
  return (
    <div>
      <Modal show={showModal} onHide={closeModal} bsSize="sm">
        <Modal.Header closeButton>
          <Modal.Title>{i18nDeleteModal.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{i18nDeleteModal.message}</Modal.Body>
        <Modal.Footer>
          <Button bsStyle="danger" onClick={confirmDelete}>
            {i18nAction.confirm}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
