import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Row, Col, Button } from 'react-bootstrap'
import { TextField, ListField } from '../form/index'
import ServiceMappingSearchSelectField from '../service/ServiceMappingSearchSelectField'

export default (props) => (
  <ListField {...props} inputFieldItem={ClassToServiceMappingForm} />
)

const ClassToServiceMappingForm = ({ path, onRemove, ...rest }) => {
  const label = I18n.t('classToServiceMappingField')

  return (
    <Row>
      <Col sm={3}>
        <TextField
          name={`${path}.privilegeClass`}
          label={label.privilegeClass}
        />
      </Col>
      <Col sm={7}>
        <ServiceMappingSearchSelectField
          name={`${path}.meta.serviceMappingId`}
          label={label.serviceMappingId}
          selectValue={(serviceMapping) => serviceMapping._id}
        />
      </Col>
      <Col sm={2} style={{ marginTop: 24 }}>
        <Button onClick={onRemove} bsStyle="danger" className="pull-right">
          {I18n.t('action.remove').toUpperCase()}
        </Button>
      </Col>
    </Row>
  )
}
