import { toastr } from 'react-redux-toastr'

export function showReportRequestSubmittedToast() {
  toastr.info(
    'Report Request Submitted',
    "Your report will soon be sent to your email inbox when it's ready",
  )
}

export function showReportRequestErrorToast(errorMessage) {
  toastr.error('Report Request Failed', errorMessage)
}

export async function handleReportRequest(promise) {
  try {
    const response = await promise
    if (response.ok) showReportRequestSubmittedToast()
    else {
      const body = await response.json()
      showReportRequestErrorToast(body.error)
    }
  } catch (error) {
    showReportRequestErrorToast(error.message)
  }
}

export function showReportProcessFailedToast() {
  toastr.error(
    'Your Report Process Failed',
    'Please try again. If the problem persists please contact the admin.',
  )
}

export async function handleReportDownload(promise) {
  toastr.info(
    'Report Request Submitted',
    'Your report is processing please hang in there. It may take a while.',
  )
  try {
    const response = await promise
    console.log(response)
    if (response.ok) {
      toastr.info('Your report is ready', 'Downloading the Report...')
    } else {
      showReportProcessFailedToast()
    }
  } catch (error) {
    showReportProcessFailedToast()
  }
}
