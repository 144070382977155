import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import _ from 'lodash'
import { fetchAgent } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { priceFormat } from '../../services/currency'
import { agentTypes } from '../../services/agentType'

const AgentDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('agentPage')
  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.code}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.code}</p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.name}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.name}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.type}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && instance.type.toUpperCase()}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.phone1}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.phone1}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.phone2}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.phone2}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.email}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.email}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.email2}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && instance.email2 ? instance.email2 : '-'}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.email3}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && instance.email3 ? instance.email3 : '-'}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.address}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.address}</p>
        </div>
      </div>
      {instance.type === agentTypes.prepaid &&
        renderPrepaidInfo(fields, instance)}
    </form>
  )
}

function renderPrepaidInfo(fields, instance) {
  return (
    <div>
      <hr />
      <div className="form-group">
        <label className="col-sm-4 control-label">
          {fields.remainingTopUp}
        </label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && priceFormat(instance.remainingTopUp)}
          </p>
        </div>
      </div>
    </div>
  )
}

let AgentDetailPopupContainer = withDetailPopup(AgentDetailPopup, () => ({
  header: I18n.t('agentPage.detail.header'),
}))

export default connect(
  (state) => ({ instance: _.get(state, 'fetch.agent.single') }),
  { fetchInstance: fetchAgent },
)(AgentDetailPopupContainer)
