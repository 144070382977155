import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import { fetchStaffs, clearStaff } from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'

class StaffSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId={this.props.selectId || 'staffOptions'}
      />
    )
  }
}

const createQuery = (searchText) => ({
  q: searchText ? searchText.toLowerCase() : undefined,
})

const defaultValueQuery = (initialId) => ({
  _id: initialId,
})

const createLabel = (staff) =>
  `${staff.code} ${staff.firstName} ${staff.lastName}`

export default connect(null, {
  fetchOptions: fetchStaffs,
  clearOptions: clearStaff,
})(StaffSearchSelect)
