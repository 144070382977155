import React from 'react'
import { Panel, Form, Col, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import Select from 'react-select'
import AgentSearchSelect from '../agent/AgentSearchSelect'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {
  const fieldsLabel = I18n.t('topupPage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Col md={4}>
          <AgentSearchSelect
            initialValue={filter.agentId}
            value={filter.agentId}
            onChange={(value) => onFilterChange('agentId', value)}
            placeholder={fieldsLabel.agent}
            selectValue={(agent) => agent._id}
            options={paidOptions}
          />
        </Col>
        <Col md={4}>
          <Select
            value={filter.isPaid}
            onChange={(value) => onFilterChange('isPaid', value)}
            placeholder={fieldsLabel.isPaid}
            simpleValue
            options={paidOptions}
          />
        </Col>
        <Col md={4}>
          <Button className="pull-right" onClick={onClearFilter}>
            <span className="glyphicon glyphicon-trash" /> Clear Filter
          </Button>
        </Col>
      </Form>
    </Panel>
  )
}

const paidOptions = [
  { label: 'PAID', value: true },
  { label: 'UNPAID', value: false },
]
