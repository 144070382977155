import React from 'react'

const Loader = ({ isLoading, loading, children }) => (
  <div>
    {isLoading && loading}
    {!isLoading && children}
  </div>
)

export default Loader
