import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { Label } from 'react-bootstrap'
import { get } from 'lodash'

import { fetchServiceTxn } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { dateTimeFormat } from '../table'

const optionalTextFormat = (text) => (text ? text : '-')
const numberFormat = (n) => (n ? n : '-')
const durationFormat = (n) => (n ? `${n} Hr.` : '-')
const statusTextFormat = (status) => (status ? status.toUpperCase() : '-')
const noteTypeFormat = (status) => {
  if (status === 'comment')
    return <Label bsStyle="success">{status.toUpperCase()}</Label>
  else if (status === 'complain')
    return <Label bsStyle="danger">{status.toUpperCase()}</Label>
  else return '-'
}

const ServiceTxnDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('serviceTxnPage')

  return (
    <form className="form-horizontal">
      <div className="row">
        <div className="col-sm-7">
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.status}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && statusTextFormat(instance.status)}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.station}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.station && instance.station.name
                  ? instance.station.name
                  : '-'}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.mainPax}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.customerName}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.guestOf}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.guestOf && instance.guestOf.customerName
                  ? instance.guestOf.customerName
                  : '-'}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">
              {fields.serviceDateTime}
            </label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance.serviceDateTime
                  ? dateTimeFormat(instance.serviceDateTime)
                  : '-'}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">
              {fields.flightNumber}
            </label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.flightNumber}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">
              {fields.seatNumber}
            </label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.seatNumber}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-4 control-label">
              {fields.boardingTime}
            </label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && instance.boardingDateTime
                  ? dateTimeFormat(instance.boardingDateTime)
                  : '-'}
              </p>
            </div>
          </div>
          {instance.userInfo && (
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {fields.checkedInUser}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {get(instance, 'userInfo.displayName')} - (
                  {get(instance, 'userInfo.username')})
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="col-sm-5">
          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.service}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance &&
                  instance.service &&
                  `${instance.service.class} ${instance.service.name}`}
              </p>
            </div>
          </div>

          <div className="col-sm-12 ">
            <div className="form-group col-sm-4">
              <label className="col-sm-4 control-label">
                {fields.guestPaxAdult}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {numberFormat(get(instance, 'serviceInfo.guestPaxAdult'))}
                </p>
              </div>
            </div>
            <div className="form-group col-sm-4">
              <label className="col-sm-4 control-label">
                {fields.guestPaxChild}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {numberFormat(get(instance, 'serviceInfo.guestPaxChild'))}
                </p>
              </div>
            </div>
            <div className="form-group col-sm-4">
              <label className="col-sm-4 control-label">
                {fields.guestPaxInfant}
              </label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {numberFormat(get(instance, 'serviceInfo.guestPaxInfant'))}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">
              {fields.luggageAmount}
            </label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance && numberFormat(instance.luggageAmount)}
              </p>
            </div>
          </div>

          <div className="col-sm-12 ">
            <div className="form-group col-sm-6">
              <label className="col-sm-4 control-label">{fields.inTime}</label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance &&
                  instance.serviceInfo &&
                  instance.serviceInfo.inTime
                    ? dateTimeFormat(instance.serviceInfo.inTime)
                    : '-'}
                </p>
              </div>
            </div>
            <div className="form-group col-sm-6">
              <label className="col-sm-4 control-label">{fields.outTime}</label>
              <div className="col-sm-8">
                <p className="form-control-static">
                  {instance &&
                  instance.serviceInfo &&
                  instance.serviceInfo.outTime
                    ? dateTimeFormat(instance.serviceInfo.outTime)
                    : '-'}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.duration}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance &&
                instance.serviceInfo &&
                instance.serviceInfo.duration
                  ? durationFormat(instance.serviceInfo.duration)
                  : '-'}{' '}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.noteType}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance &&
                  instance.serviceInfo &&
                  noteTypeFormat(instance.serviceInfo.noteType)}
              </p>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-4 control-label">{fields.note}</label>
            <div className="col-sm-8">
              <p className="form-control-static">
                {instance &&
                  instance.serviceInfo &&
                  optionalTextFormat(instance.serviceInfo.note)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

let ServiceTxnDetailPopupContainer = withDetailPopup(
  ServiceTxnDetailPopup,
  () => ({
    header: I18n.t('serviceTxnPage.detail.header'),
    customClass: 'modal-reservation',
  }),
)

export default connect(
  (state) => ({ instance: get(state, 'fetch.serviceTxn.single') }),
  { fetchInstance: fetchServiceTxn },
)(ServiceTxnDetailPopupContainer)
