import React from 'react'
import {
  Modal,
  Row,
  Col,
  Button,
  FormControl,
  FormGroup,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap'
import { Formik } from 'formik'
import { compose, withStateHandlers } from 'recompose'
import { I18n } from 'react-redux-i18n'
import { toastr } from 'react-redux-toastr'

export const PrintSelectionModalAction = compose(
  withStateHandlers(() => ({ isShow: false }), {
    showModal: () => () => ({ isShow: true }),
    onClose: () => () => ({ isShow: false }),
  }),
)(({
  children,
  showModal,
  isShow,
  onClose,
  onPrint,
  totalPage = 0,
  limitRange,
}) => {
  return (
    <div>
      <Formik
        initialValues={{ fromSeq: '', toSeq: '' }}
        onSubmit={(values, actions) => {
          if (values.toSeq - values.fromSeq > limitRange) {
            toastr.error(
              'Error',
              `seq range cannot be over than ${limitRange} page`,
            )
            return
          }
          if (onPrint) onPrint(values)
          onClose()
        }}
        validate={(values) => {
          return removeNullAttribute({
            fromSeq:
              values.fromSeq > 0 && values.fromSeq < values.toSeq
                ? null
                : 'Error',
            toSeq:
              values.toSeq <= totalPage && values.fromSeq < values.toSeq
                ? null
                : 'Error',
          })
        }}
        render={(props) => (
          <PrintSelectionModal isShow={isShow} onClose={onClose} form={props} />
        )}
      />
      {children(showModal)}
    </div>
  )
})

const PrintSelectionModal = ({
  isShow,
  onClose,
  form: { values, errors, handleSubmit, handleBlur, handleChange, submitForm },
}) => (
  <form onSubmit={handleSubmit}>
    <Modal show={isShow} onHide={onClose} backdrop={false} bsSize="small">
      <Modal.Header closeButton>
        <Modal.Title>
          {I18n.t('serviceFormPage.printSelection.header')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>
                {I18n.t('serviceFormPage.printSelection.from')}
              </ControlLabel>
              <FormControl
                name="fromSeq"
                type="number"
                step="1"
                value={values.fromSeq}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.fromSeq && <HelpBlock>{errors.fromSeq}</HelpBlock>}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <ControlLabel>
                {I18n.t('serviceFormPage.printSelection.to')}
              </ControlLabel>
              <FormControl
                name="toSeq"
                type="number"
                step="1"
                value={values.toSeq}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.toSeq && <HelpBlock>{errors.toSeq}</HelpBlock>}
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} bsStyle="default">
          {I18n.t('action.cancel')}
        </Button>
        <Button onClick={submitForm} bsStyle="primary">
          {I18n.t('action.print')}
        </Button>
      </Modal.Footer>
    </Modal>
  </form>
)

function removeNullAttribute(obj) {
  Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key])
  return obj
}
