import React from 'react'
import { FormControl } from 'react-bootstrap'

const Option = ({ options, emptyLabel, ...props }) => (
  <FormControl componentClass="select" {...props} value={props.value || ''}>
    {emptyLabel ? <option value="">{emptyLabel}</option> : null}
    {options &&
      options.map((option) => (
        <option key={`${option.value}_${option.label}`} value={option.value}>
          {option.label}
        </option>
      ))}
  </FormControl>
)

export default Option
