import React from 'react'
import { connect } from 'react-redux'
import { submit, stopSubmit } from 'redux-form'
import { shallowEqual } from 'recompose'
import _ from 'lodash'

import VoucherBookForm from './VoucherBookForm'
import {
  fetchVoucherBook,
  createVoucherBook,
  updateVoucherBook,
  clearVoucherBook,
} from '../../actions/remote_api'
import { formMode } from '../form/index'
import { voucherStatus } from '../../services/voucherBook'

class VoucherBookFormPage extends React.Component {
  formId = 'voucherBook'
  state = { formData: {} }

  componentDidMount() {
    const { fetchVoucherBook, id, clearVoucherBook } = this.props

    clearVoucherBook()
    if (id !== undefined) {
      fetchVoucherBook(id)
    }
  }

  componentWillUnmount() {
    this.props.clearVoucherBook()
  }

  componentWillReceiveProps(nextProps) {
    if (!shallowEqual(nextProps.voucherBook, this.props.voucherBook)) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.voucherBook },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = (formData) => {
    const { createVoucherBook, updateVoucherBook, id } = this.props
    const voucherBook = formData
    if (id === undefined)
      createVoucherBook(voucherBook, { onResponse: this.handleResponse })
    else updateVoucherBook(voucherBook, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = (e) => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  isLocked = () =>
    _.get(this.props, 'voucherBook.status') === voucherStatus.valid

  render() {
    return (
      <form onSubmit={this.submit}>
        <VoucherBookForm
          formMode={formMode(this.props.id)}
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          enableReinitialize
          isLocked={this.isLocked()}
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { voucherBook }, ...state }) => ({
    voucherBook: voucherBook ? voucherBook.single : {},
    errors: voucherBook ? voucherBook.singleErrors : null,
  }),
  {
    createVoucherBook,
    updateVoucherBook,
    fetchVoucherBook,
    clearVoucherBook,
    submit,
    stopSubmit,
  },
)(VoucherBookFormPage)
