import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'

import { fetchAirlines, clearAirline } from '../../actions/remote_api'
import SearchSelect from '../../modules/select/SearchSelect'

class AirlineSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId="airlineOptions"
      />
    )
  }
}

const createQuery = (searchText) => ({
  q: searchText ? searchText.toLowerCase() : undefined,
})

const defaultValueQuery = (initialId) => ({
  _id: initialId,
})

const createLabel = (a) => `${a.name} (${a.code})`

export default connect(null, {
  fetchOptions: fetchAirlines,
  clearOptions: clearAirline,
})(AirlineSearchSelect)
