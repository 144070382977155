import React from 'react'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { debounce, memoize, get } from 'lodash'

import { fetchInvoices, deleteInvoice } from '../../actions/remote_api'
import {
  AllInvoiceTable,
  PendingInvoiceTable,
  DraftInvoiceTable,
} from './InvoiceTable'
import { compose } from 'recompose'
import { invoiceStatus } from '../../services/invoice'
import { withInvoicePayModal } from './withInvoicePayModal'
import { escape } from '../../utils/regex'

const createInvoiceTableContainer =
  ({ defaultQuery = {}, headerI18nKey }) =>
  (Table) =>
    class InvoiceTableContainer extends React.Component {
      currentFilter = undefined

      componentDidMount() {
        this.handleDataChange = debounce(this.handleDataChange, 300)
      }

      refetchWithLastFilter = () => {
        if (this.currentFilter) {
          this.handleDataChange(this.currentFilter)
        }
      }

      handleDataChange = (filter) => {
        this.props.fetchInvoices({
          page: filter.currentPage,
          limit: filter.sizePerPage,
          sort: filter.sort,
          order: filter.order,
          ...defaultQuery,
          ...this.createQueryFilter(filter.query),
        })
        this.currentFilter = filter
      }

      createQueryFilter = (filter) => {
        if (!filter) return undefined

        const queryfilter = {}
        if (filter.code) queryfilter.code_like = escape(filter.code)

        if (filter.debtorId) queryfilter.debtorId = filter.debtorId

        if (filter.status) queryfilter.status = filter.status

        return queryfilter
      }

      createOnProcess = memoize((id) =>
        debounce(() => {
          this.props.processInvoice(id)
        }, 200),
      )

      createHandlePay = memoize((id) => () => {
        const invoice = this.props.instances.find((i) => i._id === id)
        if (invoice)
          this.props.showInvoicePayModal(invoice, this.refetchWithLastFilter)
      })

      render() {
        return (
          <Table
            {...this.props}
            headerI18nKey={headerI18nKey}
            onDataChange={this.handleDataChange}
            createHandlePay={this.createHandlePay}
          />
        )
      }
    }

const withInvoiceTableContainer = ({ headerI18nKey, defaultQuery }) =>
  compose(
    connect(
      ({ fetch }) => ({
        isLoading: get(fetch, 'invoice.isLoading.list', false),
        instances: get(fetch, 'invoice.list', []),
        totalCount: get(fetch, 'invoice.totalCount'),
      }),
      {
        fetchInvoices,
        deleteInstance: deleteInvoice,
      },
    ),
    withInvoicePayModal,
    createInvoiceTableContainer({
      headerI18nKey,
      defaultQuery,
    }),
  )

export const AllInvoiceTableContainer = withInvoiceTableContainer({
  headerI18nKey: undefined,
})(AllInvoiceTable)

export const DraftInvoiceTableContainer = withInvoiceTableContainer({
  headerI18nKey: 'invoicePage.draftTable.header',
  defaultQuery: {
    status: invoiceStatus.draft,
  },
})(DraftInvoiceTable)

export const PendingInvoiceTableContainer = withInvoiceTableContainer({
  headerI18nKey: 'invoicePage.pendingTable.header',
  defaultQuery: {
    status: invoiceStatus.pending,
  },
})(PendingInvoiceTable)
