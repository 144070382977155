import React from 'react'
import { Panel, Row, Col, FormControl, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import InvoiceProfileOption from './InvoiceProfileOption'
import styledComponents from 'styled-components'

export default ({ isShow, filter, onFilterChange, onClearFilter }) => {
  const fieldsLabel = I18n.t('debtorPage.fields')
  return (
    <Panel collapsible expanded={isShow}>
      <FilterRow>
        <Col md={4}>
          <FormControl
            type="text"
            value={filter.name || ''}
            onChange={(e) => onFilterChange('name', e.currentTarget.value)}
            placeholder={fieldsLabel.name}
          />
        </Col>
        <Col md={4}>
          <FormControl
            type="text"
            value={filter.taxId || ''}
            onChange={(e) => onFilterChange('taxId', e.currentTarget.value)}
            placeholder={fieldsLabel.taxId}
          />
        </Col>
        <Col md={2} />
        <Col md={2}>
          <Button className="pull-right" onClick={onClearFilter}>
            <span className="glyphicon glyphicon-trash" /> Clear Filter
          </Button>
        </Col>
      </FilterRow>
      <FilterRow>
        <Col md={6}>
          <InvoiceProfileOption
            value={filter.invoiceProfile}
            onChange={(e) => onFilterChange('invoiceProfile', e.target.value)}
            placeholder={fieldsLabel.invoiceProfile}
            emptyLabel="Select Profile"
          />
        </Col>
      </FilterRow>
      <FilterRow>
        <Col md={6}>
          <FormControl
            componentClass="textarea"
            value={filter.address || ''}
            onChange={(e) => onFilterChange('address', e.currentTarget.value)}
            placeholder={fieldsLabel.address}
          />
        </Col>
      </FilterRow>
    </Panel>
  )
}

const FilterRow = styledComponents(Row)`
  margin-bottom: 16px;
`
