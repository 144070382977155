import reservableMiddlewareCreator from '../middlewares/reservable'
import { formMode } from '../components/form'
import { fetchReservable, clearReservable } from '../actions/remote_api'
import createReservationForm from '../reducers/reservationForm'

export const reservableMiddleware = (getServiceDateTime) =>
  reservableMiddlewareCreator({
    formId: 'reservation',
    fetchReservable,
    fetchId: 'reservable',
    clearReservable,
    observedFields: [
      'guestPaxAdult',
      'guestPaxChild',
      'guestPaxInfant',
      'product',
      'payerType',
      'agent',
      'payerVoucherBook',
      'serviceDateTime',
      'flightDateTime',
      'isCharged',
    ],
    formMode,
    reservationFormReducer: createReservationForm({
      getServiceDateTime,
    }),
  })
