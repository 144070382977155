import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { get } from 'lodash'
import { fetchPricingContractTemplates } from '../../actions/remote_api'

class PricingContractTemplateSelectField extends React.Component {
  componentDidMount() {
    const { fetchPricingContractTemplates, contractorType } = this.props
    fetchPricingContractTemplates({ contractorType })
  }

  getOptions = () => {
    return this.props.pricingContractTemplate.map((pricingContract) => ({
      value: pricingContract.id,
      label: pricingContract.name,
    }))
  }

  handleChange = (value) => {
    this.props.onChange(value, this.findPricingContractForValue(value))
  }

  findPricingContractForValue = (value) =>
    this.props.pricingContractTemplate.find(
      (pricingContract) => pricingContract._id === value,
    )

  render() {
    const options = this.getOptions()
    return (
      <Select
        {...this.props}
        onChange={this.handleChange}
        options={options}
        simpleValue
      />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    pricingContractTemplate: get(fetch, 'pricingContractTemplate.list', []),
  }),
  { fetchPricingContractTemplates },
)(PricingContractTemplateSelectField)
