import React from 'react'
import { compose, withStateHandlers } from 'recompose'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'

import { I18n } from 'react-redux-i18n'
import { priceFormat } from '../../services/currency'
import { invoiceProfiles } from '../../services/invoiceProfiles'
import { dateFormat } from '../table/index'
import { debtTypes } from '../../services/debtType'

const withModal =
  (Modal) =>
  (Component) =>
  ({ isShow, showDebtModal, onHide, ...props }) => (
    <div>
      <Component
        {...props}
        showDebtModal={showDebtModal}
        isDebtModalAvailable={props.invoice && props.invoice.debts}
      />
      <Modal isShow={isShow} onHide={onHide} invoice={props.invoice} />
    </div>
  )

const ShowDebtModal = ({ isShow, onHide, invoice }) => {
  if (!invoice || !invoice.debts) return null
  const debts = invoice.debts
  return (
    <Modal show={isShow} onHide={onHide} bsSize="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {I18n.t('invoicePage.originalDebtModal.header')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
              <th>{I18n.t('invoicePage.originalDebtModal.info')}</th>
              <th>{I18n.t('invoicePage.originalDebtModal.price')}</th>
              <th>{I18n.t('invoicePage.originalDebtModal.type')}</th>
              {isShowInvoiceProfile(invoice) && (
                <th>{I18n.t('invoicePage.originalDebtModal.type')}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {debts.map((debt) => (
              <tr key={debt._id}>
                <td>{renderDebtInfo(debt)}</td>
                <td>{priceFormat(debt.price)}</td>
                <td>{I18n.t('debtType.' + debt.type)}</td>
                {isShowInvoiceProfile(invoice) && (
                  <td>{I18n.t('invoiceProfile.' + debt.invoiceProfile)}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  )
}

const renderDebtInfo = (debt) => {
  switch (debt.type) {
    case debtTypes.reservation:
      return renderReservationDebtInfo(debt)
    case debtTypes.voucherBook:
      return renderVoucherBookDebtInfo(debt)
    case debtTypes.topup:
      return renderTopupDebtInfo(debt)
    default:
      throw Error('renderDebtInfo not support ' + debt.type)
  }
}

const renderReservationDebtInfo = (debt) => {
  switch (debt.invoiceProfile) {
    case invoiceProfiles.productAndPrice:
      return (
        <div>
          <dl className="dl-horizontal">
            <dt>{I18n.t('invoicePage.originalDebtModal.product')}</dt>
            <dd>{debt.info.product.name}</dd>
          </dl>
        </div>
      )
    case invoiceProfiles.productAndPriceAndFlight:
      return (
        <div>
          <dl className="dl-horizontal">
            <dt>{I18n.t('invoicePage.originalDebtModal.product')}</dt>
            <dd>{debt.info.product.name}</dd>
          </dl>
          <dl className="dl-horizontal">
            <dt>{I18n.t('invoicePage.originalDebtModal.flightName')}</dt>
            <dd>{debt.info.flightName}</dd>
          </dl>
        </div>
      )
    case invoiceProfiles.productAndPriceAndFlightAndDay:
      return (
        <div>
          <dl className="dl-horizontal">
            <dt>{I18n.t('invoicePage.originalDebtModal.product')}</dt>
            <dd>{debt.info.product.name}</dd>
          </dl>
          <dl className="dl-horizontal">
            <dt>{I18n.t('invoicePage.originalDebtModal.flightNumber')}</dt>
            <dd>{debt.info.flightNumber}</dd>
          </dl>
          <dl className="dl-horizontal">
            <dt>{I18n.t('invoicePage.originalDebtModal.flightDateTime')}</dt>
            <dd>{dateFormat(debt.info.flightDateTime)}</dd>
          </dl>
        </div>
      )
    default:
      throw Error('debt type ' + debt.type + ' is not support')
  }
}

const renderVoucherBookDebtInfo = (debt) => (
  <div>
    {_.map(_.get(debt, 'info.products'), (product, index) => (
      <dl className="dl-horizontal" key={product._id}>
        <dt>
          {I18n.t('invoicePage.originalDebtModal.product')} #{index + 1}
        </dt>
        <dd>{product.name}</dd>
      </dl>
    ))}
  </div>
)

const renderTopupDebtInfo = (debt) => (
  <div>
    <dl className="dl-horizontal">
      <dt>{I18n.t('invoicePage.originalDebtModal.agent')}</dt>
      <dd>{_.get(debt, 'info.agent.name')}</dd>
    </dl>
    <dl className="dl-horizontal">
      <dt>{I18n.t('invoicePage.originalDebtModal.issuedDate')}</dt>
      <dd>{dateFormat(_.get(debt, 'info.issuedDate'))}</dd>
    </dl>
  </div>
)

const toggleShow = (isShow) => ({ isShow })

const isShowInvoiceProfile = (invoice) => !_.isEmpty(invoice.reservationIds)

export const withShowDebtModal = compose(
  withStateHandlers(() => ({ isShow: false }), {
    showDebtModal: () => () => toggleShow(true),
    onHide: () => () => toggleShow(false),
  }),
  withModal(ShowDebtModal),
)
