import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Label, Button } from 'react-bootstrap'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'

import { withDetailPopupProvider } from '../detailPopup'
import { withTableConfigs, timeFormat, dateTimeFormat } from '../table'
import ServiceTxnDetailPopup from './ServiceTxnDetailPopup'
import ServiceTxnTableFilterPanel from './ServiceTxnTableFilterPanel'
import Icon from 'react-icons-kit'
import { plane } from 'react-icons-kit/ionicons'
import { ic_event_seat } from 'react-icons-kit/md'
import Loader from '../Loader'
import TableLoading from '../table/TableLoading'

let ServiceTxnTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  settings,
  options,
  renderFilterPanel,
  renderToolbar,
  isLoading,
}) => {
  const { fields, table } = I18n.t('serviceTxnPage')
  const customSettings = { ...defaultSettings, ...settings }
  const customOptions = { ...defaultOptions, ...options }
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>

      {renderFilterPanel}
      {renderToolbar}
      <Loader loading={<TableLoading />} isLoading={isLoading}>
        <BootstrapTable {...customSettings} options={customOptions}>
          <TableHeaderColumn dataField="customerName" dataFormat={detailLink}>
            {fields.mainPax}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="flightNumber"
            width="180px"
            dataSort
            dataFormat={renderFlightInfo}
          >
            {fields.flightNumber}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="service._id"
            dataFormat={renderServiceInfo}
            dataSort
          >
            {fields.service}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="170px"
            dataField="serviceDateTime"
            dataFormat={dateTimeFormat}
            dataSort
          >
            {fields.serviceDateTime}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="70px"
            dataField="seq"
            dataFormat={renderSeqButton}
          >
            {fields.seq}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="70px"
            dataField="serviceInfo.inTime"
            dataFormat={renderServiceTxnInfo('inTime', timeFormat)}
            dataSort
          >
            {fields.serviceInfo.inTime}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="70px"
            dataField="serviceInfo.outTime"
            dataFormat={renderServiceTxnInfo('outTime', timeFormat)}
            dataSort
          >
            {fields.serviceInfo.outTime}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="125px"
            dataField="status"
            dataFormat={(status) => status.toUpperCase()}
            dataSort
          >
            {fields.status}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="remark"
            dataFormat={renderServiceRemark}
          >
            {fields.remark}
          </TableHeaderColumn>
          {/* <TableHeaderColumn dataField='edit' editable={false} dataFormat={editButton} width="100px" dataAlign="center" hiddenOnInsert></TableHeaderColumn> */}
        </BootstrapTable>
      </Loader>
    </div>
  )
}

const renderSeqButton = (seq, serviceTxn) => {
  if (!seq) return null

  const state = {
    loadServiceTxn: {
      serviceDateTime: serviceTxn.serviceDateTime.toISOString(),
      seq,
    },
  }
  return (
    <Link to={{ pathname: '/service-form', state }}>
      <Button bsStyle="primary" bsSize="xs">
        {seq}
      </Button>
    </Link>
  )
}

const renderServiceTxnInfo =
  (field, formatter) =>
  (_, { serviceInfo }) => {
    if (!serviceInfo || !serviceInfo[field]) return '-'
    if (formatter && serviceInfo) return formatter(serviceInfo[field])

    return serviceInfo[field]
  }

const renderFlightInfo = (_, serviceInstance) => {
  return (
    <div className="row">
      <div className="col-xs-12">
        <Icon icon={plane} /> {serviceInstance.flightNumber}{' '}
        {serviceInstance.seatNumber && (
          <span>
            <Icon icon={ic_event_seat} /> {serviceInstance.seatNumber}
          </span>
        )}
      </div>
    </div>
  )
}
const renderServiceRemark = (_, serviceInstance) => {
  return (
    <div>
      {serviceInstance.serviceInfo && serviceInstance.serviceInfo.note && (
        <div className="row">
          <div className="col-xs-12">
            <Label
              bsStyle={
                serviceInstance.serviceInfo.noteType === 'complain'
                  ? 'danger'
                  : 'success'
              }
            >
              {serviceInstance.serviceInfo.noteType
                ? serviceInstance.serviceInfo.noteType.toUpperCase()
                : 'CUSTOMER FEEDBACK'}
            </Label>
            <br />
            {serviceInstance.serviceInfo.note}
            <br />
          </div>
        </div>
      )}

      {serviceInstance.remark && (
        <div className="row">
          <div className="col-xs-12">
            <Label bsStyle="default">REMARK</Label>
            <br />
            {serviceInstance.remark}
          </div>
        </div>
      )}

      {serviceInstance.guestOf && (
        <div className="row">
          <div className="col-xs-12">
            <p>
              <Label bsStyle="primary">GUEST OF</Label>
              <br /> {serviceInstance.guestOf.customerFirstName}{' '}
              {serviceInstance.guestOf.customerLastName}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
const renderServiceInfo = (_, { service }) => {
  return `${service.displayName}`
}

ServiceTxnTable = withTableConfigs('serviceTxn')(
  ServiceTxnTable,
  ServiceTxnTableFilterPanel,
)
ServiceTxnTable = withDetailPopupProvider(
  ServiceTxnTable,
  ServiceTxnDetailPopup,
)

export default ServiceTxnTable
