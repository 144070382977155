import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, memoize, get, isNil, flow } from 'lodash'
import { Button } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { play } from 'react-icons-kit/ionicons'
import { interval } from 'rxjs'
import { filter } from 'rxjs/operators'
import { lifecycle } from 'recompose'
import moment from 'moment'

import {
  fetchInvoiceIntervals,
  deleteInvoiceInterval,
  processInvoiceInterval,
  fetchProcessingInvoiceInterval,
  restartInvoiceIntervalProcess,
  cancelInvoiceIntervalProcess,
} from '../../actions/remote_api'
import { withDetailPopupProvider } from '../detailPopup'
import {
  withTableConfigs,
  withDeleteModal,
  dateTimeFormat,
  dateFormat,
} from '../table'
import InvoiceIntervalDetailPopup from './InvoiceIntervalDetailPopup'
import InvoiceIntervalTableFilterPanel from './InvoiceIntervalTableFilterPanel'
import {
  invoiceIntervalStatus,
  isShowRecoveryControlForInvoiceInterval,
} from '../../services/invoiceInterval'
import ProcessingInvoiceIntervalPanel from './ProcessingInvoiceIntervalPanel'
import config from '../../modules/config'
import TableLoading from '../table/TableLoading'
import Loader from '../Loader'

class InvoiceIntervalTableContainer extends React.Component {
  $intervalDisposal = null

  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
    this.props.fetchProcessingInvoiceInterval()

    this.$intervalDisposal = interval(10000)
      .pipe(filter(() => !isNil(this.props.processingInvoiceInterval)))
      .subscribe(() => {
        this.props.fetchProcessingInvoiceInterval()
      })
  }

  componentWillUnmount() {
    if (this.$intervalDisposal) this.$intervalDisposal.unsubscribe()
  }

  handleDataChange = (filter) => {
    this.props.fetchInvoiceIntervals({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = (filter) => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.status) queryfilter.status = filter.status

    return queryfilter
  }

  createOnProcess = memoize((id) =>
    debounce(() => {
      this.props.processInvoiceInterval(id, {
        onResponse: this.props.fetchProcessingInvoiceInterval,
      })
    }, 200),
  )

  createOnRestart = memoize((id) =>
    debounce(() => {
      this.props.restartInvoiceIntervalProcess(id, {
        onResponse: this.props.fetchProcessingInvoiceInterval,
      })
    }, 200),
  )

  handleCancelProcess = debounce(() => {
    const processingInvoiceIntervalId = get(
      this.props.processingInvoiceInterval,
      '_id',
    )
    if (processingInvoiceIntervalId)
      this.props.cancelInvoiceIntervalProcess(processingInvoiceIntervalId, {
        onResponse: this.props.fetchProcessingInvoiceInterval,
      })
  }, 500)

  handleRestartProcess = debounce(() => {
    const processingInvoiceIntervalId = get(
      this.props.processingInvoiceInterval,
      '_id',
    )
    if (processingInvoiceIntervalId)
      this.props.restartInvoiceIntervalProcess(processingInvoiceIntervalId, {
        onResponse: this.props.fetchProcessingInvoiceInterval,
      })
  }, 500)

  isProcessingInvoiceInterval = (id) =>
    get(this.props.processingInvoiceInterval, '_id') === id

  isShowRecoveryControl = () =>
    isShowRecoveryControlForInvoiceInterval(
      this.props.processingInvoiceInterval,
      moment(),
      config.invoiceInterval.process.enableRecoveryControlAfterProcessRunFor,
    )

  render() {
    return (
      <InvoiceIntervalTable
        {...this.props}
        onDataChange={this.handleDataChange}
        createOnProcess={this.createOnProcess}
        createOnRestart={this.createOnRestart}
        cancelProcess={this.handleCancelProcess}
        restartProcess={this.handleRestartProcess}
        isProcessingInvoiceInterval={this.isProcessingInvoiceInterval}
        isShowRecoveryControl={this.isShowRecoveryControl()}
      />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    isLoading: get(fetch, 'invoiceInterval.isLoading.list', false),
    instances: get(fetch, 'invoiceInterval.list', []),
    totalCount: get(fetch, 'invoiceInterval.totalCount'),
    processingInvoiceInterval: get(
      fetch,
      'processingInvoiceInterval.single.processingInvoiceInterval',
    ),
  }),
  {
    fetchInvoiceIntervals,
    deleteInstance: deleteInvoiceInterval,
    processInvoiceInterval,
    fetchProcessingInvoiceInterval,
    cancelInvoiceIntervalProcess,
    restartInvoiceIntervalProcess,
  },
)(InvoiceIntervalTableContainer)

let InvoiceIntervalTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
  createOnProcess,
  createOnRestart,
  processingInvoiceInterval,
  cancelProcess,
  restartProcess,
  isProcessingInvoiceInterval,
  isShowRecoveryControl,
  isLoading,
  ...props
}) => {
  const { fields, table } = I18n.t('invoiceIntervalPage')
  const toInvoiceIntervalStatus = toInvoiceIntervalStatusCreator(
    isProcessingInvoiceInterval,
  )
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      <ProcessingInvoiceIntervalPanel
        processingInvoiceInterval={processingInvoiceInterval}
        cancelProcess={cancelProcess}
        restartProcess={restartProcess}
        isShowRecoveryControl={isShowRecoveryControl}
      />
      {renderFilterPanel}
      {renderToolbar}
      <Loader loading={<TableLoading />} isLoading={isLoading}>
        <BootstrapTable {...defaultSettings} options={defaultOptions}>
          <TableHeaderColumn
            width="10%"
            dataField="startDate"
            dataSort
            dataFormat={dateFormatLink(detailLink)}
          >
            {fields.startDate}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="endDate"
            dataSort
            dataFormat={dateFormatLink(detailLink)}
          >
            {fields.endDate}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="txnCount"
            dataFormat={(txnCount, invoiceInterval) =>
              invoiceInterval.status === invoiceIntervalStatus.processing
                ? '?'
                : txnCount
            }
            dataSort
            dataAlign="right"
          >
            {fields.txnCount}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="txnRemaining"
            dataSort
            dataAlign="right"
          >
            {fields.txnRemaining}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="100px"
            dataField="status"
            dataFormat={formatStatus(toInvoiceIntervalStatus)}
            dataAlign="center"
          >
            {fields.status}
          </TableHeaderColumn>
          {/* <TableHeaderColumn
          width="10%"
          dataField="log"
          dataFormat={log => dateTimeFormat(log.lastUpdatedDateTime)}
        >
          {fields.lastUpdatedDateTime}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10%"
          dataField="log"
          dataFormat={log => dateTimeFormat(log.createdDateTime)}
        >
          {fields.createdDateTime}
        </TableHeaderColumn> */}
          <TableHeaderColumn
            width="10%"
            dataField="processInfo"
            dataFormat={(processInfo) =>
              dateTimeFormat(get(processInfo, 'startDateTime'))
            }
          >
            {fields.processInfo.startDateTime}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="processInfo"
            dataFormat={(processInfo) =>
              dateTimeFormat(get(processInfo, 'finishDateTime'))
            }
          >
            {fields.processInfo.finishDateTime}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataField="processInfo"
            dataFormat={(processInfo) =>
              dateTimeFormat(get(processInfo, 'lastUpdateDateTime'))
            }
          >
            {fields.processInfo.lastUpdateDateTime}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="edit"
            editable={false}
            dataFormat={actionButtons(
              editButton,
              createOnProcess,
              createOnRestart,
              toInvoiceIntervalStatus,
              processingInvoiceInterval,
            )}
            width="100px"
            dataAlign="center"
            hiddenOnInsert
          />
        </BootstrapTable>
      </Loader>
    </div>
  )
}

const formatStatus = (toInvoiceIntervalStatus) => (status, invoiceInterval) =>
  withUpperCase(toInvoiceIntervalStatus(invoiceInterval))

const toInvoiceIntervalStatusCreator =
  (isProcessingInvoiceInterval) => (invoiceInterval) =>
    flow(withProcessingStatus(invoiceInterval, isProcessingInvoiceInterval))(
      invoiceInterval.status,
    )

const withProcessingStatus =
  (invoiceInterval, isProcessingInvoiceInterval) => (status) =>
    isProcessingInvoiceInterval(invoiceInterval._id)
      ? invoiceIntervalStatus.processing
      : status
const withUpperCase = (value) => value.toUpperCase()

const dateFormatLink = (detailLink) => (date, instance) =>
  detailLink(dateFormat(date), instance)

const actionButtons =
  (
    editButton,
    createOnProcessHandler,
    createOnRestartHandler,
    toInvoiceIntervalStatus,
    processingInvoiceInterval,
  ) =>
  (_, invoiceInterval) => (
    <div className="btn-group-vertical">
      {editButton(_, invoiceInterval, {
        disabled:
          toInvoiceIntervalStatus(invoiceInterval) !==
          invoiceIntervalStatus.draft,
      })}
      <Button
        bsStyle="primary"
        onClick={createOnProcessHandler(invoiceInterval._id)}
        disabled={
          toInvoiceIntervalStatus(invoiceInterval) ===
            invoiceIntervalStatus.processing ||
          !isNil(processingInvoiceInterval)
        }
      >
        <Icon icon={play} /> {I18n.t('action.process')}
      </Button>
      <Button
        bsStyle="primary"
        onClick={createOnRestartHandler(invoiceInterval._id)}
        disabled={
          toInvoiceIntervalStatus(invoiceInterval) ===
            invoiceIntervalStatus.processing ||
          !isNil(processingInvoiceInterval)
        }
      >
        <Icon icon={play} /> {I18n.t('action.restart')}
      </Button>
    </div>
  )

InvoiceIntervalTable = lifecycle({
  componentWillReceiveProps(nextProps) {
    if (
      this.props.processingInvoiceInterval &&
      !nextProps.processingInvoiceInterval
    ) {
      this.props.refetch()
    }
  },
})(InvoiceIntervalTable)
InvoiceIntervalTable = withTableConfigs('invoiceInterval')(
  InvoiceIntervalTable,
  InvoiceIntervalTableFilterPanel,
)
InvoiceIntervalTable = withDetailPopupProvider(
  InvoiceIntervalTable,
  InvoiceIntervalDetailPopup,
)
InvoiceIntervalTable = withDeleteModal(InvoiceIntervalTable)
