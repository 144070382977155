import React from 'react'
import { connect } from 'react-redux'
import { submit, stopSubmit, getFormValues } from 'redux-form'
import { pick, get } from 'lodash'
import { shallowEqual } from 'recompose'

import PricingContractGeneralForm from './PricingContractGeneralForm'
import {
  fetchPricingContract,
  createPricingContract,
  updatePricingContract,
  clearPricingContract,
} from '../../actions/remote_api'
import { createFetchId, getFetchPricingContract } from './index'

const formId = 'pricingContract'

class PricingContractGeneralFormPage extends React.Component {
  state = {
    formData: { isTemplate: false, contractorType: this.props.contractorType },
  }

  componentDidMount() {
    const { fetchPricingContract, id, clearPricingContract } = this.props

    clearPricingContract('single', {
      fetchId: createFetchId(this.props.contractorType),
    })
    if (id !== undefined) {
      fetchPricingContract(id, {
        fetchId: createFetchId(this.props.contractorType),
      })
    }

    if (this.props.pricingContractTemplateId) {
      this.props.fetchPricingContract(this.props.pricingContractTemplateId, {
        fetchId: 'pricingContractTemplate',
      })
    }
  }

  componentWillUnmount() {
    this.props.clearPricingContract('single', {
      fetchId: createFetchId(this.props.contractorType),
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pricingContract) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.pricingContract },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }

    if (
      nextProps.pricingContractTemplate &&
      this.props.pricingContractTemplateId &&
      !shallowEqual(
        nextProps.pricingContractTemplate,
        this.props.pricingContractTemplate,
      )
    ) {
      const templateData = pick(nextProps.pricingContractTemplate, [
        'name',
        'contractorType',
        'agentId',
        'airlineId',
        'cardId',
        'startDate',
        'endDate',
        'prices',
      ])
      templateData.prices = templateData.prices.map(
        ({ _id, id, ...priceTemplate }) => priceTemplate,
      )

      this.setState({
        formData: {
          ...this.state.formData,
          ...templateData,
        },
      })
    }
  }

  handleSubmit = (formData) => {
    const { createPricingContract, updatePricingContract, id } = this.props
    const pricingContract = formData
    const fetchId = createFetchId(this.state.formData.contractorType)
    if (id === undefined)
      createPricingContract(pricingContract, {
        onResponse: this.handleResponse,
        fetchId,
      })
    else
      updatePricingContract(pricingContract, {
        onResponse: this.handleResponse,
        fetchId,
      })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit(this.state.formData.contractorType)
  }

  submit = (e) => {
    this.props.submit(formId)
    e.preventDefault()
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <PricingContractGeneralForm
          form={formId}
          initialValues={this.state.formData}
          formValues={this.props.formValues}
          onSubmit={this.handleSubmit}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  (state, { contractorType }) => {
    const pricingContract = getFetchPricingContract(state, contractorType)
    return {
      pricingContract: get(pricingContract, 'single'),
      errors: get(pricingContract, 'singleErrors'),
      formValues: getFormValues(formId)(state),
      pricingContractTemplate: get(
        state,
        'fetch.pricingContractTemplate.single',
      ),
    }
  },
  {
    createPricingContract,
    updatePricingContract,
    fetchPricingContract,
    clearPricingContract,
    submit,
    stopSubmit,
  },
)(PricingContractGeneralFormPage)
