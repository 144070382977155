import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { fetchVoucherBook } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { dateFormat } from '../table/index'
import { priceFormat } from '../../services/currency'
import VoucherBookDetailRightBarButtons from './VoucherBookDetailRightBarButtons'

const VoucherBookDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('voucherBookPage')
  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.name}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.name}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">
          {fields.totalUsageAmount}
        </label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance.totalUsageAmount ? instance.totalUsageAmount : '-'}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.price}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance.price ? priceFormat(instance.price) : '-'}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.debtorId}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance.debtor ? instance.debtor.name : '-'}
          </p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.startDate}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && dateFormat(instance.startDate)}
          </p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.endDate}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && dateFormat(instance.endDate)}
          </p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.products}</label>
        <div className="col-sm-8 form-control-static">
          <table className="table table-border">
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {instance.products &&
                instance.products.map((product, index) => (
                  <tr key={index}>
                    <td>{product.name}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  )
}

let VoucherBookDetailPopupContainer = withDetailPopup(
  VoucherBookDetailPopup,
  () => ({ header: I18n.t('voucherBookPage.detail.header') }),
  VoucherBookDetailRightBarButtons,
)

export default connect(
  (state) => ({
    instance: get(state, 'fetch.voucherBook.single'),
  }),
  { fetchInstance: fetchVoucherBook },
)(VoucherBookDetailPopupContainer)
