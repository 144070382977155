import React from 'react'
import Select from 'react-select'

import { withComplexValue } from './withComplexValue'
import TextAreaSelect from '../../components/form/TextAreaSelect'

const ComplexValueSelect = withComplexValue((props) =>
  props.selectStyle === 'textArea' ? (
    <TextAreaSelect {...props} />
  ) : (
    <Select {...props} />
  ),
)

export default ComplexValueSelect
