import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { debounce, get } from 'lodash'

import { fetchStations, deleteStation } from '../../actions/remote_api'
import { makeServiceTitle } from '../'
import { withDetailPopupProvider } from '../detailPopup'
import { withTableConfigs, withDeleteModal } from '../table'
import StationDetailPopup from './StationDetailPopup'
import StationTableFilterPanel from './StationTableFilterPanel'
import { escape } from '../../utils/regex'

class StationTableContainer extends React.Component {
  componentDidMount() {
    this.handleDataChange = debounce(this.handleDataChange, 300)
  }

  handleDataChange = (filter) => {
    this.props.fetchStations({
      page: filter.currentPage,
      limit: filter.sizePerPage,
      sort: filter.sort,
      order: filter.order,
      ...this.createQueryFilter(filter.query),
    })
  }

  createQueryFilter = (filter) => {
    if (!filter) return undefined

    const queryfilter = {}
    if (filter.code) queryfilter.code_like = escape(filter.code)

    if (filter.name) queryfilter.name_like = escape(filter.name)

    return queryfilter
  }

  render() {
    return <StationTable {...this.props} onDataChange={this.handleDataChange} />
  }
}

export default connect(
  ({ fetch }) => ({
    instances: get(fetch, 'station.list', []),
    totalCount: get(fetch, 'station.totalCount'),
  }),
  { fetchStations, deleteInstance: deleteStation },
)(StationTableContainer)

let StationTable = ({
  defaultOptions,
  defaultSettings,
  detailLink,
  editButton,
  renderFilterPanel,
  renderToolbar,
}) => {
  const { fields, table } = I18n.t('stationPage')
  return (
    <div>
      <h1 className="page-header">{table.header}</h1>
      {renderFilterPanel}
      {renderToolbar}
      <BootstrapTable {...defaultSettings} options={defaultOptions}>
        <TableHeaderColumn
          width="5%"
          dataField="code"
          dataSort
          dataFormat={detailLink}
        >
          {fields.code}
        </TableHeaderColumn>
        <TableHeaderColumn width="42%" dataField="name" dataSort>
          {fields.name}
        </TableHeaderColumn>
        <TableHeaderColumn width="8%" dataField="capacity" dataSort>
          {fields.capacity}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="25%"
          dataField="service"
          dataFormat={serviceFormatter}
        >
          {fields.service}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="edit"
          editable={false}
          dataFormat={editButton}
          width="100px"
          dataAlign="center"
          hiddenOnInsert
        />
      </BootstrapTable>
    </div>
  )
}

const serviceFormatter = (service, row) => {
  if (service) return makeServiceTitle(service)
  else return '-'
}

StationTable = withTableConfigs('station')(
  StationTable,
  StationTableFilterPanel,
)
StationTable = withDetailPopupProvider(StationTable, StationDetailPopup)
StationTable = withDeleteModal(StationTable)
