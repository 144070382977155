import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { fetchPricingContract } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import ShowPricingProductTable from './ShowPricingProductTable'
import { dateFormat } from '../table/index'

const PricingContractDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('pricingContractPage')
  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.name}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance && instance.name}</p>
        </div>
      </div>

      {renderContractorInfo(instance)}

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.startDate}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && dateFormat(instance.startDate)}
          </p>
        </div>
      </div>

      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.endDate}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {instance && dateFormat(instance.endDate)}
          </p>
        </div>
      </div>

      <div className="form-group">
        <div className="col-sm-12 form-control-static">
          <ShowPricingProductTable prices={instance.prices} />
        </div>
      </div>
    </form>
  )
}

let PricingContractDetailPopupContainer = withDetailPopup(
  PricingContractDetailPopup,
  () => ({
    header: I18n.t('pricingContractPage.detail.header'),
    customClass: 'modal-lg',
  }),
)

const renderContractorInfo = (pricingContract) => {
  const contractorInfo = getContractorInfo(pricingContract)
  return (
    <div className="form-group">
      <label className="col-sm-4 control-label">{contractorInfo.label}</label>
      <div className="col-sm-8">
        <p className="form-control-static">{contractorInfo.name}</p>
      </div>
    </div>
  )
}

const getContractorInfo = (pricingContract) => {
  switch (pricingContract.contractorType) {
    case 'agent': {
      const { code, name } = pricingContract.agent
      return {
        label: I18n.t('pricingContractPage.fields.contractorType.agent'),
        name: `${name} (${code})`,
      }
    }
    case 'card': {
      const { code, name } = pricingContract.card
      return {
        label: I18n.t('pricingContractPage.fields.contractorType.card'),
        name: `${name} (${code})`,
      }
    }
    case 'airline': {
      const { code, name } = pricingContract.airline
      return {
        label: I18n.t('pricingContractPage.fields.contractorType.airline'),
        name: `${name} (${code})`,
      }
    }
    default:
      return {}
  }
}

export default connect(
  (state) => ({
    instance: get(state, 'fetch.pricingContract.single'),
  }),
  { fetchInstance: fetchPricingContract },
)(PricingContractDetailPopupContainer)
