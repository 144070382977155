import React from 'react'
import { arrayPush, arrayRemove, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import _, { findIndex } from 'lodash'

import PriceProductCheckboxSelectTable from './PriceProductCheckboxSelectTable'
import withSearchProduct from '../product/withSearchProduct'

class PriceProductCheckboxSelectTableContainer extends React.Component {
  componentDidMount() {
    if (!this.props.formId || !this.props.fieldName)
      throw new Error('No formId or fieldName specified')
  }

  handleCheckPriceProduct = (e) => {
    const selectingPriceProductId = e.target.name
    if (e.target.checked) {
      const picingProduct = this.toPriceProduct(
        this.props.productItems.find(
          (product) => product._id === selectingPriceProductId,
        ),
      )
      this.props.arrayPush(
        this.props.formId,
        this.props.fieldName,
        picingProduct,
      )
    } else {
      const targetIndex = findIndex(
        this.props.selectedPriceProducts,
        (priceProduct) => priceProduct.productId === selectingPriceProductId,
      )
      if (targetIndex >= 0)
        this.props.arrayRemove(
          this.props.formId,
          this.props.fieldName,
          targetIndex,
        )
    }
  }

  isChecked = (pricingProduct) => {
    let isChecked = false
    this.props.selectedPriceProducts.forEach((p) => {
      if (p.productId === pricingProduct.productId) isChecked = true
    })
    return isChecked
  }

  getPriceProducts = () => _.map(this.props.productItems, this.toPriceProduct)

  toPriceProduct = (product) => ({
    productId: product._id,
    type: 'fixed',
    product,
  })

  render() {
    return (
      <PriceProductCheckboxSelectTable
        priceProducts={this.getPriceProducts()}
        onCheckPriceProduct={this.handleCheckPriceProduct}
        getIsChecked={this.isChecked}
        onSearchTextChange={this.props.onSearchTextChange}
        isLoading={this.props.isLoading}
      />
    )
  }
}

const ConnectedPriceProductCheckboxSelectTableContainer = compose(
  connect(
    (state, props) => ({
      selectedPriceProducts:
        formValueSelector(props.formId)(state, props.fieldName) || [],
    }),
    {
      arrayRemove,
      arrayPush,
    },
  ),
  withSearchProduct,
)(PriceProductCheckboxSelectTableContainer)

export default ConnectedPriceProductCheckboxSelectTableContainer
