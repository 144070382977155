import React from 'react'
import { reduxForm } from 'redux-form'
import {
  TextField,
  TextAreaField,
  Toolbar,
  SwitchField,
  SelectField,
} from '../form'
import { required, email, validateWith } from '../form/validator'
import { Button, Panel, Row, Col } from 'react-bootstrap'
import _ from 'lodash'

import { I18n } from 'react-redux-i18n'
import { agentTypeOptions } from './agentTypeOptions'
import { agentTypes } from '../../services/agentType'

const validate = (values) => ({
  code: validateWith(values.code, [required]),
  name: validateWith(values.name, [required]),
  phone1: validateWith(values.phone1, [required]),
  email: validateWith(values.email, [required, email]),
  type: validateWith(values.type, [required]),
  accountantEmail: validateWith(values.accountantEmail, [required, email]),
  notifyMinimalBalance:
    values.type === agentTypes.prepaid
      ? validateWith(values.notifyMinimalBalance, [required])
      : undefined,
})

const AgentForm = ({ initialValues, formValues }) => {
  const { form, fields } = I18n.t('agentPage')
  return (
    <div className="container">
      <Toolbar title={initialValues._id ? form.update : form.new}>
        <Button className="btn-primary" type="submit">
          Submit
        </Button>
      </Toolbar>
      <Panel>
        <Row>
          <Col sm={4}>
            <TextField name="code" label={fields.code} addStar={true} />
          </Col>
          <Col sm={8}>
            <TextField name="name" label={fields.name} addStar={true} />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <TextField name="phone1" label={fields.phone1} addStar={true} />
          </Col>
          <Col sm={4}>
            <TextField name="phone2" label={fields.phone2} />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <TextField name="email" label={fields.email} addStar={true} />
          </Col>
          <Col sm={4}>
            <TextField name="email2" label={fields.email2} />
          </Col>
          <Col sm={4}>
            <TextField name="email3" label={fields.email3} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TextAreaField name="address" label={fields.address} rows={4} />
          </Col>
        </Row>
      </Panel>

      <Panel>
        <Row>
          <Col sm={3}>
            <SelectField
              name="type"
              label={fields.type}
              options={agentTypeOptions}
              addStar
            />
          </Col>
          <Col sm={4}>
            <TextField
              name="accountantEmail"
              label={fields.accountantEmail}
              addStar={true}
            />
          </Col>
          {_.get(formValues, 'type') === agentTypes.prepaid && (
            <Col sm={5}>
              <TextField
                name="notifyMinimalBalance"
                label={fields.notifyMinimalBalance}
              />
            </Col>
          )}
        </Row>
      </Panel>

      <Panel>
        <Row>
          <Col sm={3}>
            <SwitchField
              name="isReserveRestricted"
              label={fields.isReserveRestricted}
            />
          </Col>
        </Row>
      </Panel>
    </div>
  )
}

export default reduxForm({
  validate,
})(AgentForm)
