import React from 'react'
import DebtorForm from './DebtorForm'
import { connect } from 'react-redux'
import { /*shallowEqual,*/ compose } from 'recompose'
import { submit, stopSubmit, getFormValues } from 'redux-form'
// import { pick, get } from 'lodash'

import { createFetchId, getDebtor } from './index'
import {
  fetchDebtor,
  updateDebtor,
  clearDebtor,
} from '../../actions/remote_api'

const formId = 'debtor'

class DebtorFormPage extends React.Component {
  state = {
    formData: {
      isTemplate: false,
      debtorType: this.props.debtorType,
      invoiceProfile: 'productAndPrice',
    },
  }

  componentDidMount() {
    const { fetchDebtor, id, clearDebtor } = this.props

    clearDebtor('single', {
      fetchId: createFetchId(this.props.debtorType),
    })
    if (id !== undefined) {
      fetchDebtor(id, {
        fetchId: createFetchId(this.props.debtorType),
      })
    }

    if (this.props.debtorTemplateId) {
      this.props.fetchDebtor(this.props.debtorTemplateId, {
        fetchId: 'debtorTemplate',
      })
    }
  }

  componentWillUnmount() {
    this.props.clearDebtor('single', {
      fetchId: createFetchId(this.props.debtorType),
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.debtor) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.debtor },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }

    // if (
    //   nextProps.debtorTemplate &&
    //   this.props.debtorTemplateId &&
    //   !shallowEqual(nextProps.debtorTemplate, this.props.debtorTemplate)
    // ) {
    //   const templateData = pick(nextProps.debtorTemplate, [
    //     'name',
    //     'debtorType',
    //     'agentId',
    //     'airlineId',
    //     'cardId',
    //     'startDate',
    //     'endDate',
    //     'prices',
    //   ])
    //   templateData.prices = templateData.prices.map(
    //     ({ _id, id, ...priceTemplate }) => priceTemplate,
    //   )

    //   this.setState({
    //     formData: {
    //       ...this.state.formData,
    //       ...templateData,
    //     },
    //   })
    // }
  }

  handleSubmit = (formData) => {
    const { createDebtor, updateDebtor, id } = this.props
    const debtor = formData
    const fetchId = createFetchId(this.state.formData.debtorType)
    if (id === undefined)
      createDebtor(debtor, {
        onResponse: this.handleResponse,
        fetchId,
      })
    else
      updateDebtor(debtor, {
        onResponse: this.handleResponse,
        fetchId,
      })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit(this.state.formData.debtorType)
  }

  submit = (e) => {
    this.props.submit(formId)
    e.preventDefault()
  }

  render() {
    return (
      <form onSubmit={this.submit}>
        <DebtorForm
          form={formId}
          initialValues={this.state.formData}
          formValues={this.props.formValues}
          onSubmit={this.handleSubmit}
          enableReinitialize
        />
      </form>
    )
  }
}

export default compose(
  connect(
    (state, { debtorType }) => {
      const debtor = getDebtor(state, debtorType)
      return {
        debtor: debtor ? debtor.single : undefined,
        errors: debtor ? debtor.singleErrors : null,
        formValues: getFormValues(formId)(state),
        // debtorTemplate: get(state, 'fetch.debtorTemplate.single'),
      }
    },
    {
      fetchDebtor,
      updateDebtor,
      clearDebtor,
    },
  ),
  connect(null, {
    submit,
    stopSubmit,
  }),
)(DebtorFormPage)
