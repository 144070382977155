import React from 'react'
import _ from 'lodash'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { reduxForm, submit } from 'redux-form'
import {
  TextField,
  DateTimeField,
  TextAreaField,
  SwitchField,
} from '../form/index'
import { dateFormat } from '../table/index'
import { priceFormat } from '../../services/currency'
import { updateInvoice } from '../../actions/remote_api'
import {
  validateWith,
  required,
  date,
  isPositiveNumber,
} from '../form/validator'

const invoicePayFormId = 'invoicePay'

export const InvoicePayModal = ({
  isShow,
  onHide,
  invoice,
  onSubmit,
  submit,
}) => {
  if (!invoice) return null
  const formInitialValues = _.pick(invoice, [
    'paidAmount',
    'paidDate',
    'paidRemark',
    'isPaid',
  ])
  return (
    <Modal show={isShow} onHide={onHide} bsSize="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {I18n.t('invoicePage.payModal.header')} #{invoice.code}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{invoice.debtor.name}</h4>
        <InvoicePayForm
          onSubmit={onSubmit}
          invoice={invoice}
          initialValues={formInitialValues}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="primary" onClick={submit}>
          {I18n.t('action.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const validate = (values) => ({
  paidDate: validateWith(values.paidDate, [required, date]),
  paidAmount: validateWith(values.paidAmount, [required, isPositiveNumber]),
})

export const InvoicePayForm = reduxForm({
  form: invoicePayFormId,
  validate,
})(({ handleSubmit, invoice }) => (
  <form onSubmit={handleSubmit}>
    <Row>
      <Col md={4}>
        <dl>
          <dt>{I18n.t('invoicePage.fields.billingDate')}</dt>
          <dd>{dateFormat(invoice.billingDate)}</dd>
        </dl>
      </Col>
      <Col md={4}>
        <dl>
          <dt>{I18n.t('invoicePage.fields.dueDate')}</dt>
          <dd>{dateFormat(invoice.dueDate)}</dd>
        </dl>
      </Col>
      <Col md={4}>
        <dl>
          <dt>{I18n.t('invoicePage.fields.grandTotal')}</dt>
          <dd>{priceFormat(invoice.grandTotal)}</dd>
        </dl>
      </Col>
      <Col md={4}>
        <DateTimeField
          name="paidDate"
          i18nLabel="invoicePage.fields.paidDate"
          dateFormat={I18n.t('date.display')}
          timeFormat={false}
        />
      </Col>
      <Col md={4}>
        <TextField
          name="paidAmount"
          i18nLabel="invoicePage.fields.paidAmount"
        />
      </Col>
      <Col md={4}>
        <TextAreaField
          name="paidRemark"
          i18nLabel="invoicePage.fields.paidRemark"
          rows={5}
        />
      </Col>
      <Col md={4}>
        <SwitchField
          name="isPaid"
          i18nLabel="invoicePage.fields.isPaid"
          rows={5}
        />
      </Col>
    </Row>
  </form>
))

export const withInvoicePayModal = (Component) =>
  connect(null, { submit, updateInvoice })(
    class InvoicePayModalWrapper extends React.Component {
      state = { isShow: false, invoice: undefined }

      submitDidComplete = undefined

      handleShow = (invoice, submitDidComplete) => {
        this.setState({ isShow: true, invoice })
        this.submitDidComplete = submitDidComplete
      }

      handleHide = () => this.setState({ isShow: false })

      handleSubmit = (values) => {
        this.props.updateInvoice(
          {
            ...this.state.invoice,
            ...values,
          },
          {
            onResponse: () => {
              this.handleHide()
              if (this.submitDidComplete) this.submitDidComplete()
            },
          },
        )
      }

      submit = () => {
        this.props.submit(invoicePayFormId)
      }

      render() {
        return (
          <div>
            <Component {...this.props} showInvoicePayModal={this.handleShow} />
            <InvoicePayModal
              isShow={this.state.isShow}
              onHide={this.handleHide}
              onSubmit={this.handleSubmit}
              invoice={this.state.invoice}
              submit={this.submit}
            />
          </div>
        )
      }
    },
  )
