import React from 'react'
import { Panel, Form, Row, Col, FormControl, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import DateTime from 'react-datetime'
import Select from 'react-select'
import AgentSearchSelect, { createQuery } from '../agent/AgentSearchSelect'
import ProductSearchSelect from '../product/ProductSearchSelect'
import StationSearchSelect from '../station/StationSearchSelect'
import moment from 'moment'

export default ({
  isShow,
  filter,
  onFilterChange,
  onClearFilter,
  isJobAssignable,
}) => {
  const fieldsLabel = I18n.t('reservationPage.fields')

  const startDateValid = (current) => {
    if (filter.serviceDateTimeEnd)
      return current
        .startOf('day')
        .isBefore(filter.serviceDateTimeEnd.endOf('day'))
    return true
  }

  const endDateValid = (current) => {
    if (filter.serviceDateTimeStart)
      return current
        .endOf('day')
        .isAfter(filter.serviceDateTimeStart.startOf('day'))
    return true
  }

  return (
    <Panel collapsible expanded={isShow}>
      <Form horizontal>
        <Row>
          <Col md={4}>
            <Row>
              <Col md={6}>
                <FormControl
                  type="text"
                  value={filter.mainPaxName || ''}
                  onChange={(e) =>
                    onFilterChange('mainPaxName', e.currentTarget.value)
                  }
                  placeholder={fieldsLabel.mainPax.name}
                />
              </Col>
              <Col md={6}>
                <FormControl
                  type="text"
                  value={filter.confirmationNumber || ''}
                  onChange={(e) =>
                    onFilterChange('confirmationNumber', e.currentTarget.value)
                  }
                  placeholder={fieldsLabel.confirmationNumber}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <ProductSearchSelect
                  value={filter.productId}
                  onChange={(value) => onFilterChange('productId', value)}
                  placeholder={fieldsLabel.product}
                  selectValue={(product) => product._id}
                  initialValue={filter.productId}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <StationSearchSelect
                  initialValue={filter.stationId}
                  value={filter.stationId}
                  onChange={(value) => onFilterChange('stationId', value)}
                  selectValue={(station) => station._id}
                  placeholder={fieldsLabel.station}
                />
              </Col>
            </Row>
          </Col>
          <Col md={2}>
            <DateTime
              value={filter.serviceDateTimeStart}
              onChange={(value) => {
                if (value && !moment.isMoment(value)) return
                onFilterChange('serviceDateTimeStart', value)
              }}
              dateFormat="DD-MM-YYYY"
              timeFormat={false}
              isValidDate={startDateValid}
              inputProps={{
                placeholder: fieldsLabel.serviceDateTimeStart,
                style: { marginButtom: '4px' },
              }}
            />
            <br />
            <DateTime
              value={filter.serviceDateTimeEnd}
              onChange={(value) => {
                if (value && !moment.isMoment(value)) return
                onFilterChange('serviceDateTimeEnd', value)
              }}
              dateFormat="DD-MM-YYYY"
              timeFormat={false}
              isValidDate={endDateValid}
              inputProps={{ placeholder: fieldsLabel.serviceDateTimeEnd }}
            />
          </Col>
          <Col md={2}>
            <FormControl
              type="text"
              value={filter.flightNumber || ''}
              onChange={(e) =>
                onFilterChange('flightNumber', e.currentTarget.value)
              }
              placeholder={fieldsLabel.flightNumber}
            />
            <br />
            <AgentSearchSelect
              initialValue={filter.agentId}
              value={filter.agentId}
              onChange={(value) => onFilterChange('agentId', value)}
              createQuery={(search) => ({
                ...createQuery(search),
                _showDeleted: true,
              })}
              selectValue={(agent) => agent._id}
              placeholder={fieldsLabel.agent}
            />
          </Col>
          <Col md={2}>
            <Select
              value={filter.status}
              onChange={(value) => onFilterChange('status', value)}
              placeholder={fieldsLabel.status}
              simpleValue
              options={statusOptions}
            />
            <br />
            {isJobAssignable === undefined ? (
              <Select
                value={filter.jobAssignStatus}
                onChange={(value) => onFilterChange('jobAssignStatus', value)}
                placeholder={fieldsLabel.jobAssignStatus}
                simpleValue
                options={jobAssignStatusOptions}
              />
            ) : (
              ''
            )}
          </Col>
          <Col md={2}>
            <Button className="pull-right" onClick={onClearFilter}>
              <span className="glyphicon glyphicon-trash" /> Clear Filter
            </Button>
          </Col>
        </Row>
      </Form>
    </Panel>
  )
}

const statusOptions = [
  { label: 'TENTATIVE', value: 'tentative' },
  { label: 'CONFIRMED', value: 'confirmed' },
  { label: 'COMPLETED', value: 'completed' },
  { label: 'NOSHOW', value: 'noShow' },
  { label: 'MISSEDCASE', value: 'missedCase' },
  { label: 'CANCELED', value: 'canceled' },
]

const jobAssignStatusOptions = [
  { label: 'NO REQUIRED', value: 'noRequired' },
  { label: 'INCOMPLETE', value: 'inComplete' },
  { label: 'COMPLETED', value: 'completed' },
]
