import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { fetchInvoiceInterval } from '../../actions/remote_api'
import { withDetailPopup } from '../detailPopup'
import { dateTimeFormat } from '../table/index'

const InvoiceIntervalDetailPopup = ({ instance }) => {
  const { fields } = I18n.t('invoiceIntervalPage')
  if (!instance) return null

  return (
    <form className="form-horizontal">
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.startDate}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {dateTimeFormat(instance.startDate)}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.endDate}</label>
        <div className="col-sm-8">
          <p className="form-control-static">
            {dateTimeFormat(instance.endDate)}
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.txnCount}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.txnCount}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.status}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.status.toUpperCase()}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{fields.invoiceCount}</label>
        <div className="col-sm-8">
          <p className="form-control-static">{instance.invoiceIds.length}</p>
        </div>
      </div>

      <small>
        <div className="form-group">
          <label className="col-sm-4 control-label">
            {fields.lastUpdatedDateTime}
          </label>
          <div className="col-sm-8">
            <p className="form-control-static">
              {instance.log && dateTimeFormat(instance.log.lastUpdatedDateTime)}
              {' by '}
              {instance.log && instance.log.lastUpdatedBy}
            </p>
          </div>
        </div>
        <div className="form-group">
          <label className="col-sm-4 control-label">
            {fields.createdDateTime}
          </label>
          <div className="col-sm-8">
            <p className="form-control-static">
              {instance.log && dateTimeFormat(instance.log.createdDateTime)}
              {' by '}
              {instance.log && instance.log.createdBy}
            </p>
          </div>
        </div>
      </small>
    </form>
  )
}

let InvoiceIntervalDetailPopupContainer = withDetailPopup(
  InvoiceIntervalDetailPopup,
  () => ({ header: I18n.t('invoiceIntervalPage.detail.header') }),
)

export default connect(
  (state) => ({
    instance: get(state, 'fetch.invoiceInterval.single'),
  }),
  { fetchInstance: fetchInvoiceInterval },
)(InvoiceIntervalDetailPopupContainer)
