import React from 'react'
import ProductForm from './ProductForm'
import { connect } from 'react-redux'
import {
  fetchProduct,
  createProduct,
  updateProduct,
  clearProduct,
} from '../../actions/remote_api'
import { submit, stopSubmit } from 'redux-form'

class ProductFormPage extends React.Component {
  formId = 'product'
  state = { formData: { isActive: true } }

  componentDidMount() {
    const { fetchProduct, id, clearProduct } = this.props

    clearProduct()
    if (id !== undefined) {
      fetchProduct(id)
    }
  }

  componentWillUnmount() {
    this.props.clearProduct()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.product) {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, ...nextProps.product },
      })
    } else if (nextProps.errors) {
      this.props.stopSubmit(this.formId, nextProps.errors)
    }
  }

  handleSubmit = (formData) => {
    const { createProduct, updateProduct, id } = this.props
    const product = formData
    if (id === undefined)
      createProduct(product, { onResponse: this.handleResponse })
    else updateProduct(product, { onResponse: this.handleResponse })
  }

  handleResponse = ({ ok }) => {
    if (ok) this.props.onSubmit()
  }

  submit = (e) => {
    this.props.submit(this.formId)
    e.preventDefault()
  }

  handleMakeNewService = () => undefined

  render() {
    return (
      <form onSubmit={this.submit}>
        <ProductForm
          form={this.formId}
          initialValues={this.state.formData}
          onSubmit={this.handleSubmit}
          makeNewService={this.handleMakeNewService}
          enableReinitialize
        />
      </form>
    )
  }
}

export default connect(
  ({ fetch: { product } }) => ({
    product: product ? product.single : undefined,
    errors: product ? product.singleErrors : null,
  }),
  {
    createProduct,
    updateProduct,
    fetchProduct,
    clearProduct,
    submit,
    stopSubmit,
  },
)(ProductFormPage)
