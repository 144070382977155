export const makeServiceTitle = (service) =>
  (service.class === 'Standard' ? '' : service.class + ' ') + service.name

export const makeStaffDisplayName = (staff) =>
  `${staff.firstName} ${staff.lastName}`

export const makeStationByServiceTitle = (stationByService) =>
  stationByService
    .filter((target) => target.station)
    .map((target) => target.station.name)
    .join(', ')
