import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { Col, Button, Row, ControlLabel, Label } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import _ from 'lodash'

import StationServiceItemField from './StationServiceItemField'
import { makeServiceTitle } from '../index'
import StatusBadge from '../commons/StatusBadge'
import { payerType } from '../../services/payerType'
import {
  TextField,
  TextAreaField,
  DateTimeField,
  Toolbar,
  ListField,
  SwitchField,
} from '../form'
import {
  required,
  date,
  validateWith,
  email,
  textLimit,
} from '../form/validator'
import ProductSearchSelectField from '../product/ProductSearchSelectField'
import DropOffAreaSearchSelectField from './DropOffAreaSearchSelectField'
import ReservableStatusIcon from './ReservableStatusIcon'
import PayerControl from './payer/PayerControl'
import { RadioGroup, Radio } from '../form/RadioGroup'

const validate = (values) => {
  const errors = {}
  const mainPax = values.mainPax

  errors.product = validateWith(values.product, [required])
  errors.agent = validateWith(values.agent, [required])
  // errors.payerAirlineId = validateWith(values.payerAirlineId, [required])
  // errors.payerCardId = validateWith(values.payerCardId, [required])
  errors.payerVoucherBook = validateWith(values.payerVoucherBook, [required])
  errors.flightDateTime = validateWith(values.flightDateTime, [required, date])
  // errors.targetStationsByService = (values.targetStationsByService || []).map(target => ({
  //   service: validateWith(target.service, [required]),
  //   station: validateWith(target.station, [required]),
  // }))
  errors.serviceDateTime = validateWith(values.serviceDateTime, [
    required,
    date,
  ])
  errors.overrideConfirmationNumber = validateWith(
    values.overrideConfirmationNumber,
    [textLimit(12)],
  )
  errors.mainPax = {
    firstName: validateWith(mainPax ? mainPax.firstName : undefined, [
      required,
    ]),
    lastName: validateWith(mainPax ? mainPax.lastName : undefined, [
      required,
      textLimit(20),
    ]),
    email: validateWith(mainPax ? mainPax.email : undefined, [email]),
  }
  errors.flightNumber = validateWith(values.flightNumber, [required])
  errors.boardingDateTime = validateWith(values.boardingDateTime, [date])

  return errors
}

const selectablePayerType = [payerType.agent, payerType.voucher]

const ReservationForm = ({
  initialValues,
  formValues,
  isLocked,
  isOverrideCNShowed,
  selectedServices,
  reservableStatus,
  isSubmittable,
  formMode,
}) => {
  const { form, fields } = I18n.t('reservationPage')
  const { display: dateDisplay } = I18n.t('date')
  const { display: timeDisplay } = I18n.t('time')
  return (
    <div className="container">
      <Toolbar
        title={initialValues._id ? form.update : form.new}
        titleComponent={ReservationTitle}
      >
        <Button className="btn-primary" type="submit" disabled={!isSubmittable}>
          Submit
        </Button>
      </Toolbar>

      <div className="panel panel-default">
        <div className="panel-heading">
          <Heading.Layout>
            <Heading.Title>
              {I18n.t('reservationPage.sectionsTitle.productAndPayer')}
            </Heading.Title>
            <Heading.RightItem>
              {!isLocked && <ReservableStatusIcon status={reservableStatus} />}
            </Heading.RightItem>
          </Heading.Layout>
        </div>
        <SectionBody>
          <Row>
            <Col md={6}>
              <ProductSearchSelectField
                name="product"
                label={fields.product}
                initialValue={initialValues.productId}
                addStar={true}
                disabled={isLocked}
              />
            </Col>

            <Col md={2}>
              <TextField
                name="guestPaxAdult"
                label={fields.guestPaxAdultFull}
                type="number"
                step={1}
                readOnly={isLocked}
              />
            </Col>
            <Col md={2}>
              <TextField
                name="guestPaxChild"
                label={fields.guestPaxChildFull}
                type="number"
                step={1}
                readOnly={isLocked}
              />
            </Col>
            <Col md={2}>
              <TextField
                name="guestPaxInfant"
                label={fields.guestPaxInfantFull}
                type="number"
                step={1}
                readOnly={isLocked}
              />
            </Col>
          </Row>

          {!isLocked && (
            <PayerControl
              fields={fields}
              selectablePayerType={selectablePayerType}
              formValues={formValues}
              initialValues={initialValues}
              isLocked={isLocked}
              reservableStatus={reservableStatus}
            />
          )}

          {isLocked && (
            <Row>
              <Col md={12}>
                <PayerTypeDisplay reservation={formValues} />
              </Col>
            </Row>
          )}

          <Row>
            <Col md={12}>
              <SwitchField
                name="isCharged"
                label={fields.isCharged}
                addStar
                isLocked={isLocked}
                initialValue={true}
                isValidate={false}
              />
            </Col>
          </Row>
        </SectionBody>
      </div>

      <div className="panel panel-default">
        <div className="panel-heading">
          {I18n.t('reservationPage.sectionsTitle.flightInfo')}
        </div>
        <SectionBody>
          <Row>
            <Col md={3}>
              <div>
                <ControlLabel>{fields.flightType}</ControlLabel>
              </div>
              <RadioGroup>
                <Radio>
                  <Field
                    name="flightType"
                    component="input"
                    type="radio"
                    value="arrival"
                    disabled={isLocked}
                  />
                  Arrival
                </Radio>
                <Radio>
                  <Field
                    name="flightType"
                    component="input"
                    type="radio"
                    value="departure"
                    disabled={isLocked}
                  />
                  Departure
                </Radio>
              </RadioGroup>
            </Col>

            <Col md={3}>
              <TextField
                name="flightNumber"
                label={fields.flightNumber}
                addStar={true}
                disabled={isLocked}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <DateTimeField
                name="flightDateTime"
                label={fields.flightDateTime}
                dateFormat={dateDisplay}
                timeFormat={timeDisplay}
                addStar={true}
                inputProps={{ disabled: isLocked }}
              />
            </Col>

            <Col md={4}>
              <DateTimeField
                name="boardingDateTime"
                label={fields.boardingDateTime}
                dateFormat={dateDisplay}
                timeFormat={timeDisplay}
                inputProps={{ disabled: isLocked }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <TextField
                name="airport"
                label={fields.airport}
                disabled={isLocked}
              />
            </Col>
          </Row>
        </SectionBody>
      </div>

      <div className="panel panel-default">
        <div className="panel-heading">
          {I18n.t('reservationPage.sectionsTitle.serviceInfo')}
        </div>
        <SectionBody>
          <Row>
            <Col md={12}>
              <ControlLabel>
                {I18n.t('reservationPage.fields.targetStationsByService')}
              </ControlLabel>
              {selectedServices && (
                <ListField
                  name="targetStationsByService"
                  disabled={isLocked}
                  itemLabels={
                    selectedServices
                      ? selectedServices.map(makeServiceTitle)
                      : undefined
                  }
                  inputFieldItem={StationServiceItemField}
                  initialValues={initialValues.targetStationsByService}
                />
              )}
              {_.isEmpty(selectedServices) && (
                <div style={{ marginBottom: 15 }}>
                  <Label>{I18n.t('reservationPage.noProductSelected')}</Label>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <DateTimeField
                name="serviceDateTime"
                label={fields.serviceDateTime}
                dateFormat={dateDisplay}
                timeFormat={timeDisplay}
                addStar={true}
                inputProps={{ disabled: isLocked }}
              />
            </Col>

            <Col md={4}>
              {isOverrideCNShowed && (
                <TextField
                  name="overrideConfirmationNumber"
                  label={fields.overrideConfirmationNumber}
                  disabled={isLocked}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <TextField
                name="replyTo"
                label={fields.replyTo}
                disabled={isLocked}
              />
            </Col>
          </Row>

          <Row>
            <DropOffArea md={12}>
              <DropOffAreaSearchSelectField
                name="dropOffArea"
                label={fields.dropOffArea}
                initialValue={initialValues.dropOffArea}
                disabled={isLocked}
              />
            </DropOffArea>
          </Row>
        </SectionBody>
      </div>

      <div className="panel panel-default">
        <div className="panel-heading">
          {I18n.t('reservationPage.sectionsTitle.passengerInfo')}
        </div>
        <SectionBody>
          <Row>
            <Col md={4}>
              <TextField
                name="mainPax.firstName"
                label={fields.mainPax.firstName}
                addStar={true}
                readOnly={isLocked}
              />
            </Col>
            <Col md={4}>
              <TextField
                name="mainPax.lastName"
                label={fields.mainPax.lastName}
                addStar={true}
                readOnly={isLocked}
              />
            </Col>
            <Col md={4}>
              <TextField
                name="mainPax.email"
                label={fields.mainPax.email}
                readOnly={isLocked}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <TextField
                name="phoneNumber"
                label={fields.phoneNumber}
                disabled={isLocked}
              />
            </Col>
            <Col md={3}>
              <TextField
                name="luggageAmount"
                label={fields.luggageAmount}
                type="number"
                step={1}
                readOnly={isLocked}
              />
            </Col>
          </Row>
        </SectionBody>
      </div>

      <div className="panel panel-default">
        <div className="panel-heading">
          {I18n.t('reservationPage.sectionsTitle.notes')}
        </div>
        <SectionBody>
          <Row>
            <Col md={12}>
              <TextAreaField
                name="importantNote"
                label={fields.importantNote}
                rows={3}
                disabled={!isSubmittable}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextAreaField
                name="privateNote"
                label={fields.privateNote}
                rows={3}
                disabled={!isSubmittable}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextAreaField
                name="publicNote"
                label={fields.publicNote}
                rows={3}
                disabled={!isSubmittable}
              />
            </Col>
          </Row>
        </SectionBody>
      </div>
    </div>
  )
}

const ReservationTitle = ({ title }) => (
  <span>
    {title}{' '}
    <Field name="confirmationNumber" component={renderConfirmationNum} />{' '}
    <Field name="status" component={renderStatusBadge} />
  </span>
)

const renderStatusBadge = ({ input: { value } }) =>
  value ? <StatusBadge>{value}</StatusBadge> : null

const renderConfirmationNum = ({ input: { value } }) =>
  value ? <b>#{value}</b> : null

const SectionBody = styled.div.attrs({ className: 'panel-body' })`
  margin-left: 8px;
  margin-right: 8px;
`

const DropOffArea = styled(Col)`
  margin-bottom: 40px;
`

const Heading = {
  Layout: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  Title: styled.div`
    flex: 1;
  `,
  RightItem: styled.div``,
}

const PayerTypeDisplay = ({ reservation }) => {
  if (!reservation) return null

  const title = getPayerTypeTitle(reservation)
  return (
    <div>
      <ControlLabel>{reservation.payerType.toUpperCase()}</ControlLabel>
      <p>{title}</p>
    </div>
  )
}

function getPayerTypeTitle(reservation) {
  function payerTitle(payer) {
    if (payer) return `${payer.name} (${payer.code})`
    return undefined
  }
  switch (reservation.payerType) {
    case payerType.agent:
      return payerTitle(_.get(reservation, 'agent'))
    case payerType.airline:
      return payerTitle(_.get(reservation, 'payerAirline.name'))
    case payerType.card:
      return payerTitle(_.get(reservation, 'payerCard.name'))
    case payerType.voucher:
      return _.get(reservation, 'payerVoucherBook.name')
    case payerType.walkin:
      return I18n.t('reservationPage.walkin')
    default:
      return undefined
  }
}

export default reduxForm({
  validate,
})(ReservationForm)
