import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import AgentCashTxnModal from './AgentCashTxnModal'
import {
  createAgentCashTxn,
  fetchAgentCashTxns,
  fetchAgent,
} from '../../../actions/remote_api'
import { agentCashTxnTypes } from '../../../services/agentCashTxnTypes'

class AgentCashTxnModalContainer extends React.Component {
  state = {
    isShow: false,
    adjustTopupValue: undefined,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agent.remainingTopUp !== this.state.adjustTopupValue) {
      this.setState({
        adjustTopupValue: nextProps.agent.remainingTopUp,
      })
    }
  }

  onShow = () => {
    this.fetchAgentCashTxns()
    this.setState({ isShow: true })
  }
  handleClose = () => this.setState({ isShow: false })

  handleAdjustTopupChange = (e) => {
    this.setState({
      adjustTopupValue: e.target.value,
    })
  }

  handleAdjustBalance = _.debounce(() => {
    const agentId = this.props.agent._id
    this.props.createAgentCashTxn(
      {
        agentId,
        amount: this.state.adjustTopupValue,
        type: agentCashTxnTypes.adjust,
      },
      {
        onResponse: () => {
          this.fetchAgentCashTxns()
          if (this.props.onAdjustBalance) this.props.onAdjustBalance()
        },
      },
    )
  }, 150)

  fetchAgentCashTxns = () => {
    this.props.fetchAgentCashTxns({
      agentId: this.props.agent._id,
      order: 'desc',
      sort: '_id',
    })
  }

  render() {
    const modal = (
      <AgentCashTxnModal
        isShow={this.state.isShow}
        onClose={this.handleClose}
        agent={this.props.agent}
        adjustTopupValue={this.state.adjustTopupValue}
        onAdjustTopupChange={this.handleAdjustTopupChange}
        onAdjustBalance={this.handleAdjustBalance}
        agentCashTxns={this.props.agentCashTxns}
      />
    )
    return this.props.children(this.onShow, modal)
  }
}

export default connect(
  (state) => ({
    agentCashTxns: _.get(state, 'fetch.agentCashTxn.list'),
  }),
  { fetchAgentCashTxns, createAgentCashTxn, fetchAgent },
)(AgentCashTxnModalContainer)
