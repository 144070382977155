import { I18n } from 'react-redux-i18n'

export const pricingProductType = {
  fixed: 'fixed',
  stepped: 'stepped',
}

export const resetCountingInterval = {
  day: 'day',
  month: 'month',
  year: 'year',
}

export const displayPricingProductType = (type) =>
  I18n.t(`pricingContractPage.fields.pricingProduct.${type}`).toUpperCase()
